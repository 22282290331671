import React, { useContext, useState } from "react";
import {Context} from "../Context";
import {Avatar, Menu, MenuItem} from "@material-ui/core";
import enLogo from "../langLogo/en.png";
import rusLogo from "../langLogo/rus.png";
import estLogo from "../langLogo/est.png";
import ukLogo from "../langLogo/uk.png";
import esLogo from "../langLogo/es.png";
import {useTranslation} from "react-i18next";
import changeLanguageOnServer from "../requests/changeLanguageOnServer";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import "moment/locale/et";
import "moment/locale/uk";
import "moment/locale/es";
import changeLangTo from "./changeLangTo";
import { useSelector } from "react-redux";
import { useEffect } from "react";


export default function LangSwitcher(){
    const [companyLang, setCompanyLang] = useState('')
    const state = useSelector(state => state)
    useEffect(()=>{
        setCompanyLang(state.app.detail?.lang)
    }, [state])

    const { _, i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClose = () => {setAnchorEl(null);};

    let changeLangOnServer = async (newLang) => {
        await changeLanguageOnServer(newLang)
    }

    function LangPoint(p){
        return (
            <MenuItem key={"key_lang_" + p.src}
                      onClick={() => {
                          changeLangTo(p.src, i18n)
                          changeLangOnServer(p.src);
                          handleClose()
                      }}
            >
                <Avatar alt="lang" src={p.src === "rus" ? rusLogo : (p.src === "est" ? estLogo : (p.src === "uk" ? ukLogo : (p.src === "es" ? esLogo : enLogo)))}/>
            </MenuItem>
        )
    }

    return  <div className="h-100 d-flex align-items-center border border-2 border-dark" style={{height: "6vh", cursor: "pointer", borderRadius: "0.5rem"}}>
                <Avatar alt="lang" src={
                    i18n.language === "rus" ? rusLogo :
                    i18n.language === "est" ? estLogo :
                    i18n.language === "uk" ? ukLogo :
                    i18n.language === "es" ? esLogo :
                    enLogo
                }
                        aria-controls="basic-menu"
                        aria-haspopup="true"
                        aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
                        onClick={(e) => {setAnchorEl(e.currentTarget)}}
                />
                <Menu
                    style={{marginTop: "1.5vh"}}
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{'aria-labelledby': 'basic-button', style: {backgroundColor: "lightgray"}}}
                >
                    <LangPoint src="en"/>
                    <LangPoint src="rus"/>
                    <LangPoint src="est"/>
                    <LangPoint src="uk"/>
                    <LangPoint src="es"/>
                </Menu>
            </div>
}