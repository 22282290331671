import React, {useContext} from "react";
import {Context} from "../Context";
const XLSX = require('xlsx')

export default function SaveXLSX(props) {
    let [context, setContext] = useContext(Context);

    /*
        proprs.cells is [{title: "123", key: "123"}]
        props.array is array with data. each data line has all keys from props.cells
        props.loadingAllData is fucntion which load data (should return like props.array)

        call component like
        <SaveXLSX
            cells={columns.filter(el => el.key !== "status").map(cl => {return {title: cl.name, key: cl.key}})}
            array={this.state.items} // or load
            loadingAllData={() => this.loadWaybills({skip: null, limit: null}, true)} // or array
        />
     */

    let formatDate = (str) => {
        if(!str) return ""
        let d = new Date(str)
        return d.toLocaleDateString('ru') + " " + d.toLocaleTimeString('ru').substr(0, d.toLocaleTimeString('ru').length - 3)
    }

    return  <button type="button" className="btn btn-outline-dark" onClick={() => {
        (async () => {
            let {result, error} = props.loadingAllData ? (await props.loadingAllData()) : {result: props.array, error: false}
            if(error === 401) context.error_401();

            if(!(props.cells && result)){
                console.log("XLSX creation error ")
                return;
            }

            let groupedBloks = !props.grouped ? [] :
                result.map(r =>
                    (
                        [
                            props.cells.reduce((sum, cl, i) => {sum[cl.title] = (i === 0 ? r.login: ""); return sum}, {}),
                            ...r.substrs.map(sbstr => (
                                props.cells.reduce((sum, cl) => {sum[cl.title] = sbstr[cl.key] ?
                                    (cl.key.indexOf("Date") !== -1 ?
                                            formatDate(sbstr[cl.key])
                                            :
                                            (cl.exportText ?
                                                    (sbstr[cl.key] === "" ? "" : cl.exportText)
                                                    :
                                                    sbstr[cl.key]
                                            )
                                    ) : ""; return sum}, {}
                                )
                            ))
                        ]
                    )
                );
            let groupedBloksMainContent = []
            groupedBloks.forEach(gr => {
                gr.forEach(g => (
                    groupedBloksMainContent.push(g)
                ))
            })

            let rows = !props.grouped ?
                result.map(arr => props.cells.reduce((sum, cl) => {sum[cl.title] = arr[cl.key] ?
                    (cl.key.indexOf("Date") !== -1 ?
                            formatDate(arr[cl.key])
                            :
                            (cl.exportText ?
                                    (arr[cl.key] === "" ? "" : cl.exportText)
                                    :
                                    arr[cl.key]
                            )
                    ) : ""; return sum}, {}
                ))
                :
                groupedBloksMainContent

            let binary_univers = rows //[{'name': 'Hi','value':1},{'name':'Bye','value':0}]
            let binaryWS = XLSX.utils.json_to_sheet(binary_univers);
            let wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, binaryWS, 'Binary values')
            XLSX.writeFile(wb, 'document.xlsx');

        })();
    }}
    >
        <i className="fas fa-file-excel"/>&nbsp;&nbsp;XLSX
    </button>
}