import React, {useState} from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {useTranslation} from "react-i18next";
import {importFromFile, importFromFileInit} from "../redux/actions/transactionActions";
import {useDispatch, useSelector} from "react-redux";
import {Fab} from "@material-ui/core";

export default function ImportFileModal({handleClose}){
    const { t, i18n } = useTranslation();
    let lk = "data.importFileModal."

    const [uploadFile, setUploadFile] = useState(null);
    const [uploadFileName, setUploadFileName] = useState(null);

    const dispatch = useDispatch()
    const loadingState = useSelector(state => state.transaction)

    const statuses = {
        "init":    {color: "dark",    loadBtnText :t(lk + "load"),       text: null,                  allowLoading: true},
        "waiting": {color: "warning", loadBtnText :t(lk + "waitingBtn"), text: t(lk + "waitingText"), loading: true},
        "success": {color: "success", loadBtnText :t(lk + "successBtn"), text: t(lk + "successText"), isSuccess: true},
        "error":   {color: "danger",  loadBtnText :t(lk + "errorBtn"),   text: "error"}
    }
    let status = loadingState.loading ? statuses.waiting : loadingState.error === null ? statuses.init : loadingState.error ? {...statuses.error, text: loadingState.error} : statuses.success

    const modalStyle = {position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "30rem", // width: "50vw", height: "95vh", overflow: "auto",
        bgcolor: '#eeeeee', border: '2px solid grey', borderRadius: "1rem", boxShadow: 24, p: 4
    };

    let translate = key => t(key)

    return (
        <Modal open={true}
               onClose={(e, reason) => {if (reason !== 'backdropClick') {handleClose()}}}
        >
            <Box sx={modalStyle}>
                <div className="text-center py-2 mb-2 rounded-3 bg-dark text-white fw-bold">
                    {t(lk + "header")}
                </div>

                <div className="text-start rounded-3 pt-2">
                    <div className={"card  my-2 py-2 px-5 text-center"}>
                        <div className="py-2">
                            {
                                uploadFileName ?
                                    t(lk + "fileName") + " " + uploadFileName
                                    :
                                    t(lk + "fileDontChoosed")
                            }
                        </div>

                        <label htmlFor="upload" className="py-2">
                            <input
                                style={{ display: 'none' }}
                                id="upload"
                                name="upload"
                                type="file"
                                onChange={e => {
                                    dispatch(importFromFileInit())
                                    if(e.target.files.length) {
                                        setUploadFileName(e.target.value.replace('C:\\fakepath\\', '')) // if few files ???
                                        if (e.target.value) {
                                            let formData = new FormData();
                                            formData.append("file", e.target.files[0]); // [0] ???
                                            setUploadFile(formData)
                                        }
                                    }
                                }}
                            />

                            <Fab
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"
                                sx={{
                                    border: "1px solid #212529",
                                    borderRadius: "0.5rem",
                                    color: "#212529",
                                    borderColor: "#212529",
                                    backgroundColor: "transparent",
                                    boxShadow: "none",
                                    p: 2,
                                }}
                            >
                                {t(lk + "fileChoosing")}
                            </Fab>

                        </label>

                        {
                            status.text ?
                                <div className={"py-2 text-" + status.color}>
                                    {status.text}
                                </div>
                                : null
                        }
                    </div>
                </div>

                <div className="text-end pt-2">
                    <button type="button" className={"btn btn-outline-dark"} onClick={handleClose}>
                        {t(lk + (status.isSuccess ? "close" : "cancel"))}&nbsp;&nbsp;<i className="fas fa-window-close"/>
                    </button>

                    <button type="button" className={"ms-2 btn btn-outline-" + status.color}
                            onClick={() => {
                                if(status.allowLoading){
                                    dispatch(importFromFile(uploadFile, translate))
                                }
                            }}
                    >
                        {status.loadBtnText}&nbsp;&nbsp;
                        {
                            status.loading ?
                                <div className="spinner-border" role="status" style={{width: "1rem", height: "1rem"}}/>
                                : <i className="fas fa-file-import"/>
                        }
                    </button>
                </div>
            </Box>
        </Modal>
    )
}