import {serverURL, corsHeaders} from "./constants";
const axios = require('axios');

export default async function editObject(id, card){
    try {
        let response = await axios({
            method: 'put',
            url: serverURL + 'items/' + id,
            headers: {
                ...corsHeaders,
                'Content-Type': 'application/json',
            },
            data: {
                fuel_volume: card.tank,
                visible_flag: card.eye,
                time_interval: card.time,
                cards: card.paymentSystems.map(el => ({
                    card_number: el.cNumb,
                    type_system_id: el.pSys
                }))
            }
        });

        return {result: response.status === 200, error: response.status}
    } catch (error) {
        return {result: false, error: error.response ? {status: error.response.status, detail: error.response.data && error.response.data.detail ? error.response.data.detail : null} : {status: 500, detail: ""}}
    }
}