import React, {useContext, useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import loadConnectedPacks from "./requests/loadConnectedPacks";
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import {Backdrop, CircularProgress} from "@material-ui/core";
import Table from "../Table";
import {useTranslation} from "react-i18next";
import PackShop from "./PackShop";
import {Context} from "../../Context";
import {formatBalance} from "../../functions";

export default function ConnectedPacks(){
    const [context, setContext] = useContext(Context);
    const [items, setItems] = useState([]);
    const { promiseInProgress } = usePromiseTracker();
    const { t, i18n } = useTranslation();
    let lk = "billing.ConnectedPacks."

    let loadData = async () => {
        let {result, error} = await loadConnectedPacks()
        if(error === 401) context.error_401();

        let summary = result.reduce((s, el) => ({
            ...s,
            pricePerM: "" + ((s.pricePerM || 0) + parseFloat(el.pricePerM)),
            fullPrice: "" + ((s.fullPrice || 0) + parseFloat(el.fullPrice)),
            is_right: el.is_right,
            symbolPDF: el.symbolPDF,
            symbol: el.symbol,
        }), {
            name: t(lk + "result"),
            pricePerM: "0",
            fullPrice: "0",
            is_right: false,
            symbolPDF: "",
            symbol: "",
            amObjs: "",
            connDate: "",
            amPacks: "",
        })
        result.push(summary)
        console.log(result)

        let addCurrencySign = (is_r, s, v) => ("" + (is_r ? "" : " " + s) + formatBalance(v, 0) + (is_r ? " " + s : ""))
        if(result.length > 1)
            result = result.map(r => ({
                ...r,
                pricePerM: "" + addCurrencySign(r.is_right, r.symbol, r.pricePerM),
                pricePerMPDF: "" + addCurrencySign(r.is_right, r.symbolPDF, r.pricePerM),
                fullPrice: "" + addCurrencySign(r.is_right, r.symbol, r.fullPrice),
                fullPricePDF: "" + addCurrencySign(r.is_right, r.symbolPDF, r.fullPrice),
            }))
        setItems(result);
    };

    useEffect(() => {
        trackPromise(loadData());
        return () => {};
    }, []);

    let columns = [
        {name: t(lk + "name"),      key: "name"},
        {name: t(lk + "connDate"),  key: "connDate"},
        {name: t(lk + "amObjs"),    key: "amObjs"},
        {name: t(lk + "amPacks"),   key: "amPacks"},
        {name: t(lk + "pricePerM"), key: "pricePerM", exportKey: "pricePerMPDF"},
        {name: t(lk + "fullPrice"), key: "fullPrice", exportKey: "fullPricePDF"},
    ].map(el => ({...el, floatC: true, filter: true, sort: "noSort"}))

    return  (
        <div className="py-2">
            {
                promiseInProgress ?
                    <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={true}><CircularProgress
                        color="inherit"/></Backdrop>
                    :
                    <Table items={items} columns={columns} doNotExpand={true}/>
            }

            <PackShop/>
        </div>

    )
}