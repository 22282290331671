import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {Context} from "../Context";
import "./FilterType.css"
import {useTranslation} from "react-i18next";
import { useSelector } from "react-redux";

export default function FilterType(){
    const [context, setContext] = useContext(Context);
    const { t, i18n } = useTranslation();

    const [companyName, setCompanyName] = useState('')
    const state = useSelector(state => state)
    useEffect(()=>{
        setCompanyName(state.app.detail?.service_name)
    }, [state])

    let type = context.filter.type
    let statusColors = ["#ff6347", "#709fdc", "#ff9000", "#6bcc7e", "#32aa48"] //серый a2a2a2
    const text = useMemo(() =>(
        [t("filterTypes.t1"),
        `${t("filterTypes.t2").replace('tms', `${companyName ? companyName : 'Wialon'}`)}`,
        t("filterTypes.t3"),
        t("filterTypes.t4"),
        t("filterTypes.t5"),]
        ), [companyName, t])
    let btnChecked = ["btnR_c", "btnB_c", "btnY_c", "btnGL_c", "btnG_c"]// col => {return {backgroundColor: col, borderColor: col, color: "white"}}
    let btnFree = ["btnR", "btnB", "btnY", "btnGL", "btnG"]// col => {return {borderColor: col, color: "black"}}

    return ( <div className="FilterType">
            <div className="btn-group">
                {statusColors.map((col, i) =>
                    <button type="button" className={"btn btn-outline-primary " + (type[i] ? btnChecked[i] : btnFree[i])} key={"key" + Math.random()}
                            onClick={()=>{setContext({...context,
                                filter: {...context.filter,
                                    type: type.map((el, j) => (j === i ? !el: el))
                                }
                            })}}
                    >
                        {text[i]}
                    </button>
                )}
            </div>
        </div>
    )
}