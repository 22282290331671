import {serverURL, corsHeaders} from "./constants";
const axios = require('axios');

export default async function saveAccRights(newAccRights){
    try {

        let response = await axios({
            method: 'put',
            url: serverURL + 'users/' + newAccRights.id +'/rights/',
            headers: {
                ...corsHeaders,
                'Content-Type': 'application/json',
            },
            data: {
                view: newAccRights.bool_0,
                property: newAccRights.bool_1,
                admin: newAccRights.bool_2,
                diler: newAccRights.bool_3,
                billing: newAccRights.bool_4,
                enable: newAccRights.bool_5,
            }
        });

        return {result: response.status === 200, error: response.status}
    } catch (error) {
        return {result: false, error: error.response ? error.response.status : 500}
    }
}