import React, {Suspense} from 'react';
import {compose, createStore, applyMiddleware} from 'redux'
import {Provider} from 'react-redux'
import thunk from 'redux-thunk'
import {rootReducer} from './redux/rootReducer'
import ReactDOM from 'react-dom';
import App from "./App"
import {BrowserRouter as Router} from "react-router-dom";
import './i18n';
import "./index.css"

const store = createStore(rootReducer, compose(
    applyMiddleware(
        thunk,
    )
))

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={<div>Loading...</div>}>
            <Provider store={store}>
                <Router>
                    <App />
                </Router>
            </Provider>
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);

