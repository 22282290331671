import React, {useContext} from "react";
import * as pdfMake from "pdfmake/build/pdfmake";
import moment from "moment";
import {Context} from "../Context";
import {useSelector} from 'react-redux'
import { useTranslation } from "react-i18next";

const pdfMakeX = require('pdfmake/build/pdfmake.js');
const pdfFontsX = require('pdfmake-unicode/dist/pdfmake-unicode.js');
pdfMakeX.vfs = pdfFontsX.pdfMake.vfs;

export default function SavePDF(props) {
    const {t, i18n} = useTranslation();
    let [context, setContext] = useContext(Context);
    /*
        proprs.cells is [{title: "123", key: "123"}]
        props.array is array with data. each data line has all keys from props.cells
        props.loadingAllData is fucntion which load data (should return like props.array)

        call component like
        <SavePDF
            cells={columns.filter(el => el.key !== "status").map(cl => {return {title: cl.name, key: cl.key}})}
            array={this.state.items} // or load
            loadingAllData={() => this.loadWaybills({skip: null, limit: null}, true)} // or array
        />
     */

    let formatDate = (str) => {
        if (!str) return ""
        let d = new Date(str)
        return d.toLocaleDateString('ru') + " " + d.toLocaleTimeString('ru').substr(0, d.toLocaleTimeString('ru').length - 3)
    }

    const logo = useSelector(state => state.app.pdfLogo)

    return <button type="button" className="btn btn-outline-dark" onClick={() => {
        (async () => {
            let {result, error} = props.loadingAllData ? (await props.loadingAllData()) : {
                result: props.array,
                error: false
            }
            if (error === 401) context.error_401();
            let cells = props.cells

            if (!(cells && result)) {
                console.log("PDF creation error ")
                return;
            }
            let groupedBloks = !props.grouped ? [] :
                result.map(r =>
                    (
                        [
                            {text: r.loginPDF, fontSize: 15, margin: [0, 20, 0, 10]},
                            {
                                table: {
                                    headerRows: 1,
                                    widths: cells.map(cl => 'auto'),
                                    body: [
                                        cells.map(cl => {
                                            return {text: cl.title, bold: true, style: 'tableHeader'}
                                        }),
                                        ...r.substrs.map(sbstr => (
                                            cells.map(cl => {
                                                    let k = cl.exportKey ? cl.exportKey : cl.key
                                                    return {
                                                        text: sbstr[k] ?
                                                            (k.indexOf("Date") !== -1 ?
                                                                    formatDate(sbstr[k])
                                                                    :
                                                                    (cl.exportText ?
                                                                            (sbstr[k] === "" ? "" : cl.exportText)
                                                                            :
                                                                            sbstr[k]
                                                                    )
                                                            )
                                                            :
                                                            "",
                                                        style: 'table'
                                                    }
                                                }
                                            )
                                        ))

                                    ]
                                },
                                "layout": "lightHorizontalLines",
                                "style": "table"
                            },
                        ]
                    )
                );
            let groupedBloksMainContent = []
            groupedBloks.forEach(gr => {
                groupedBloksMainContent.push(gr[0])
                groupedBloksMainContent.push(gr[1])
            })

            let styles = {
                header: {fontSize: 10, bold: false},
                tableHeader: {bold: true, fontSize: 10, alignment: 'center'},
                table: {fontSize: 10, alignment: 'center'}
            }

            let header = {
                margin: [30, 15, 0, 10], columnGap: 2, columns: [
                    {image: 'data:image/jpeg;base64,' + logo, maxHeight: 30, maxWidth: 143},
                    {
                        text: t("table.PDFDoc") + moment().format("DD.MM.YYYY HH:mm") +
                            (props.pdfHeader ? "\n" + props.pdfHeader : "")
                        , style: 'header', width: 350, margin: [50, 0, 0, 0]
                    },
                ]
            }

            const docDefinition = !props.grouped ?
                {
                    "header": header,/*{
                        "text":"Porous Tube Piezometer\nBatu Dam\n23, Jun 2014",
                        "alignment":"center",
                        "margin":[0,40,0,0]},*/
                    "content": {
                        "table": {
                            "headerRows": 1,
                            "widths": cells.map(cl => (cl.key === "status" ? 1 : (cl.pdfMaxW ? cl.pdfMaxW : 'auto'))),
                            "body":/*[
                                ["#","PTP ","Station ","Offset ","Elevation of P.Tube (Meter)","Top Ele Initial (Meter)","Top Ele Present (Meter)","Settlement (Meter)","Field Reading (Meter)","Piezo Head (Meter)","Initial Piezo Ele (Meter)","Remarks ","Status"],
                                ["1. ","PTP-1","09+75","112D","60.745","72.698","72.695","0.003","","","68.918","","X"],
                                ["2. ","PTP-2","09+75","190D","68.352","72.828","72.802","0.026","","","68.268","","X"],
                                ["3. ","PTP-3A","09+09","096D","52.701","76.525","76.524","0.001","","","69.825","","X"],
                                ["4. ","PTP-3B","09+09","096D","71.451","76.525","76.524","0.001","","","71.435","","X"],
                                ["5. ","PTP-4","09+06","184D","46.69","76.855","76.855","0","","","68.455","","X"],
                                ["6. ","PTP-5A","08+08","092D","53.448","76.985","76.984","0.001","","","69.975","","X"],
                                ["7. ","PTP-5B","08+08","092D","72.048","76.985","76.984","0.001","","","71.965","","X"],
                                ["8. ","PTP-6","08+08","190D","46.42","77.225","77.219","0.006","","","69.125","","X"],
                                ["9. ","PTP-7","08+00","040D","70","93.452","93.452","0","","","71.682","","X"],
                                ["10. ","PTP-8","07+10","040D","70.03","97.172","97.147","0.025","","","77.932","","X"],
                                ["11. ","PTP-9A","06+00","010D","70.418","107.638","107.633","0.005","","","86.408","","X"],
                                ["12. ","PTP-9B","06+00","010D","87.018","107.638","107.633","0.005","","","85.058","","X"],
                                ["13. ","PTP-10","04+43","017D","71.42","112.153","112.153","0","","","85.713","","X"],
                                ["14. ","PTP-11","10+99","060D","85.294","93.72","93.707","0.013","","","85.91","","X"],
                                ["15. ","PTP-12A","10+83","020D","77.229","103.931","103.917","0.014","","","78.301","","X"],
                                ["16. ","PTP-12B","10+83","020D","99.129","103.931","103.917","0.014","","","DRY","","X"],
                                ["17. ","PTP-13","03+84","007D","88.711","113.111","113.111","0","","","101.211","","X"],
                                ["18. ","PTP-14","04+52","024D","88.678","101.196","101.196","0","","","95.516","","X"],
                                ["19. ","PTP-15","03+94","048D","90.267","113.512","113.512","0","","","100.157","","X"],
                                ["20. ","PTP-16","04+54","050D","88.62","93.04","93.04","0","","","89.93","","X"],["21. ","DH-129","07+82","095D","0","77.185","77.174","0.011","","","73.595","","X"],
                                ["22. ","DH-130","07+42","080D","0","82.077","82.066","0.011","","","75.627","","X"]
                            ]*/
                                [
                                    cells.map(cl => {
                                        return {
                                            text: cl.exportHeader ? cl.exportHeader : cl.title,
                                            //bold: true,
                                            style: 'table'
                                        }
                                    }),
                                    ...result.map((arr, i) => cells.map(cl => {
                                            let k = cl.exportKey ? cl.exportKey : cl.key
                                            return {
                                                text:
                                                    k !== "status" && arr[k] ?
                                                        (k.indexOf("Date") !== -1 ?
                                                                formatDate(arr[k])
                                                                :
                                                                (cl.exportText ?
                                                                        (arr[k] === "" ? "" : cl.exportText)
                                                                        :
                                                                        arr[k]
                                                                )
                                                        )
                                                        :
                                                        ""
                                                ,
                                                style: 'table',
                                                fillColor: cl.key === "status" ? arr.statusColor : i % 2 === 0 ? "#cbcbcb" : "#ffffff"
                                            }
                                        }
                                    ))
                                ]
                        },
                        "layout": "lightHorizontalLines",
                        "style": "table"
                    },
                    "styles": {
                        "header": {"fontSize": 10, "bold": false},
                        "tableHeader": {"bold": true, "fontSize": 10, "alignment": 'center'},
                        "table": {"fontSize": 10, "alignment": 'center'}
                    },
                    "pageOrientation": "landscape",
                    "pageMargins": [10, 50, 10, 20]
                }
                :

                {
                    styles: styles,
                    pageSize: 'A4',
                    pageOrientation: 'landscape',
                    pageMargins: [10, 50, 10, 20],
                    header: header,
                    content: groupedBloksMainContent
                }
            ;
            pdfMake.createPdf(docDefinition).download('document.pdf');
        })();
    }}
    >
        <i className="fas fa-file-pdf"/>&nbsp;&nbsp;PDF
    </button>
}