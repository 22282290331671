import {serverURL, corsHeaders} from "./constants";
const axios = require('axios');

export default async function checkBackLoading(id){
    try {
        let response = await axios({
            method: 'get',
            url: serverURL + 'transactions/checkLoad/?load_id=' + id,
            headers: {
                'accept': 'application/json',
                ...corsHeaders
            },
        });

        if(response.status !== 200)
            return {result: false, error: 200}

        return {result: response.data, error: response.status}
    } catch (error) {
        return {result: false, error: error.response ? error.response.status : 500}
    }
}
