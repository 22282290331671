import { MapContainer, TileLayer, Marker, Popup, Tooltip, Rectangle} from 'react-leaflet'
import React, { useEffect } from "react";
import { Icon } from 'leaflet';
/*
    installation guide https://react-leaflet.js.org/docs/start-installation/

    package dependencies
        "@react-leaflet/core": ">=1.0.0 <1.1.0 || ^1.1.1",
        "leaflet": "^1.7.1",
        "react-leaflet": "^3.2.2",

        "browserslist": [
            ">0.2%",
            "not dead",
            "not op_mini all"
          ],

    add to head of public html
        <link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css" integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A==" crossorigin=""/>

    add to body of public html
        <script src="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js" integrity="sha512-XQoYMqMTK8LvdxXYG3nZ448hOEQiglfqkJs1NOQV44cWnUrBc8PkAOcXy20w0vlaXaVUearIOBhiXZ5V3ynxwA==" crossorigin=""></script>


    if error occurred then
        - Delete your node_modules folder
        - Run npm cache clean --force
        - Run npm install
 */

export default function Map(props){
    //let markerText = props.markerText
    let height = props.height
    let width = props.width

    let points = props.points || []
    let center = points.reduce((s, p) => [p.lat, p.lon], [0, 0])

    /*const rectangle = [
        [props.lat, props.lon],
        [props.lat, props.lon],
    ]*/
    return  <MapContainer center={props.center || center}
                key={props?.center?.toString() + new Date().toString()}
                style={{ height: height, width: width }}
                zoom={18} scrollWheelZoom={true}>
        <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {/*
            props.isMarker ?
                <Marker position={center}>
                    <Popup>
                        {markerText}
                    </Popup>
                </Marker>
                :
                <Rectangle bounds={rectangle} pathOptions={{ color: 'black' }}>
                    <Tooltip direction="top" offset={[0, 0]} opacity={1} permanent>
                        {markerText}
                    </Tooltip>
                </Rectangle>
        */}
        {
            points.map((p, i) => (
                p.isMarker ?
                    <Marker icon={ new Icon ({
                        iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${!p.icon ? 'green' : 'blue'}.png`,
                        iconSize: [25, 41],
                    })} position={[p.lat, p.lon]} key={"k_" + i}>
                        <Popup offset={[0,-10]} >
                            {p.markerText}
                        </Popup>
                    </Marker>
                    :
                    <Rectangle
                        bounds={
                            [
                                [p.lat, p.lon],
                                [p.lat, p.lon],
                            ]
                        }
                        pathOptions={{ color: 'black' }}
                        key={"k_" + i}
                    >
                        <Tooltip direction={["top", "bottom"][i % 2]} offset={[0, 0]} opacity={1} permanent>
                            {p.markerText}
                        </Tooltip>
                    </Rectangle>
            ))
        }
    </MapContainer>
}