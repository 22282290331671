import React, {useContext, useState} from "react";
import {IconButton, InputAdornment, TextField, FormControl, Grid, Checkbox} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import "./PairOfGlasses.css"
import {useTranslation} from "react-i18next";
import {Context} from "../Context";

export default function PairOfGlasses(props){
    let [context, setContext] = useContext(Context);
    let maxHeight = "52vh"
    let labelL = props.labelL || "LabelL"
    let labelR = props.labelR || "labelR"
    let saveF = props.saveF
    let [arr, setArr] = useState(props.arr.map((el, i) => {return {...el , checked: false}}))
    let [arrOriginal, setOriginal] = useState(arr)

    let [searchLineL, setSearchLineL] = useState("")
    let [searchLineR, setSearchLineR] = useState("")

    let [pageR, setPageR] = useState(0)
    let [pageL, setPageL] = useState(0)
    let strsOnOnePage = 100

    let [btnColor, setBtnColor] = useState('dark');
    const { t, i18n } = useTranslation();

    let save = async () => {
        if(btnColor !== 'dark')
            return;
        setBtnColor('warning')
        let {result, error} = await saveF(arr, arrOriginal);
        if(error === 401) context.error_401();
        setBtnColor(result ? 'success' : 'danger')
        if(result)
            setOriginal(arr)
        setTimeout(() => {setBtnColor('dark')}, 500)
    }

    let moveAll = (isRight) => {setPageL(0); setPageR(0); setArr(arr.map(el => {return {...el, right: !isRight, checked: false}}))}
    let moveChecked = (isRight) => {setPageL(0); setPageR(0); setArr(arr.map(el => {return {...el, right: el.checked && el.right === isRight? !el.right : el.right, checked: el.checked && el.right === isRight ? false : el.checked}}))}
    let checkLi = (id) => {setArr(arr.map(el => (el.id === id ? {...el, checked: !el.checked} : el)))}
    let amountChecked = (isRight) => {return arr.reduce((s, el) => s + ((el.right === isRight) && el.checked ? 1 : 0), 0)}

    let makeList = (isRight) => {
        let checked = <div className="col-1"><i className={"far fs-5 fa-check-square text-primary"}/></div>
        let notChecked = <div className="col-1"><i className={"far fs-5 fa-square"}/></div>

        let byf = arr.filter(el => (isRight ? el.right : !el.right) &&
            (isRight ?
                    searchLineR === "" || el.name.toLowerCase().indexOf(searchLineR.toLowerCase()) !== -1 :
                    searchLineL === "" || el.name.toLowerCase().indexOf(searchLineL.toLowerCase()) !== -1
            ))

        let allStrs = byf.length
        byf = byf.filter((el, i) => (i >= ((isRight ? pageR : pageL) * strsOnOnePage) && i < ((isRight ? pageR : pageL) + 1) * strsOnOnePage))

        byf = byf.map((el, i) =>
                <li className={"list-group-item clickable " + ( el.right !== arrOriginal.filter(orEl => orEl.id === el.id)[0].right ? "unsavedLi" : (i % 2 === 0 ? "lightGrayLi" : "whiteLi"))} key={"key" + Math.random()}
                    onClick={() => {checkLi(el.id)}}
                    style={{userSelect: "none"}}
                >
                    <div className="row m-0 mw-100">
                        {!isRight ? null : el.checked ? checked : notChecked}
                        <div className="col-11">{el.name}</div>
                        {isRight ? null : el.checked ? checked : notChecked}
                    </div>
                </li>
            )
        if(allStrs > strsOnOnePage){
            byf = [...byf, (
                <li className={"list-group-item clickable lightGrayLi"} key={"key" + Math.random()}
                    style={{userSelect: "none"}}
                >
                    <div className="row m-0 mw-100">
                        <div className="col-6 text-center">
                            {
                                isRight && pageR > 0 || !isRight && pageL > 0 ?
                                    <button type="button" className="btn btn-outline-dark" onClick={() => {isRight ? setPageR(pageR - 1) : setPageL(pageL - 1) }}><i className="fas fa-backward"/>&nbsp;&nbsp;{t("glasses.previous") + " " + strsOnOnePage + " " + t("glasses.objects")}</button>
                                    :null
                            }
                        </div>

                        <div className="col-6 text-center">
                            {
                                isRight && (allStrs > (pageR + 1) * strsOnOnePage) || !isRight && (allStrs > (pageL + 1) * strsOnOnePage) ?
                                    <button type="button" className="btn btn-outline-dark" onClick={() => {isRight ? setPageR(pageR + 1) : setPageL(pageL + 1) }}>{t("glasses.next") + " " +  strsOnOnePage + " " + t("glasses.objects")} &nbsp;&nbsp;<i className="fas fa-forward"/></button>
                                    :null
                            }
                        </div>
                    </div>
                </li>
            )]
        }

        return (
            <ul className="list-group list-group-flush px-0" style={{maxHeight: maxHeight, overflow: "auto"}}>
                {byf.length ? byf : <div className="col-12 px-4 py-1 text-center">{t("glasses.empty")}</div>}
            </ul>
        )
    }

    let makeSearchLine = (isRight) => {
        return (
            <FormControl className="p-2 pb-3">
                <TextField
                    variant="standard" label="Поиск" value={isRight ? searchLineR : searchLineL}
                        onChange={(e) => {
                            if (isRight) {
                                setSearchLineR(e.target.value)
                                setPageR(0)
                            } else {
                                setSearchLineL(e.target.value)
                                setPageL(0)
                            }
                        }}
                       InputProps={{endAdornment: (<InputAdornment position='end'><IconButton><SearchIcon /></IconButton></InputAdornment>)}}
                />
            </FormControl>
        )
    }

    let makeGlass = (isRight => {
        return (
            <div className="row m-0 pb-2 mw-100">
                <div className="col-12 px-0 py-1 text-center bg-dark text-white rounded-top">
                    <div className="row m-0 mw-100" style={{fontSize: "1.3rem"}}>
                        <div className="col-1">{isRight && amountChecked(isRight) !== 0 ? amountChecked(isRight) : null}</div>
                        <div className="col-10" key={"key" + Math.random()}>
                            {isRight ? labelR : labelL}
                        </div>
                        <div className="col-1">{!isRight && amountChecked(isRight) !== 0 ? amountChecked(isRight) : null}</div>
                    </div>
                </div>
                {makeSearchLine(isRight)}
                {makeList(isRight)}
            </div>
        )
    })

    let makeInfoLine = (label, value, ps = "", pe = "") => {
        return (
            <div className={"col-4 px-0 py-1 ps-" + ps + " pe-" + pe}>
                <div className="rounded-3 p-2 " style={{backgroundColor: "lightgray"}}>{label}&nbsp;&nbsp;{value}</div>
            </div>
        )
    }

    let makeInfoLines = (isVisible) => {
        return (
            <div className={"row pb-2 m-0 mw-100 text-center " + (isVisible ? "" : "opacity-0")}>
                {makeInfoLine(t("glasses.allocated"), props.infoValues.Allocated, "0", "2")}
                {makeInfoLine(t("glasses.current"), props.infoValues.Current, "2", "2")}
                {makeInfoLine(t("glasses.available"), props.infoValues.Available, "2", "0")}
            </div>
        )
    }

    let makeArrow = (type, onclick) => (
        <div className="col-12 p-0 py-1 arrow" onClick={onclick}>
            <i className={"fas " + type}/>
        </div>
    )

    let buttonsWidth = "3rem"
    let glassWidth =  "calc((100vw - " + buttonsWidth + ") / 2)"

    return (
        <div className="SuperTransferList d-flex">
            <div className="bg-white rounded-3" style={{width: glassWidth}}>
                {makeInfoLines(false)}
                <div className="card">{makeGlass(false)}</div>
                <div className="py-4 px-0">
                    <div className="border-bottom rounded-3 p-2" style={{backgroundColor: "lightblue"}}>{t("glasses.warning")}</div>
                </div>
            </div>

            <div className="mx-2 fs-5 d-flex justify-content-center align-items-center" style={{width: buttonsWidth}}>
                <div className="row m-0 text-center">
                    {makeArrow("fa-angle-double-left", () => {moveAll(true)})}
                    {makeArrow("fa-angle-left", () => {moveChecked(true)})}
                    {makeArrow("fa-angle-right", () => {moveChecked(false)})}
                    {makeArrow("fa-angle-double-right", () => {moveAll(false)})}
                </div>
            </div>

            <div className="bg-white rounded-3" style={{width: glassWidth}}>
                {makeInfoLines(true)}
                <div className="card">{makeGlass(true)}</div>
                <div className="text-end py-4 px-0">
                    <button type="button" className={"me-2 btn btn-outline-dark"} onClick={() => {setPageL(0); setPageR(0); setArr(arrOriginal)}}>
                        {t("glasses.cancel")}&nbsp;&nbsp;<i className="fas fa-undo"/>
                    </button>

                    <button type="button" className={"btn btn-outline-" + btnColor} onClick={() => save()}>
                        {t("glasses.save")}&nbsp;&nbsp;<i className="fas fa-save"/>
                    </button>
                </div>
            </div>
        </div>
    )
}