import React, {useContext, useState, useEffect, useMemo} from "react";
import {Context} from "../Context";
import {Redirect} from 'react-router-dom';
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import Table from "./Table";
import loadTransactions from "../requests/loadTransactions";
import loadCards from "../requests/loadCards";
import {Backdrop, Tooltip} from "@material-ui/core";
import FilterDropdownList from "./FilterDropdownList";
import FilterType from "./FilterType";
import wialon_logo from "../wialon_logo_40.png"
import transaction_logo from "../transaction_40.png"
//import jerrican from "../jerrican.png"
import ExpandableRowData from "./ExpandableRowData";
import {useTranslation} from "react-i18next";
import {ClipLoader} from "react-spinners";
import DiapasonsDateTimePicker from "./DiapasonsDateTimePicker/DiapasonsDateTimePicker";
import ImportFileModal from "./ImportFileModal";
import {useDispatch, useSelector} from "react-redux";
import {importFromFileInit} from "../redux/actions/transactionActions";

export default function DataPage(){
    const dispatch = useDispatch()
    const [companyName, setCompanyName] = useState('')
    const [companyLogo, setCompanyLogo] = useState(undefined)
    const state = useSelector(state => state)
    useEffect(()=>{
        setCompanyName(state.app.detail?.service_name || 'Wialon')
        setCompanyLogo(state.app.serviceImg)
    }, [state])
    const [context, setContext] = useContext(Context);
    const [items, setItems] = useState([]);
    const [isShowCardsF, setIsShowCardsF] = useState(false);
    const [cards, setCards] = useState([]);
    const [dateTimeRanges, setDateTimeRanges] = useState({});

    const { promiseInProgress } = usePromiseTracker();
    const { t: _t, i18n } = useTranslation();
    const t = key => (_t(key) +
        (
            ["fuelTr", "fuelW", "diffL", "tank", "jerrican", "tr", "W", "diff",].includes(key.replace("data.", "")) ?
                ", " + _t("measures.water." + (localStorage.getItem("measureWater") || "liter") + '.short') : ""
        )
        +
        (
            ["tooltipFuelTr", "tooltipFuelW", "tooltipFuelDiff"].includes(key.replace("data.", "")) ?
                ", " + _t("measures.water." + (localStorage.getItem("measureWater") || "liter") + '.in') : ""
        )
    );

    let sleep = ms => new Promise(r => setTimeout(r, ms));

    let loadDropdown = async () => {
        let {result, error} = await loadCards()
        if(error === 401) context.error_401();
        setCards(result);
    };

    let loadData = async (ranges) => {
        let sl = sleep(300);
        let {result, error} = await loadTransactions(ranges.dMin, ranges.dMax)
        if(error === 401) context.error_401();
        await sl
        setItems(result);
    };

    useEffect(() => {
        trackPromise(loadDropdown());
        return () => {};
    }, []);

    useEffect(() => {
        trackPromise(loadData(dateTimeRanges));
        return () => {};
    }, [dateTimeRanges]);

    const niceLabels = useMemo(() => ({
        fuelTr: <div className="vAlign"><i className="fas fa-gas-pump d-table-cell pe-3"/><div className="d-table-cell m-0"><img src={transaction_logo} alt="T" style={{width: "1rem", height: "1rem"}}/></div></div>,
        fuelW: <div className="vAlign"><i className="fas fa-gas-pump d-table-cell pe-3"/><div className="d-table-cell m-0"><img src={`${companyLogo ? companyLogo : wialon_logo}`} alt="W" style={{width: "1rem", height: "1rem"}}/></div></div>,
        refund: <i className="fas fa-undo-alt"/>,
        suspicion: <i className="fas fa-exclamation-triangle"/>,
        fuelDiff: <i className="fas fa-balance-scale-right"/>,
        tankVolume: t("data.jerrican")/*<img src={jerrican} alt="W" style={{width: "1rem", height: "1rem"}}/>*/,
    }), [transaction_logo, wialon_logo, companyLogo])

    if(context.login === "")
        return <Redirect to='/auth'/>

    let columns = [
        {name: "",                   key: "status"},
        {name: "",                   key: "status",      empty: true},
        {name: t("data.transport"),  key: "transport",   sort: "t", pdfMaxW: 100, filter: true, nowrap: true, exportKey: "transport", searchKey: "transport"},
        {name: t("data.date"),       key: "date",        sort: "d", pdfMaxW: 43},
        {name: t("data.card"),       key: "cardNumber",  sort: "t", pdfMaxW: 43, filter: true},
        {name: t("data.gasStation"), key: "fuelCompany", sort: "t", pdfMaxW: 43, filter: true},
        {name: t("data.address"),    key: "address",     sort: "t", pdfMaxW: 85, filter: true},
        {name: t("data.fuelType"),   key: "fuelType",    sort: "t", pdfMaxW: 43, filter: true},
        {name: t("data.fuelTr"),     key: "fuelTr",      sort: "f", pdfMaxW: 30, thName: niceLabels.fuelTr,      tooltip: t("data.tooltipFuelTr")},
        {name: "", key: "trend", exportKey: "trendPDF"},
        {name: t("data.fuelW").replace('tms', `${companyName ? companyName : 'Wialon'}`),      key: "fuelW",       sort: "f", pdfMaxW: 30, thName: niceLabels.fuelW,       tooltip: t("data.tooltipFuelW").replace('tms', `${companyName ? companyName : 'Wialon'}`)},
        {name: t("data.diffL"),      key: "fuelDiff",    sort: "f", pdfMaxW: 30, thName: niceLabels.fuelDiff,    tooltip: t("data.tooltipFuelDiff").replace('tms', `${companyName ? companyName : 'Wialon'}`), exportKey: "fuelDiffPDF"},
        {name: t("data.tank"),       key: "tankVolume",  sort: "f", pdfMaxW: 30, thName: niceLabels.tankVolume,  tooltip: t("data.tooltipTankVolume")},
        {name: t("data.price"),      key: "price",       sort: "f", pdfMaxW: 30, exportKey: "pricePDF"},
        {name: t("data.tooltipRefund"),   key: "refund",  sort: "t", pdfMaxW: 43, thName: niceLabels.refund,      tooltip: t("data.tooltipRefund"),    exportText: "\\/"},
        {name: t("data.tooltipSuspicion"),key: "suspicion",sort: "t", pdfMaxW: 43, thName: niceLabels.suspicion,   tooltip: t("data.tooltipSuspicion"), exportText: "\\/"},
        {name: "",                   key: "status"},
        {name: "", key: "fillingInfo", detail: true},
        {name: "", key: "refundInfo", detail: true},
        {name: "", key: "fillings", detail: true},
        {name: "", key: "refunds", detail: true},
        {name: "", key: "wialonInfo", detail: true},
        {name: "", key: "latFuel", detail: true},
        {name: "", key: "lonFuel", detail: true},
        {name: "", key: "latWialon", detail: true},
        {name: "", key: "lonWialon", detail: true},
    ].map(el => ({...el, floatC: true, floatCA: true}))

    let summary = [
        {title: t("data.tr"), key:"fuelTr"},
        {title: t("data.W").replace('tms', companyName), key:"fuelW"},
        {title: t("data.diff"), action: (a, b) => a - b, key1:"fuelTr", key2: "fuelW", resultAction: a => Math.abs(a)},
    ]

    return (
        <div>
            <div className="container-fluid">
                <div className="px-3">

                    <div className="btn-group px-0 py-2" role="group">
                        <DiapasonsDateTimePicker card={dateTimeRanges} setCard={setDateTimeRanges}/>

                        <Tooltip title={(isShowCardsF ? t("data.close") : t("data.open")) + " " + t("data.filterOfFuelCard")}>
                            <i className={"fas fs-3 ps-2 fa-sort-down " + (isShowCardsF ? " text-primary" : "")}
                               style={{
                                   cursor: "pointer",
                                   paddingTop: "1.2rem"
                               }}
                               onClick={()=>{setContext({...context, filter: {...context.filter, cards: []}}); setIsShowCardsF(!isShowCardsF)}}
                            />
                        </Tooltip>
                    </div>
                    {isShowCardsF ? <div className="row pt-2 pb-2 m-0 mw-100"><div className="col-6 px-0"><FilterDropdownList contextKey="cards" contextSubKey="filter" items={cards}/></div></div> : null}

                    <div className="py-2"><FilterType/></div>

                    {promiseInProgress?
                        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}><ClipLoader color={"#ffffff"} loading={true} size={70} /></Backdrop>
                        :
                        <Table items={items
                            .filter(el => context.filter.type[el.status])
                            .filter(el => context.filter.cards.length === 0 ? true : (context.filter.cards.reduce((s, fEl) => s + (fEl.value === el.cardNumber ? 1 : 0), 0) > 0))
                            .map(el => ({
                                ...el,
                            }))
                            .map(el => {
                                let fuelTr = parseFloat(el.fuelTr)
                                let fuelW = parseFloat(el.fuelW)

                                if(!fuelTr || !fuelW)
                                    return el

                                let trend = fuelTr < fuelW
                                let difference = Math.abs(fuelTr - fuelW)
                                let bias = ((fuelTr + fuelW) / 2) * (5 / 100)

                                if(fuelTr.toFixed(2) === fuelW.toFixed(2))
                                    return {
                                        ...el,
                                        trendPDF: "=",
                                        trend: <i className="fas fa-equals text-secondary"/>
                                    }

                                return {
                                    ...el,
                                    trendPDF: trend ? ">" : "<",
                                    trend:  trend ? ">" : "<",
                                }
                            })
                        }
                               columns={columns}
                               ExpandableRow={ExpandableRowData}
                               expandOnSingleClick
                               summary={summary}
                               pdfHeader={
                                   t("table.PDFPeriod") + (dateTimeRanges && dateTimeRanges.dMin && dateTimeRanges.dMax ?
                                   dateTimeRanges.dMin.format("DD.MM.YYYY HH:mm") + " - " +
                                   dateTimeRanges.dMax.format("DD.MM.YYYY HH:mm") : "")
                               }
                        />
                    }
                </div>
            </div>
        </div>
    )
}