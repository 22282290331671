import React, {useContext, useEffect, useState} from "react";
import Typography from '@mui/material/Typography';
//import logo from "../logo_light.png"
import {Context} from "../Context";
import LangSwitcher from "./LangSwitcher";
import {NavLink} from "react-router-dom";
import { useHistory } from "react-router-dom";
import "./Navbar.css"
import moment from "moment";
import getBalance from "../requests/getBalance";
import {useTranslation} from "react-i18next";
import logOut from "../requests/logOut";
import {useSelector} from 'react-redux'


export default function Navbar(){
    const logo = useSelector(state => state.app.navbarLogo)
    let [context, setContext] = useContext(Context);
    let history = useHistory();
    let [path, setPath] = useState(history.location.pathname);
    let [billing, setBilling] = useState(false);
    const { t, i18n } = useTranslation();

    let logout = async () => {await logOut()}
    async function balancePingLoop() {
        if(localStorage.getItem('login')) {
            let {result, error} = await getBalance()
            if(error === 401) context.error_401();
            if(result) setBilling(result);
        }
        setTimeout(() => {balancePingLoop()}, 5000)
    }

    useEffect(() => {
        balancePingLoop();
        return () => {};
    }, []);

    let makeLink = (title, key, isExact = false) => {
        return  context.login === "" ? null :
            <div className="d-flex px-2 text-center">
                <NavLink className={"navlink text-decoration-none fs-5 text-uppercase"}
                     exact={true} to={"./" + key} onClick={()=>{clearFilter(); setPath(window.location.pathname);}}
                >
                    {title}
                </NavLink>
            </div>

    }

    let clearFilter = () => {
        setContext({...context, filter: {
            dateTimeRanges: {min: moment().startOf('day'), max: moment().endOf('day')},
            cards: [],
            type: Array(5).fill(true),
            checkboxes: [],
    }})}

    if(!billing && context.login && context.billing_state)
        setBilling(context.billing_state)

    return  (
        <div className={"navbar container-fluid px-5 bg-dark d-flex align-items-center text-white " + (!context.login || context.login === "" ? "opacity-0" : "")}>
                <img className="ps-2 pe-5" style={{height: "2.5rem"}} src={logo} alt="..." loading="lazy"/>
                {context.rights && (context.rights.ViewRights) ? makeLink(t("navbar.data"), "data",true) : ""}
                {context.rights && (context.rights.ViewRights) ? makeLink(t("navbar.dash"), "dashboard") : ""}
                {makeLink(t("navbar.settings"), "settings")}
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}/>
                <div className="px-3 h-100 d-flex align-items-center" style={{height: "6vh", cursor: "pointer"}}>
                    {context.login && billing ? t("navbar.balance") + " " + billing.Current + " / " + billing.Allocated : ""}
                </div>
                <div className="fs-5 px-3 h-100 d-flex align-items-center" style={{height: "6vh", cursor: "pointer"}}>
                    {context.login}
                    &nbsp;&nbsp;
                    {
                        context.login === "" ? null :
                            <i className="fas fa-sign-out-alt" onClick={() => {
                                logout()
                                localStorage.removeItem("login");
                                setBilling(false)
                                setContext({...context, login: "", rights: null})
                            }}/>
                    }
                </div>
                <LangSwitcher/>
            </div>
    )
}