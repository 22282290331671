import React, {useContext, useState, useEffect} from "react";
import {Context} from "../Context";
import {Redirect} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useTranslation} from "react-i18next";
import {Skeleton} from "@material-ui/core";
import loadFuelChart from "../requests/loadFuelChart";
import loadBrandChart from "../requests/loadBrandChart";

import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import FilterDropdownList from "./FilterDropdownList";
import loadCards from "../requests/loadCards";
import DiapasonsDateTimePicker from "./DiapasonsDateTimePicker/DiapasonsDateTimePicker";

export default function Dashboard(){
    const [context, setContext] = useContext(Context);
    const { t, i18n } = useTranslation();
    let lk = "dash."
    const [card, setCard] = useState({});
    const [loading, setLoading] = useState([true, true, true, true]);
    const [data, setData] = useState([]);
    const [noData, setNoData] = useState(false);
    const [headers, setHeaders] = useState(["Вид топлива, л.", "Вид топлива, ", "Бренд, л.", "Бренд, "]);
    const [charts, setCharts] = useState([<></>, <></>, <></>, <></>]);
    const [cards, setCards] = useState([]);
    let colors = ["#8884d8","#8dd1e1","#81ca9d","#a4de6c","#ffc558","#97c1ba","#8ac6a8", ]

    let sleep = ms => new Promise(r => setTimeout(r, ms));

    let loadingDash = async () => {
        setLoading([true, true, true, true])
        setNoData(false)
        let req1_pr = loadFuelChart(card.dMin, card.dMax, context.dashCards ? context.dashCards : []);
        let req2_pr = loadBrandChart(card.dMin, card.dMax, context.dashCards ? context.dashCards : []);
        let loadedCards_pr = loadCards();
        let sl = sleep(1500);
        let {result: req1, error: error1} = await req1_pr;
        let {result: req2, error: error2} = await req2_pr;
        if(error1 === 401) context.error_401();
        if(error2 === 401) context.error_401();

        if(!req1.length || !req2.length)
            setNoData(true)
        let re1Symbol = req1.length ? req1[0].cur_symbol : ""
        let re2Symbol = req2.length ? req2[0].cur_symbol : ""
        let allData = [
            req1.map(el => ({name: el.fuel_type, [t(lk + "fuelLValue")]: el.liters_balance})),
            req1.map(el => ({name: el.fuel_type, [t(lk + "fuelPValue")]: el.sum_balance})),
            req2.map(el => ({name: el.brand, [t(lk + "fuelLValue")]: el.liters_balance})),
            req2.map(el => ({name: el.brand, [t(lk + "fuelPValue")]: el.sum_balance})),
        ]
        let {result: loadedCards, error: error3} = await loadedCards_pr;
        if(error3 === 401) context.error_401();
        sl = await sl;

        setCharts(charts.map((el, i) => (
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    data={allData[i]}
                    margin={{top: 5, right: 5, left: 5, bottom: 5}}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name"/>
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey={i % 2 === 0 ? t(lk + "fuelLValue") : t(lk + "fuelPValue")}>
                        {allData[i]
                            .map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        )))
        setCards(loadedCards);
        setLoading([false, false, false, false])
        setHeaders([t(lk + "fuelL"), t(lk + "fuelP") + (re1Symbol.length ? ", " + re1Symbol: ""), t(lk + "brandL"), t(lk + "brandT") + (re2Symbol.length ? ", " + re2Symbol: "")])
        setData(allData)
    };

    useEffect(() => {
        loadingDash();
        return () => {};
    }, [card, context.dashCards]);

    if(context.login === "")
        return <Redirect to='/auth'/>

    let makeDash = i => {
        return (
            <div className="col-6 px-2 py-2" key={"dash" + i}>

                <div className="my-2 py-1 text-center bg-dark rounded-3 text-white fw-bold">
                    {headers[i]}
                </div>

                <div className="rounded-3" style={{height: "25vh"}}>
                    {
                        !loading[i]?
                            charts[i]
                            :
                            <Skeleton className="rounded-3 h-100" animation="wave" variant="rectangular"/>
                    }
                </div>

            </div>
        )
    }

    let makeLabelLine = i => {
        return (
            <div className="col-12 px-2 pt-2 pb-4" key={"labelLine_" + i}>
                {
                    !loading[i] && data.length?
                        <div className="d-flex justify-content-evenly">
                            {
                                data[i].map((d, j) => <div key={"label_" + i + "_" + j}>
                                    <i className="fas fa-square" style={{color: colors[j % colors.length]}}/>
                                    &nbsp;&nbsp;
                                    {d.name}
                                </div>)
                            }
                        </div>
                        :
                        <Skeleton className="rounded-3 w-100" animation="wave"/>
                }
            </div>
            )
    }

    return  (
        <div className="py-2 px-3 overflow-hidden">

            <div className="px-2 pb-2">
                <DiapasonsDateTimePicker card={card} setCard={setCard} />
            </div>

            <div className="px-2 pt-2">
                <FilterDropdownList contextKey="dashCards" items={cards}/>
            </div>

            {
                noData?
                    <div className="my-3 py-1 text-center bg-dark rounded-3 text-white fw-bold">
                        {t(lk + "noData")}
                    </div>
                    :
                    <div className="row m-0 mw-100">
                        {makeDash(0)}
                        {makeDash(1)}
                        {makeLabelLine(0)}
                        {makeDash(2)}
                        {makeDash(3)}
                        {makeLabelLine(2)}
                    </div>
            }
        </div>)
}