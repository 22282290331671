import React, {useContext, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {useTranslation} from "react-i18next";
import saveChangedTariff from "./requests/saveChangedTariff";
import {Context} from "../../Context";
import MakeInput from "../MakeInput";

export default function EditTariffModal(props){
    const [context, setContext] = useContext(Context);
    const { t, i18n } = useTranslation();
    let isOk = true
    let lk = "billing.EditTariffModal."
    let lkM = "billing.Management."
    const [saveBtnColor, setSaveBtnColor] = useState('dark');
    const [card, setCard] = useState(props.tr);

    console.log(card)

    const modalStyle = {position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "30rem", // width: "50vw", height: "95vh", overflow: "auto",
        bgcolor: '#eeeeee', border: '2px solid grey', borderRadius: "1rem", boxShadow: 24, p: 4
    };

    let save = async () => {
        if(saveBtnColor !== "dark")
            return;
        setSaveBtnColor('warning')
        let {result, error} = await saveChangedTariff(card)
        if(error === 401) context.error_401();
        if(result) setSaveBtnColor('success');
        else setSaveBtnColor('danger');
        setTimeout(() => {
            setSaveBtnColor('dark')
            if(result)
                props.handleClose(true)
        }, 800)
    }

    return (
        <Modal open={props.isOpen}
               onClose={(e, reason) => {if (reason !== 'backdropClick') {props.handleClose()}}}
        >
            <Box sx={modalStyle}>
                <div className="text-center py-2 mb-2 rounded-3 bg-dark text-white fw-bold">
                    {t(lk + "header")}
                </div>

                <div className="text-start rounded-3 pt-2">
                    <div className={"card  my-2 p-2 text-center"}>
                        <div className="row m-0 mw-100">
                            <div className="col-8 py-2">
                                <MakeInput value={card.name} label={t(lkM + "pack")} note="" mykey={"name"} error={saveBtnColor === 'danger'}
                                    onBlur={(e) => {setCard({...card, name: e.target.value})}}
                                />
                            </div>
                            <div className="col-4 py-2"><MakeInput value={card.symbolPDF + " " + card.symbol} label={null} note="" mykey={"symbol"} disabled={true}/></div>
                            <div className="col-12 py-2"><MakeInput value={card.creationDate} label={t(lkM + "creationDate")} note="" mykey={"creationDate"} disabled={true}/></div>
                            <div className="col-6 py-2"><MakeInput value={card.pricePerM} label={t(lkM + "pricePerM")} note="" mykey={"pricePerM"} disabled={true}/></div>
                            <div className="col-6 py-2"><MakeInput value={card.amObjs} label={t(lkM + "amObjs")} note="" mykey={"amObjs"} disabled={true}/></div>
                            <div className="col-12 py-2 text-end">
                                <div className="btn-group" role="group" aria-label="Basic example">
                                    <button type="button" className={"btn " + (card.isArchive ? "btn-dark" : "btn-outline-dark opacity-50")} onClick={() => (setCard({...card, isArchive: !card.isArchive}))}><i className="fas fa-eye-slash"/></button>
                                    <button type="button" className={"btn " + (!card.isArchive ? "btn-dark" : "btn-outline-dark opacity-50")} onClick={() => (setCard({...card, isArchive: !card.isArchive}))}><i className="fas fa-eye"/></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="text-end pt-2">
                    <button type="button" className={"me-2 btn btn-outline-dark"} onClick={props.handleClose}>
                        {t(lk + "cancel")}&nbsp;&nbsp;<i className="fas fa-window-close"/>
                    </button>

                    <button type="button" className={"btn btn-outline-" + saveBtnColor} onClick={() => {save()}}>
                        {t(lk + "save")}&nbsp;&nbsp;<i className="fas fa-save"/>
                    </button>
                </div>
            </Box>
        </Modal>
    )
}