import {
    IMPORT_FROM_FILE_INIT,
    IMPORT_FROM_FILE_START,
    IMPORT_FROM_FILE_SUCCESS,
    IMPORT_FROM_FILE_ERROR,
} from '../types/transactionTypes'

const initialState = {
    loading: null,
    error: null,
}

export const transactionReducer = (state = initialState, action) => {

    switch (action.type) {
        case IMPORT_FROM_FILE_INIT:
            return {
                loading: null,
                error: null,
            }

        case IMPORT_FROM_FILE_START:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case IMPORT_FROM_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
            }
        case IMPORT_FROM_FILE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default: return state
    }
}
