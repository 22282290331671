import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {useTranslation} from "react-i18next";

export default function ChangeTariffResultModal(props){
    const { t, i18n } = useTranslation();
    let isOk = props.isOk
    let lk = "ChangeTariffResultModal."

    const modalStyle = {position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "30rem", // width: "50vw", height: "95vh", overflow: "auto",
        bgcolor: '#eeeeee', border: '2px solid grey', borderRadius: "1rem", boxShadow: 24, p: 4
    };

    return (
        <Modal open={props.isOpen}
               onClose={(e, reason) => {if (reason !== 'backdropClick') {props.handleClose()}}}
        >
            <Box sx={modalStyle}>
                <div className="text-center py-2 mb-2 rounded-3 bg-dark text-white fw-bold">
                    {t(lk + "header")}
                </div>

                <div className="text-start rounded-3 pt-2">
                    <div className={"card  my-2 py-2 px-5 text-center"}>
                        <div className="px-2 py-4">{t(lk + (isOk ? "success" : "error"))}</div>
                    </div>
                </div>

                <div className="text-end pt-2">
                    <button type="button" className={"btn btn-outline-" + (isOk ? "success" : "danger")} onClick={() => {props.handleClose(true)}}>
                        {t(lk + "ok")}&nbsp;&nbsp;<i className={"fas fa-" + (isOk ? "check-circle" : "times-circle")}/>
                    </button>
                </div>
            </Box>
        </Modal>
    )
}