import {serverURL, corsHeaders} from "./constants";
const axios = require('axios');

export default async function removeUsers(id){
    try {
        let response = await axios({
            method: 'delete',
            url: serverURL + 'users/' + id,
            headers: {
                'accept': 'application/json',
                ...corsHeaders
            },
        });

        return {result: response.status === 200, error: response.status}
    } catch (error) {
        return {result: false, error: error.response ? error.response.status : 500}
    }
}