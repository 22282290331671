import {serverURL, corsHeaders} from "../../../requests/constants";
const axios = require('axios');

export default async function confirmReq(id, newStatus){
    try {
        let response = await axios({
            method: 'put',
            url: serverURL + 'billing/request/' + id ,
            headers: {
                ...corsHeaders,
                'Content-Type': 'application/json', // for post arr in data
            },
            data: JSON.stringify({
                status: newStatus
            })
        });

        return {result: response.status === 200, error: false}
    } catch (error) {
        return {result: [], error: error.response ? error.response.status : 500}
    }
}