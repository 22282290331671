import React from "react";
import {serverURL, corsHeaders, momentToServerFormat} from "./constants"
import moment from 'moment'
import {addCurrencySign} from "../functions";
const axios = require('axios');
//axios.defaults.withCredentials = true
export default async function loadTransactions(from, to){
    let formatDate = (str) => {
        if(!str) return ""
        let d = new Date(str)
        return d.toLocaleDateString('ru') + " " + d.toLocaleTimeString('ru').substr(0, d.toLocaleTimeString('ru').length - 3)
    }

    let formatBalance = (b) => parseFloat(b).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1 ")

    try {

        let response = await axios({
            method: 'get',
            url: serverURL + 'transactions/?from=' + momentToServerFormat(from) + '&to=' + momentToServerFormat(to) + "&wialon=true&paysystem=true",

            headers: {
                'accept': 'application/json',
                //'Authorization': 'Bearer ' + token,
                ...corsHeaders,
            },
        })

        if(response.status !== 200)
            return {result: [], error: response.status}

        let result = response.data.map(el => {

            let date = el.date ? el.date : (el.wialFills && el.wialFills.date ? el.wialFills.date: "")
            date = moment(date).isValid() ? formatDate(date) : ""
            let defaultFillingInfo = {trID: "", date: "", price: "", value: ""}

            /*
                status note:
                    trans yes, wialon no    red #ff6347
                    trans no, wialon yes    grey #a2a2a2
                    > 10%                   orange #ff9000
                    5-10%                   lGreen #6bcc7e
                    < 5%                    green #32aa48
            */

            let statusColors = ["#ff6347", "#709fdc", "#ff9000", "#6bcc7e", "#32aa48"]
            let status = el.pprFills.date && !el.wialFills.date ? 0 : (!el.pprFills.date && el.wialFills.date ? 1 : 777)
            if(status > 1){
                let deviation = status > 1 && el.unitTrankVol && parseInt(el.unitTrankVol) >= 0 && el.diff && parseInt(el.diff) > 0 ? parseInt(el.diff) / parseInt(el.unitTrankVol) : null
                status = deviation > 0.1 ? 2 : (deviation > 0.05 ? 3 : 4)
            }

            return {
                status: status,
                statusColor: statusColors[status],
                transport: el.objectName,
                date: date,
                cardNumber: el.cardNumber,
                fuelCompany: el.brand,
                address: el.address,
                fuelType: el.fuelType,

                fuelTr: el.pprFills.value ? el.currValue : "",
                dateTr: el.pprFills.date ? el.pprFills.date : "",

                fuelW: el.wialFills.value ? el.wialFills.value : "",
                dateW: el.wialFills.date ? el.wialFills.date : "",

                fuelDiff: status > 1 ? el.diff.toFixed(2) : "",
                //fuelDiffPDF: status > 1 ? el.diff.toFixed(2) : "",
                tankVolume: el.unitTrankVol && parseInt(el.unitTrankVol) >= 0 ? el.unitTrankVol : "",
                price: el.pprFills.summ ? "" + addCurrencySign(el.currencyInfo.is_right, el.currencyInfo.symbol, el.currSumm) : "",
                pricePDF: el.pprFills.summ ? "" + addCurrencySign(el.currencyInfo.is_right, el.currencyInfo.ISO_LAT, el.currSumm) : "",

                fillings: el.transactions ? el.transactions.map(tr => (
                    tr.date ? {trID: tr.transID, date: moment(tr.date).isValid() ? (formatDate(tr.date) + (tr.gmt ? " (" + tr.gmt + " GMT)" : "")) : "", price: "" + formatBalance(tr.summ) + (el.currencyInfo ? "  " + el.currencyInfo.symbol : ""), value: tr.value} : defaultFillingInfo
                )) : [{defaultFillingInfo}],
                refunds: el.transactions ? el.transactions.map(tr => (
                    tr.money_back ? {trID: tr.money_back.transID, date: moment(tr.money_back.date).isValid() ? (formatDate(tr.money_back.date) + (tr.money_back.gmt ? " (" + tr.money_back.gmt + " GMT)" : "")) : "", price: "" + formatBalance(tr.money_back.summ) + (el.currencyInfo ? "  " + el.currencyInfo.symbol : ""), value: tr.money_back.value} : defaultFillingInfo
                )) : [{defaultFillingInfo}],

                refund: el.pprFillsMB && el.pprFillsMB.date ? <i className="fas fa-info" key={"key" + Math.random()}/> : "",
                suspicion: el.suspicion ? <i className="fas fa-exclamation-triangle text-warning" key={"key" + Math.random()}/> : "",
                fillingInfo: el.pprFills.date ? {trID: el.pprFills.transID, date: moment(el.pprFills.date).isValid() ? formatDate(el.pprFills.date) : "", price: "" + formatBalance(el.pprFills.summ) + (el.currencyInfo ? "  " + el.currencyInfo.symbol : ""), value: el.pprFills.value} : defaultFillingInfo,
                refundInfo: el.pprFillsMB.date ? {trID: el.pprFillsMB.transID, date: moment(el.pprFillsMB.date).isValid() ? formatDate(el.pprFillsMB.date) : "", price: "" + formatBalance(el.pprFillsMB.summ) + (el.currencyInfo ? "  " + el.currencyInfo.symbol : ""), value: el.pprFillsMB.value} : defaultFillingInfo,
                //todo
                wialonInfo: el.wialFills.date ? {trID: el.wialFills.transID, date: moment(el.wialFills.date).isValid() ? (formatDate(el.wialFills.date) + (el.wialFills.gmt ? " (" + el.wialFills.gmt + " GMT)" : "")) : "", price: "", value: el.wialFills.value} : defaultFillingInfo,

                latFuel: el.fuelLat && el.fuelLat !== 0 ? el.fuelLat : null,
                lonFuel: el.fuelLon && el.fuelLon !== 0 ? el.fuelLon : null,

                latWialon: el.wialLat && el.wialLat !== 0 ? el.wialLat : null,
                lonWialon: el.wialLon && el.wialLon !== 0 ? el.wialLon : null,

                latObject: el.objectLat && el.objectLat !== 0 ? el.objectLat : null,
                lonObject: el.objectLon && el.objectLon !== 0 ? el.objectLon : null,
            }
        })

        return {result: result, error: response.status}
    } catch (error) {
        return {result: [], error: error.response ? error.response.status : 500}
    }
}