import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React from "react";
import MakeInput from "./MakeInput";
import {FormControl, MenuItem, Select} from "@material-ui/core";
import {useTranslation} from "react-i18next";

export default function RemoveModal(props){

    const { t, i18n } = useTranslation();

    const modalStyle = {position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',// width: "40vw",
        bgcolor: 'background.paper', border: '2px solid grey', borderRadius: "1rem", boxShadow: 24, p: 4
    };

    return (
        <Modal open={props.isOpen}
               onClose={(e, reason) => {if (reason !== 'backdropClick') {props.handleClose()}}}
        >
            <Box sx={modalStyle}>
                <div className="text-center py-2 mb-2 rounded-3 bg-dark text-white fw-bold">
                    {t("modalRemove.removing")}
                </div>

                <div className="text-start rounded-3">
                    <div className={"card  my-2 py-2 px-5"}>
                        <div className="row m-0 mw-100">
                            <div className="col-12 px-3 text-start py-2 px-5">
                                <div className="px-2 py-4">{t("modalRemove.areYouSure")}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="text-end pt-2">

                    <button type="button" className={"btn btn-outline-dark"} onClick={props.handleClose}>
                        {t("modalRemove.cancel")}&nbsp;&nbsp;<i className="fas fa-window-close"/>
                    </button>

                    <button type="button" className={"ms-2 btn btn-outline-" + props.btnColor} onClick={props.btnF}>
                        {t("modalRemove.remove")}&nbsp;&nbsp;<i className="fas fa-trash-alt"/>
                    </button>

                </div>
            </Box>
        </Modal>
    )
}