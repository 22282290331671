import {serverURL, corsHeaders} from "./constants";
const axios = require('axios');

export default async function loadingRights(){
    try {
        let response = await axios({
            method: 'get',
            url: serverURL + 'auth/myrights/',
            headers: {
                'accept': 'application/json',
                ...corsHeaders
            },
        });

        return {result: response.status === 200 ? response.data : null, error: response.status}
    } catch (error) {
        return {result: null, error: error.response ? error.response.status : 500}
    }
}