import React, {useContext, useEffect, useState} from "react";
import addUser from "../requests/addUser";
import editUser from "../requests/editUser";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {Autocomplete, TextField} from "@material-ui/core";
import MakeInput from "./MakeInput";
import {useTranslation} from "react-i18next";
import loadOrganizations from "../requests/loadOrganizations";
import {Context} from "../Context";

export default function EditUserModal(props){
    console.log(props.editingUser)
    const [context, setContext] = useContext(Context);
    let refArr = []
    const { t, i18n } = useTranslation();
    const [saveBtnColor, setSaveBtnColor] = useState('dark');
    let refsPush = (newRef) => {refArr = [...refArr.filter(el => el.key !== newRef.key), newRef]}
    const [card, setCard] = useState({
        orgName: props.editingUser && props.editingUser.ClientName ? props.editingUser.ClientName : "",
        name: props.editingUser && props.editingUser.UserName ? props.editingUser.UserName : "",
        login: props.editingUser && props.editingUser.login ? props.editingUser.login : "",
        password: "",
        passConfirm: ""
    });
    const [curOrg, setCurOrg] = useState(0);
    const [passError, setPassError] = useState(false);
    const [orgList, setOrgList] = useState([]);

    let loadOrgs = async () => {
        let {result, error} = await loadOrganizations()
        if(error === 401) context.error_401();
        result = result.map(el => ({id: el.id, name: el.name}))
        setOrgList(result);
    };

    useEffect(() => {
        loadOrgs();
        return () => {};
    }, []);

    let getVByRefK = (key) => refArr.filter(el => el.key === key) ? "" + refArr.filter(el => el.key === key)[0].ref.current.value : "";

    let save = async () => {
        if(saveBtnColor !== "dark")
            return;
        setSaveBtnColor('warning')
        let lastCard = {...card, name: getVByRefK("name"), login: getVByRefK("login"), password: getVByRefK("pass"), passConfirm: getVByRefK("passConfirm"),
            client_id: curOrg.id
        }
        setCard(lastCard)
        let {result, error} = props.editingUser ? await editUser(lastCard, props.editingUser.id) : await addUser(lastCard)
        if(error === 401) context.error_401();
        if(result) setSaveBtnColor('success');
        else setSaveBtnColor('danger');
        setTimeout(() => {
            setSaveBtnColor('dark')
            if(result)
                props.handleClose(true)
        }, 800)
    }

    const modalStyle = {position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "30rem", // width: "50vw", height: "95vh", overflow: "auto",
        bgcolor: '#eeeeee', border: '2px solid grey', borderRadius: "1rem", boxShadow: 24, p: 2
    };

    let updateCard = () => {setCard({name: getVByRefK("name"), login: getVByRefK("login"), password: getVByRefK("pass"), passConfirm: getVByRefK("passConfirm")})
    }

    let checkPasswords = () => {
        updateCard()
        setPassError(getVByRefK("pass") !== getVByRefK("passConfirm"))
    }

    return (
        <Modal open={props.isOpen}// onClose={props.handleClose}
               onClose={(e, reason) => {if (reason !== 'backdropClick') {props.handleClose()}}}
        >
            <Box sx={modalStyle}>
                <div className="text-center py-2 mb-2 rounded-3 bg-dark text-white fw-bold">
                    {t("modalAddUser." + (props.editingUser ? "editUser" : "addUser") + "")}
                </div>

                <div className="text-start rounded-3">
                    <div className={"card  my-2 py-2"}>
                        <div className="row m-0 mw-100">
                            <div className="col-12 px-3 text-start py-2">
                                <div className="px-2 pt-2 pb-4"><i className="fas fa-user-alt"/>&nbsp;&nbsp;{t("modalAddUser.user")}</div>
                                <MakeInput value={card.name} label="" note="" mykey={"name"} refsPush={(newRef) => {refsPush(newRef)}} error={saveBtnColor === 'danger'}/>
                            </div>
                        </div>
                    </div>

                    <div className={"card  my-2 py-2"}>
                        <div className="row m-0 mw-100">
                            <div className="col-12 px-3 text-start py-2">
                                <div className="px-2 pt-2 pb-4"><i className="fas fa-building"/>&nbsp;&nbsp;{t("modalAddUser.org")}</div>

                                {
                                    props.editingUser ?
                                        <MakeInput value={card.orgName} label="" note="" mykey={"orgName"} refsPush={(newRef) => {refsPush(newRef)}} disabled/>
                                        :
                                        <Autocomplete
                                            onChange={(e, nv) => {
                                                setCurOrg(nv)
                                                updateCard()
                                            }}
                                            options={orgList}
                                            getOptionLabel={el => el ? el.name : ""}
                                            loading={orgList.length === 0}
                                            loadingText={t("modalAddUser.loadingText")}
                                            noOptionsText={t("modalAddUser.noOptionsText")}
                                            sx={{width: "100%"}}
                                            renderInput={(params) => <TextField {...params} label="" value="" variant="standard" error={saveBtnColor === 'danger'}/>}
                                            key={"AutocompleteKey"}
                                        />
                                }
                            </div>
                        </div>
                    </div>

                    <div className={"card  my-2 py-2"}>
                        <div className="row m-0 mw-100">
                            <div className="col-12 px-3 text-start py-2">
                                <div className="px-2 pt-2 pb-4"><i className="fas fa-address-card"/>&nbsp;&nbsp;{t("modalAddUser.auth")}</div>
                                <MakeInput value={card.login} label={t("modalAddUser.login")} note="" mykey={"login"} refsPush={(newRef) => {refsPush(newRef)}} error={saveBtnColor === 'danger'}/>
                                <MakeInput value={card.password} label={t("modalAddUser.pass")} note="" mykey={"pass"} refsPush={(newRef) => {refsPush(newRef)}} error={passError || saveBtnColor === 'danger'} onChange={checkPasswords}/>
                                <MakeInput value={card.passConfirm} label={t("modalAddUser.confirm")} note="" mykey={"passConfirm"} refsPush={(newRef) => {refsPush(newRef)}} error={passError || saveBtnColor === 'danger'} onChange={checkPasswords}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="text-end pt-2">
                    <button type="button" className={"me-2 btn btn-outline-dark"} onClick={props.handleClose}>
                        {t("modalAddUser.cancel")}&nbsp;&nbsp;<i className="fas fa-window-close"/>
                    </button>

                    <button type="button" className={"btn btn-outline-" + saveBtnColor} onClick={() => {save()}}>
                        {t("modalAddUser.save")}&nbsp;&nbsp;<i className="fas fa-save"/>
                    </button>
                </div>
            </Box>
        </Modal>
    )
}