import {serverURL, corsHeaders} from "./constants";
import { ISODate } from "./helpers";
const axios = require('axios');

export default async function loadOrganizations(){
    try {
        let response = await axios({
            method: 'get',
            url: serverURL + 'clients/',
            headers: {
                'accept': 'application/json',
                ...corsHeaders
            },
        });
        if(response.status !== 200)
            return {result: [], error: response.status}

        let result = response.data.map(el => {
            return {
                id: el.client_id,
                name: el.name,
                date: ISODate(el.created_at),
                creator: el.creator_name,
                creator_id: el.creator_id,
                amountObj: el.items_count,
                amountUsers: el.users_count,
            }
        })
        return {result: result, error: response.status}
    } catch (error) {
        return {result: [], error: error.response ? error.response.status : 500}
    }
}