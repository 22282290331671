import {serverURL, corsHeaders} from "./constants";
const axios = require('axios');

export default async function loadMigration(){
    
    try {
        let response = await axios({
            method: 'get',
            url: serverURL + 'items/all/',
            headers: {
                'accept': 'application/json',
                ...corsHeaders
            },
        });

        if(response.status !== 200)
            return {result: [], error: response.status}

        let result = {
            info: response.data.billing_state,
            units: response.data.units.map(el => {
                return {
                    id: el.wialon_id,
                    name: el.name,
                    right: el.in_system,
                }
            })
        }

        return {result: result, error: response.status}
    } catch (error) {
        return {result: [], error: error.response ? error.response.status : 500}
    }
}