import React, {useContext, useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import loadCreatedPacks from "./requests/loadCreatedPacks";
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import {Backdrop, CircularProgress} from "@material-ui/core";
import Table from "../Table";
import {useTranslation} from "react-i18next";
import ClientsList from "./ClientsList";
import {Context} from "../../Context";
import ResultModal from "./ResultModal";
import EditTariffModal from "./EditTariffModal";
import {Tooltip} from "@mui/material";

export default function Management(){
    const [context, setContext] = useContext(Context);
    const [items, setItems] = useState([]);
    const [editingTariff, setEditingTariff] = useState(null);
    const { promiseInProgress } = usePromiseTracker();
    const { t, i18n } = useTranslation();
    let lk = "billing.Management."
    const [needRefresh, setNeedRefresh] = useState(Math.random());

    let loadData = async () => {
        let {result, error} = await loadCreatedPacks()
        if(error === 401) context.error_401();
        let addCurrencySign = (is_r, s, v) => ("" + (is_r ? "" : " " + s) + v + (is_r ? " " + s : ""))
        if(result.length)
            result = result.map(r => ({
                ...r,
                archiveSign: <i className={"fas fa-eye" + (r.isArchive ? "-slash" : "")}/>,
                forDealerOnly: r.isForDealerOnly ? <Tooltip title={t(lk + "forDealerOnly")}><i className="fas fa-network-wired"/></Tooltip> : null,
                archivePDF: r.isArchive ? "Архивный": "",
                pricePerM: addCurrencySign(r.is_right, r.symbol, r.pricePerM),
                pricePerMPDF: addCurrencySign(r.is_right, r.symbolPDF, r.pricePerM),
                edit: <i className="fas fa-edit" onClick={() => {if(editingTariff) return; setEditingTariff(r)}}/>,
            }))
        setItems(result);
    };

    useEffect(() => {
        trackPromise(loadData());
        return () => {};
    }, [needRefresh]);

    let columns = [
        {name: "",                      key: "archiveSign",    exportKey: "archivePDF", width: "5rem"},
        {name: t(lk + "pack"),          key: "name",           filter: true},
        {name: t(lk + "creationDate"),  key: "creationDate"},
        {name: t(lk + "pricePerM"),     key: "pricePerM",      exportKey: "pricePerMPDF"},
        {name: t(lk + "amObjs"),        key: "amObjs"},
        {name: t(lk + "amConnections"), key: "amConnections"},
        {name: "", key: "forDealerOnly"},
        {name: "",                      key: "edit",           width: "2rem", noExport: true}
    ].map(el => ({...el, floatC: true, floatCA: true, sort: "noSort"}))

    return  (
        <div className="py-2">
            {
                promiseInProgress ?
                    <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={true}><CircularProgress color="inherit"/></Backdrop>
                    :
                    <Table items={items} columns={columns} doNotExpand={true}/>
            }

            {
                editingTariff ?
                    <EditTariffModal
                        tr={editingTariff}
                        isOpen={true} handleClose={(doUpdate = false) => {setEditingTariff(null); if(doUpdate) setNeedRefresh(Math.random())}}
                    />
                    : null
            }

            <ClientsList refreshParent={setNeedRefresh}/>
        </div>

    )
}