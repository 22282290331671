import React, {useContext, useState, useEffect} from "react";
import {Context} from "../Context";
import {Redirect} from 'react-router-dom';
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import Table from "./Table";
import {Backdrop, CircularProgress} from "@material-ui/core";
import loadOrganizations from "../requests/loadOrganizations";
import removeOrganization from "../requests/removeOrganization";
import RemoveModal from "./RemoveModal";
import EditOrgModal from "./EditOrgModal";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";


export default function OrgList(){
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const snackDef = {variant: "warning", anchorOrigin: {vertical: 'bottom', horizontal: 'right'}, autoHideDuration: null, action: key => <i className="fas fa-times pe-2" style={{cursor: "pointer"}} onClick={e => {closeSnackbar(key)}}/>}

    const [context, setContext] = useContext(Context);
    const [items, setItems] = useState([]);
    const [addOrgBtnColor, setAddOrgBtnColor] = useState('dark');
    const { promiseInProgress } = usePromiseTracker();
    const [needRefresh, setNeedRefresh] = useState(Math.random());
    const [removingId, setRemovingId] = useState(null);
    const [removingBtnColor, setRemovingBtnColor] = useState('dark');
    const [editingId, setEditingId] = useState(null);
    let cr = context.rights
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const [modalType, setModalType] = useState(null);
    const modalOpen = (type) => {setModalType(type); setOpen(true)};
    const modalClose = (isSuccess = false) => {setModalType(null); setOpen(false); if(isSuccess) setNeedRefresh(Math.random())};

    let sleep = ms => new Promise(r => setTimeout(r, ms));

    let addOrEditOrg = async (id = null) => {
        modalClose()
        setEditingId(id)
        modalOpen(id ? 'editing' : 'adding')
        return
    }

    let removeOrg = async (id, confirmed= false) => {
        setRemovingId(id)
        if(!confirmed) {
            modalClose()
            setRemovingBtnColor('dark')
            modalOpen('removing')
            return
        }
        setRemovingBtnColor('warning')
        let {result, error} = await removeOrganization(removingId)
        if(error === 401) context.error_401();
        setRemovingBtnColor( result ? 'success' : 'danger')
        setTimeout(() => {
            setRemovingBtnColor('dark');
            setItems(result ? items.filter(el => el.id !== removingId) : items)
            modalClose()
        }, 500)
    }

    let loadData = async (ranges) => {
        let sl = sleep(300);
        let {result, error} = await loadOrganizations()
        if(error === 401) context.error_401();
        await sl

        result = result.map(el => {return {
            ...el,
            edit: cr && (cr.AdminRigths) ? <i className="fas fa-edit" onClick={() => {addOrEditOrg(el.id)}}/> : null,
            remove: cr && (cr.DilerRigths || cr.SuperUserRigths) ? <i className="fas fa-trash-alt" onClick={() => {removeOrg(el.id)}}/> : null
        }})
        setItems(result);
    };

    useEffect(() => {
        trackPromise(loadData());
        return () => {};
    }, [needRefresh]);

    if(context.login === "")
        return <Redirect to='/auth'/>

    let columns = [
        {name: t("orgList.org"), key: "name", floatC: true, filter: true, sort: "t"},
        {name: t("orgList.crDate"),key: "date", floatC: true, sort: "d"},
        {name: t("orgList.creator"), key: "creator", floatC: true, filter: true, sort: "t"},
        {name: t("orgList.amObj"),key: "amountObj", floatC: true, sort: "f"},
        {name: t("orgList.amUs"), key: "amountUsers", floatC: true, sort: "f"},
        ...[cr && (cr.AdminRigths) ? {name: "", key: "edit", floatC: true, floatCA: true, sort: "noSort", width: "2rem", noExport: true}: null],
        ...[cr && (cr.DilerRigths || cr.SuperUserRigths) ? {name: "", key: "remove", floatC: true, floatCA: true, sort: "noSort", width: "2rem", noExport: true}: null],
    ].filter(el => el)

    return (
            <div className="container-fluid pb-3">
                <div className="px-3">
                    {promiseInProgress ?
                        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}><CircularProgress color="inherit" /></Backdrop>
                        :
                        <div>

                            {
                                open ?
                                    modalType === 'removing' ?
                                        <RemoveModal isOpen={open}  handleClose={modalClose}
                                                     btnColor={removingBtnColor} btnF={() => {removeOrg(null, true)}}
                                        /> :
                                        <EditOrgModal isOpen={open} handleClose={modalClose} id={editingId} enqueueSnackbar={enqueueSnackbar} snackDef={snackDef} />
                                    : null
                            }

                            {
                                cr && (cr.DilerRigths || cr.SuperUserRigths) ?
                                    <div className="row py-2 m-0 mw-100">
                                        <div className="col-12 p-0">
                                            <button type="button" className={"btn btn-outline-" + addOrgBtnColor} onClick={() => {addOrEditOrg()}}>
                                                {t("orgList.addOrg")}&nbsp;&nbsp;<i className="fas fa-plus"/>
                                            </button>
                                        </div>
                                    </div>
                                    : null
                            }

                            <Table items={items} columns={columns} doNotExpand={true}/>
                        </div>
                    }
                </div>
            </div>
    )
}