import React, {useContext, useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import loadPackShop from "./requests/loadPackShop";
import requestToBuyPack from "./requests/requestToBuyPack";
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import {Backdrop, CircularProgress} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import AddPackModal from "./AddPackModal";
import ResultModal from "./ResultModal";
import {Context} from "../../Context";

export default function PackShop(){
    let [context, setContext] = useContext(Context);
    const [items, setItems] = useState([]);
    const { promiseInProgress } = usePromiseTracker();
    const { t, i18n } = useTranslation();
    let lk = "billing.PackShop."

    const [needRefresh, setNeedRefresh] = useState(Math.random());
    const [isOk, setIsOk] = useState(false);
    const [open, setOpen] = useState(false);
    const modalOpen = (type) => {setOpen(true)};
    const modalClose = (isSuccess = false) => {
        setOpen(false);
        if(isSuccess) {
            setNeedRefresh(Math.random())
        }
    };

    let loadData = async () => {
        let {result, error} = await loadPackShop()
        if(error === 401) context.error_401();
        result = result.map(el => ({...el, btnStatus: "dark"}))
        setItems(result);
    };

    let requestToBuy = async (id) => {
        if(items.reduce((s, el) => (s + (el.btnStatus !== "dark" ? 1 : 0)), 0) > 0)
            return
        setItems(items.map(el => (el.id === id ? {...el, btnStatus: "warning"} : el)))
        let {result, error} = await requestToBuyPack(id)
        if(error === 401) context.error_401();
        setItems(items.map(el => (el.id === id ? {...el, btnStatus: result ? "success" : "danger"} : el)))
        setTimeout(() => {
            setItems(items.map(el => (el.id === id ? {...el, btnStatus: "dark"} : el)))
            /*if(items.filter(el => (el.id === id))[0].pack_price === 0)
                setNeedRefresh(Math.random())*/
        }, 500)
        setIsOk(result)
        modalOpen()
    };

    useEffect(() => {
        trackPromise(loadData());
        return () => {};
    }, [needRefresh]);

    return  (
        <div className="py-2">
            {
                promiseInProgress ?
                    <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={true}><CircularProgress
                        color="inherit"/></Backdrop>
                    :
                    <div className="row m-0 mw-100">
                        {
                            items.length ?
                                items.map((card, i) =>
                                    <div className={"col-3 py-2 " + (i % 4 === 0 ? "ps-0" : "")} key={"key_" + i}>
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="py-2 fw-bold">{card.name.toUpperCase()}</div>
                                                {/*<h6 className="card-subtitle mb-2 text-muted"></h6>*/}
                                                <div className="py-2 card-text">{t(lk + "amObjs") + ": " + card.amObjs}</div>
                                                <div className="py-2 card-text">{t(lk + "price") + ": " + card.price}</div>
                                                <div className="pt-4">
                                                    <button type="button" className={"w-100 btn btn-" + card.btnStatus} key={"key_tab_btn_" + i}
                                                            onClick={(e) => {requestToBuy(card.id)}}
                                                    >
                                                        {t(lk + "req")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                :
                                <div className="my-3 py-1 text-center bg-dark rounded-3 text-white fw-bold">
                                    {t(lk + "noPacks")}
                                </div>
                        }
                        {open ? <ResultModal isOpen={open} isOk={isOk} handleClose={modalClose}/> : null}
                    </div>
            }
        </div>

    )
}