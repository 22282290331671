import {serverURL, corsHeaders, formatDate} from "../../../requests/constants";
const axios = require('axios');

export default async function loadReqConfirmation(){
    try {
        /*return Array(30).fill(undefined).map((el, i) => ({
            reqId: "" + i,
            status: "" + i % 4,
            clientName: "clientName_" + i,
            creationDate: formatDate("2021-10-05 21:13"),
            packName: "packname_" + i,
            pricePerM: "" + i * 100,
            amObjs: "" + i * 10,
            changesUserName: "ch user " + i,
            changesDate: formatDate("2021-10-05 21:13"),
        }))*/
        let response = await axios({
            method: 'get',
            url: serverURL + 'billing/requestList/',
            headers: {
                'accept': 'application/json',
                ...corsHeaders
            },
        });


        let result = response.data.map((el, i) => ({
            reqId: "" + el.requestsID,
            status: "" + el.status,
            clientName: "" + el.nameClient,
            creationDate: formatDate(el.requestTime_utc),
            packName: "" + el.PackName,
            pricePerM: "" + el.PackPrice,
            amObjs: "" + el.unitCount,
            changesUserName: "" + (el.reactionAuthName ? el.reactionAuthName : ""),
            changesDate: formatDate(el.timeReaction_utc),
            is_right: el.currency.is_right,
            symbol: el.currency.symbol,
            symbolPDF: el.currency.ISO_LAT3,
            inbox: el.inbox
        }))

        return {result: response.status === 200 ? result : [], error: false}
    } catch (error) {
        return {result: [], error: error.response ? error.response.status : 500}
    }
}