import {
    IMPORT_FROM_FILE_INIT,
    IMPORT_FROM_FILE_START,
    IMPORT_FROM_FILE_SUCCESS,
    IMPORT_FROM_FILE_ERROR
} from '../types/transactionTypes'
import axios from "axios";
import {corsHeaders, serverURL} from "../../requests/constants";

export let importFromFileInit = () => ({
    type: IMPORT_FROM_FILE_INIT
})

let importFromFileStart = () => ({
    type: IMPORT_FROM_FILE_START
})

let importFromFileInitSuccess = () => ({
    type: IMPORT_FROM_FILE_SUCCESS
})

let importFromFileInitError = (error) => ({
    type: IMPORT_FROM_FILE_ERROR,
    payload: {
        error: error
    }
})

export let importFromFile = (file, t) => async dispatch => {

    let lk = "data.importFileError."

    dispatch(importFromFileStart())

    axios({
        url: serverURL + 'transactions/loadFromFile/',
        method: "post" ,
        headers: {
            'accept': 'application/json',
            ...corsHeaders,
            'Content-Type': 'multipart/form-data',
        },
        data: file,
    })
        .then((res) => {
            res = res.data
            if(res.result)
                dispatch(importFromFileInitSuccess())
            else{
                dispatch(importFromFileInitError(t(lk + res.msg) + (res.errors && res.error.length ? t(lk + " Ошибка в поле ") + res.errors[0].field + t(lk + " на строке ") + res.errors[0].index + t(lk + res.errors[0].msg) : "")))
            }
        })
        .catch (err => {

            if(err.response && err.response.data && err.response.data.msg){
                let error = err.response.data
                let msg = error.errors && error.errors.length ?  "\n" + t(lk + "errorInField") + " " + error.errors[0].field + "\n" + t(lk + "onRow") + " " + error.errors[0].index + "\n" + t(lk + error.errors[0].msg) : ""
                dispatch(importFromFileInitError(t(lk + error.msg) + msg))
            }
            else
                dispatch(importFromFileInitError(t(lk + "unknownError")))
        });
}

