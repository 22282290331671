import {serverURL, corsHeaders} from "./constants";
const axios = require('axios');

export default async function changeLanguageOnServer(newLang){
    try {
        let response = await axios({
            method: 'put',
            url: serverURL + 'users/locale/',
            headers: {
                ...corsHeaders,
                'Content-Type': 'application/json', // for post arr in data
            },
            data: JSON.stringify({
                locale: newLang
            })
        });

        return {result: response.status === 200, error: response.status}
    } catch (error) {
        return {result: false, error: error.response ? error.response.status : 500}
    }
}