import {serverURL, corsHeaders} from "./constants"
const axios = require('axios');

export default async function getBalance(){
    try {
        let response = await axios({
            method: 'get',
            url: serverURL + 'billing/',
            headers: {
                'accept': 'application/json',
                ...corsHeaders
            },
        })

        if(response.status !== 200)
            return {result: false, error: response.status}

        return {result: response.status === 200 ? response.data : false, error: response.status}
    } catch (error) {
        return {result: false, error: error.response ? error.response.status : 500}
    }
}