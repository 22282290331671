import {serverURL, corsHeaders, formatDate} from "../../../requests/constants";
const axios = require('axios');

export default async function loadConnectedPacks(){
    try {

        let response = await axios({
            method: 'get',
            url: serverURL + 'billing/tariffs/connected/',
            headers: {
                'accept': 'application/json',
                ...corsHeaders
            },
        });

        let result = response.data.map(el => {
            return ({
                name: el.tariff_name,
                connDate: formatDate(el.connected_at),
                amObjs: "" + el.unit_count,
                amPacks: "" + el.count ,
                pricePerM: "" + el.tariff_price,
                fullPrice: "" + el.total_price,
                is_right: el.currency.is_right,
                symbol: el.currency.symbol,
                symbolPDF: el.currency.ISO_LAT3
            })
        })

        return {result: response.status === 200 ? result : [], error: false}
    } catch (error) {
        return {result: [], error: error.response ? error.response.status : 500}
    }
}