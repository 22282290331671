import React, {useContext, useRef, useState, useEffect} from "react";
import {Checkbox, FormControl, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import loadServiceTypes from "../requests/loadServiceTypes";
import Map from "./Map";
import moment from "moment";
import {useTranslation} from "react-i18next";
import editObject from "../requests/editObject";
import {Context} from "../Context";
import {useSnackbar} from "notistack";
import MakeInput from "./MakeInput";

export default function ExpandableRowObj(props) {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const snackDef = {
        variant: "warning",
        anchorOrigin: {vertical: 'bottom', horizontal: 'right'},
        autoHideDuration: null,
        action: key => <i className="fas fa-times pe-2" style={{cursor: "pointer"}} onClick={e => {
            closeSnackbar(key)
        }}/>
    }
    let {tankVolume, mileage, motoHours, fuelLvl, isVisible, lastLat, lastLon, lastConnectionTime, cards: startCards, time_interval} = props.el
    let defaultPaymentSys = {cNumb: "", pSys: 0}
    const [context, setContext] = useContext(Context);
    let cr = context.rights
    const [serviceTypes, setServiceTypes] = useState([]);
    const [saveBtnColor, setSaveBtnColor] = useState('dark');
    const [card, setCard] = useState({
        tank: tankVolume,
        road: mileage,
        clock: motoHours,
        tint: fuelLvl,
        eye: isVisible,
        time: time_interval,
        paymentSystems: [
            ...startCards.map(el => ({cNumb: "" + el.card_number, pSys: "" + el.type_system_id})),
            {...defaultPaymentSys}
        ],
    });

    const {t, i18n} = useTranslation();
    let loadST = async () => {
        let {result: st, error} = await loadServiceTypes(true)
        if (error === 401) context.error_401();
        st = st.map(el => ({...el, id: "" + el.id}))

        setServiceTypes(st);
        //setCard({...card, bell: el.paymentSystemId})
    };

    useEffect(() => {
        loadST();
        return () => {
        };
    }, []);


    let mapW = parseInt(window.innerWidth * 0.39)
    let mapH = parseInt(window.innerHeight * 0.32)

    let textStyle = {
        input: {style: {fontSize: 20}},
        label: {style: {backgroundColor: "white !important", fontSize: 15}}
    }

    let save = async () => {
        if (saveBtnColor !== 'dark')
            return;

        if (card.paymentSystems.filter(el => ((el.cNumb.length && el.pSys === 0) || (!el.cNumb.length && el.pSys !== 0))).length) {
            enqueueSnackbar(t("objectsTable.notAllRequiredFieldsFilled"), {
                ...snackDef,
                variant: "error",
                autoHideDuration: 5000
            });
            return;
        }
        setSaveBtnColor('warning')
        let {result, error} = await editObject(props.el.id, {...card, paymentSystems: card.paymentSystems.filter(el => (el.cNumb.length && el.pSys !== 0))})
        if (error === 401) context.error_401();
        if (error.detail)
            enqueueSnackbar(t("objectsTable.saveError"), {
                ...snackDef,
                variant: "error",
                autoHideDuration: 5000
            });
        setSaveBtnColor(result ? 'success' : 'danger')
        setTimeout(() => {
            setSaveBtnColor('dark')
        }, 800)
        if (result)
            props.el._funct_makeReload()
    }

    let objSettings = [
        {label: t("expandObj.tankVolume"), sign: "fas fa-window-maximize", mykey: "tank"},
        {label: t("expandObj.time_interval"),    sign: "fa fa-clock-o",            mykey: "time"},
        {label: t("expandObj.mileage"),    sign: "fas fa-road",            mykey: "road",  readOnly: true},
        {label: t("expandObj.motoHours"),  sign: "fas fa-clock",           mykey: "clock", readOnly: true},
        {label: t("expandObj.fuelLvl"),    sign: "fas fa-tint",            mykey: "tint",  readOnly: true},
    ]

    let makeInputLine = (sign, value, label, mykey, disabled, onChange, type) => (
        <div className="row w-100 m-0">
            <div className="col-3 d-flex justify-content-center align-items-center"><i className={"fs-3 " + sign}/></div>
            <div className="col-9"><MakeInput value={value} label={label} mykey={mykey} disabled={disabled} onChange={onChange} type={type}/></div>
        </div>
    )

    return <div className="row w-100 m-0">
        <div className="d-inline p-0" style={{width: mapW, height: mapH}}>
            <div className="text-center rounded-3" style={{overflow: "hidden"}}>
                <Map points={[{
                    lat: lastLat,
                    lon: lastLon,
                    markerText: t("expandObj.lastConn") + moment.unix(lastConnectionTime).format("DD/MM/YYYY"),
                    isMarker: true
                }]}
                width={mapW + "px"} height={mapH + "px"}/>
            </div>
        </div>
        <div className="d-inline p-0 ps-4 " style={{width: "calc(100% - 41vw)"}}>
            <div className="card px-0">
                <div className="row w-100 m-0 pb-2">
                    <div className="col-12 px-0 py-2 bg-dark text-white text-center fw-bold"
                        style={{fontSize: "0.9rem !important", borderRadius: "0.3rem 0.3rem 0 0"}}>
                        {t("expandObj.settings")}
                    </div>

                    {
                        objSettings.map((el, i) => (
                            <div className="col-6 py-2" key={el.mykey}>
                                {makeInputLine(el.sign, card[el.mykey], el.label, "objSettings" + i,
                                    el.readOnly || !(cr.AdminRigths || cr.EditPropertyRights),
                                    e => {
                                        setCard(prev => ({...prev, [el.mykey]: e.target.value}))
                                    }, el.mykey === 'time' ? 'number' : ''
                                )}
                            </div>
                        ))
                    }
                    <div className="col-6 py-2" key={"eye"}>
                        <div className="row w-100 m-0">
                            <div className="col-3 d-flex justify-content-center align-items-center"><i
                                className={"fs-3 far fa-eye"}/></div>
                            <div className="col-9">
                                <Checkbox
                                    checked={card.eye}
                                    disabled={!(cr.AdminRigths || cr.EditPropertyRights)}
                                    sx={{color: "red", '&.Mui-checked': {color: "green"}}}
                                    key={"key_eye"}
                                    onClick={(e) => {
                                        setCard(prev => ({...prev, eye: e.target.checked}))
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row w-100 m-0 pb-2">
                    {
                        card.paymentSystems.map((ps, i) => (
                            <>
                                <div className="col-6 py-2" key={"key_ps_" + i}>
                                    {makeInputLine("far fa-credit-card", ps.cNumb, t("expandObj.cardNumber"), "paymentSystems_cn" + i,
                                        !(cr.AdminRigths || cr.EditPropertyRights),
                                        e => {
                                            setCard(prev => ({
                                                ...prev, paymentSystems: prev.paymentSystems.map((el, j) =>
                                                    (j === i ? {...el, cNumb: e.target.value} : el)
                                                )
                                            }))
                                            setCard(prev => (prev.paymentSystems.filter(el => el.cNumb.length === 0).length ? prev : {...prev, paymentSystems: [...prev.paymentSystems, defaultPaymentSys]}))
                                        }
                                    )}
                                </div>

                                <div className="col-6 py-2" key={"key_" + i}>
                                    <div className="row w-100 m-0">
                                        <div className="col-3 d-flex justify-content-center align-items-center"><i
                                            className={"fs-3 far fa-eye"}/></div>
                                        <div className="col-9">
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel>{t("expandObj.paymentSystem")}</InputLabel>
                                                <Select
                                                    value={ps.pSys}
                                                    label={t("expandObj.paymentSystem")}
                                                    onChange={e => {
                                                        setCard(prev => ({
                                                            ...prev, paymentSystems: prev.paymentSystems.map((el, j) =>
                                                                (j === i ? {...el, pSys: parseInt(e.target.value)} : el)
                                                            )
                                                        }))
                                                    }}
                                                    key={"paymentSystems_ps" + i}
                                                    disabled={!(cr.AdminRigths || cr.EditPropertyRights)}
                                                >
                                                    <MenuItem value={"0"} key={"mi_def"}>&nbsp;</MenuItem>
                                                    {serviceTypes.map((st, sti) => <MenuItem value={st.id} key={"mi" + sti}>{st.name}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))
                    }

                    {
                        cr.AdminRigths || cr.EditPropertyRights ?
                            <div className="col-12 py-2 text-end" key={"key" + Math.random()}>
                                <button type="button" className={"btn btn-outline-" + saveBtnColor}
                                        onClick={save}>{t("expandObj.save")}&nbsp;&nbsp;<i className="fas fa-save"/>
                                </button>
                            </div>
                            : null
                    }

                </div>
            </div>
        </div>
    </div>
}