import React, {useContext, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {Autocomplete, Backdrop, Checkbox, CircularProgress, TextField} from "@material-ui/core";
import MakeInput from "./MakeInput";
import "./EditOrgModal.css"
import {useTranslation} from "react-i18next";
import {usePromiseTracker} from "react-promise-tracker";
import loadOrgCard from "../requests/loadOrgCard";
import addOrganization from "../requests/addOrganization";
import saveOrganization from "../requests/saveOrganization";
import {Context} from "../Context";
import loadCurr from "./billing/requests/loadCurr";
import wCheckLoad from "../requests/wCheckLoad";
import { useSelector } from "react-redux";

export default function EditOrgModal(props){

    const [companyBtns, setCompanyBtns] = useState({})
    const [companyName, setCompanyName] = useState('')
    const state = useSelector(state => state)
    useEffect(()=>{
        if (state.app.detail) {
            setCompanyBtns(state.app.detail)
            setCompanyName(state.app.detail.service_name || 'Wialon')
        }
    }, [state])

    const { t, i18n } = useTranslation();
    const { promiseInProgress } = usePromiseTracker();

    const [context, setContext] = useContext(Context);
    const [curCur, setCurCur] = useState(0);
    const [currList, setCurrList] = useState([]);
    const W_CHECK_STATUSES = {
        default: {color: "dark", value: 'default', text:  t("modalEditOrg.wCheck.default"), iconClass: "fas fa-paper-plane"},
        success: {color: "success", value: 'success', text:  t("modalEditOrg.wCheck.success"), iconClass: "fas fa-check"},
        error: {color: "danger", value: 'error', text:  t("modalEditOrg.wCheck.error"), iconClass: "fas fa-times"},
        waiting: {color: "warning", value: 'waiting', text:  t("modalEditOrg.wCheck.waiting")},
    }
    const [wCheckStatus, setWCheckStatus] = useState(W_CHECK_STATUSES.default);

    const wCheck = async () => {
        if(wCheckStatus.value === W_CHECK_STATUSES.waiting.value)
            return;

        setWCheckStatus(W_CHECK_STATUSES.waiting)
        let {result, error} = await wCheckLoad(card.wHost, card.wToken)
        if(error === 401) context.error_401();
        if(error)
            setWCheckStatus(W_CHECK_STATUSES.error)
        else
            setWCheckStatus(W_CHECK_STATUSES.success)
        console.log("wcheckresult: ", result)
    }

    let loadSpecialModal = async () => {
        let {result: currency, error: loadCurrErr} = await loadCurr(true)
        if(loadCurrErr === 401) context.error_401();
        setCurrList(currency);
        if(currency.length)
            setCurCur(currency[0])

        let {result, error} = await loadOrgCard(props.id)
        if(error === 401) context.error_401();
        let resultCur = currency.filter(el => (el.id === '' + result.currency_id))
        if(resultCur.length)
            setCurCur(resultCur[0])
        result = {...result,
            services: result.services.map(el => ({...el, isInBase: el.active})),
            headUser: {
                login: "",
                password: "",
                passConfirm: "",
                name: "",
            },
            domains: result.domains.reduce((s, el, i) => (s + el + (result.domains.length - 1 !== i ? ", " : "")), "")
        }
        setCard(result)
    };

    useEffect(() => {
        loadSpecialModal();
        return () => {};
    }, []);

    let id = props.id
    const [card, setCard] = useState(null);
    const [saveBtnColor, setSaveBtnColor] = useState('dark');

    let btns = [
        {label: t("modalEditOrg.rHost"), value: "https://hst-api.wialon.ru", key: 'rus_w'},
        {label: t("modalEditOrg.eHost"), value: "https://hst-api.wialon.com", key: 'eu_w'},
        {label: t("modalEditOrg.aHost"), value: "https://hst-api.wialon.com", key: 'eng_w'}, ,
    ]

    const modalStyle = {position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "75vw", maxHeight: "95vh", minHeight: "35vh", //overflow: "auto",
        bgcolor: '#eeeeee', border: '2px solid grey', borderRadius: "1rem", boxShadow: 24, p: 2
    };

    let save = async () => {
        if(saveBtnColor !== 'dark')
            return;
        setSaveBtnColor('warning')

        if(props.id === null && (!card.headUser || !card.headUser.password || card.headUser.password && card.headUser.password.length < 5 || !card.headUser.passConfirm || card.headUser.passConfirm && card.headUser.passConfirm.length < 5)) {
            props.enqueueSnackbar(t("modalEditOrg.passMinFiveChar"), {...props.snackDef, variant: "error", autoHideDuration: 5000});
            setSaveBtnColor('danger')
            setTimeout(() => {setSaveBtnColor('dark')}, 500)
        } else if(card.wToken.length !== 0 && card.wToken.length !== 72){
            props.enqueueSnackbar(t("modalEditOrg.wrongTokenLength"), {...props.snackDef, variant: "error", autoHideDuration: 5000});
            setSaveBtnColor('danger')
            setTimeout(() => {setSaveBtnColor('dark')}, 500)
        }  else if(!card.wToken.length && card.wHost.length){
            props.enqueueSnackbar(t("modalEditOrg.emptyHost").replace('tms', companyName), {...props.snackDef, variant: "error", autoHideDuration: 5000});
            setSaveBtnColor('danger')
            setTimeout(() => {setSaveBtnColor('dark')}, 500)
        } else {
            let cardWithCurr = {...card, currency_id: curCur.id}
            /*if(!cardWithCurr.wHost.length && !cardWithCurr.wToken.length){
                cardWithCurr = {...cardWithCurr, wHost: "t".repeat(10), wToken: "t".repeat(72)}
            }*/
            let {result, error} = props.id ? await saveOrganization(id, cardWithCurr) : await addOrganization(cardWithCurr)
            if(error === 401) context.error_401();
            if(result)
                setSaveBtnColor('success')
            else {
                props.enqueueSnackbar(t("modalEditOrg.paramsError"), {...props.snackDef, variant: "error", autoHideDuration: 5000});
                setSaveBtnColor('danger')
            }setTimeout(() => {
                setSaveBtnColor('dark')
                if(result)
                    props.handleClose(true)
            }, 500)
        }
    }

    let makeCard = (inner, ii, overflow = "auto") => {return (
        <div className={"card my-2 py-2"} style={{maxHeight: "75vh", overflow: overflow}} key={"card_" + ii}>
            <div className="row m-0 mw-100 pb-2">
                {inner}
            </div>
        </div>
    )}

    let MakeBtnsForBlock = (props) => props.btns.map((el, i) => {
        return !companyBtns[el.key] ? <div className="d-inline ps-2" key={"key_btn_line_" + i}>
            <button type="button" className="btn btn-outline-dark" onClick={() => {setCard({...card, wHost : el.value})}}>{el.label}
            </button>
            </div> : null
        })

    //console.log(card)
    let MakeInputLine = (props) =>  (
        <div className={"col-" + props.col + " px-3 pb-0 text-start py-2"} key={"key_inp_line_" + props.mykey}>
            {
                props.serviceId ?
                    <MakeInput
                        value={props.value}
                        label={props.label}
                        note={props.note}
                        mykey={props.mykey}
                        hide={props.hide}
                        doSplit={props.doSplit}
                        multiline={props.multiline}
                        maxRows={props.maxRows}
                        onClick={(e) => {
                            if(card.services.filter(cs => (cs.serviceId === props.serviceId))[0].isInBase)
                                setCard({
                                    ...card,
                                    services: card.services.map(cs => (cs.serviceId === props.serviceId ?
                                        {
                                            ...cs,
                                            isInBase: false/*, fields: cs.fields.map(f => (f.key === props.mykey ? {...f, value: ""}: f))*/
                                        }
                                        :
                                        cs))
                                })
                        }}
                        onBlur={(e) => {
                            setCard({
                                ...card,
                                services: card.services.map(cs => (cs.serviceId === props.serviceId ?
                                    {
                                        ...cs,
                                        fields: cs.fields.map(f => (f.key === props.mykey ?
                                            {
                                                ...f,
                                                value: e.target.value
                                            }
                                            :
                                            f))
                                    }
                                    :
                                    cs))
                            })
                        }}
                    />
                    :
                    props.subKey === "headUser" ?
                        <MakeInput value={props.value} label={props.label} mykey={props.mykey} error={props.mykey.indexOf("pass") !== -1 && card.headUser.password !== card.headUser.passConfirm} onBlur={(e)=>{setCard({...card, headUser: {...card.headUser, [props.mykey]: e.target.value}})}}/>
                        :
                        <MakeInput value={props.value} label={props.label} mykey={props.mykey} disabled={props.disabled} onBlur={(e)=>{setCard({...card, [props.mykey]: e.target.value})}}/>
            }
        </div>
    )

    return (
        <Modal open={props.isOpen}
               onClose={(e, reason) => {if (reason !== 'backdropClick') {props.handleClose()}}}
        >
            <Box sx={modalStyle}>
                {promiseInProgress || !card ?
                    <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                              open={true}><CircularProgress color="inherit"/></Backdrop>
                    :
                    <div>
                        <div className="text-center py-2 mb-2 rounded-3 bg-dark text-white fw-bold">
                            {(props.id ? t("modalEditOrg.edit") : t("modalEditOrg.adding")) + " " + t("modalEditOrg.org")}
                        </div>
                        <div className="row m-0 mw-100">

                            <div className="col-6 p-0">
                                <div className="row m-0 mw-100">
                                    <div className="text-start rounded-3">

                                        {makeCard( // Organization Name
                                            <>
                                                <div className={"col-12 px-3 text-start py-2"}>
                                                    <i className="fas fa-building fw-bold"/>&nbsp;&nbsp;{t("modalEditOrg.orgName")}
                                                </div>

                                                <div className="col-8 px-3 pt-3">
                                                    <MakeInput value={card.orgName} label={""} note="" mykey={"orgName"} onBlur={(e)=>{setCard({...card, ["orgName"]: e.target.value})}}/>
                                                </div>

                                                <div className="col-4 px-3">
                                                    <Autocomplete
                                                        onChange={(e, nv) => {
                                                            setCurCur(nv)
                                                            console.log(nv)
                                                        }}
                                                        value={curCur}
                                                        options={currList}
                                                        getOptionLabel={el => el ? el.name : ""}
                                                        loading={currList.length === 0}
                                                        loadingText={t("billing.AddPackModal.loadingText")}
                                                        noOptionsText={t("billing.AddPackModal.noOptionsText")}
                                                        sx={{width: "100%", pt: "1rem"}}
                                                        renderInput={(params) => <TextField {...params} label="" value="" variant="standard" /*error={saveBtnColor === 'danger'}*//>}
                                                        key={"AutocompleteKey"}
                                                        disabled={props.id !== null || !context.rights.SuperUserRigths}
                                                    />
                                                </div>

                                                {
                                                    card.domains !== "" ?
                                                        <div className="col-12 px-3 pt-3">
                                                            <MakeInput value={card.domains} label={t("modalEditOrg.personalDNS")} note="" mykey={"domains"} disabled/>
                                                        </div>
                                                        : ""
                                                }

                                            </>
                                            , 1, "none")}

                                        {makeCard( // Wialon Settings
                                            <>
                                                <div className={"col-4 ps-3 pe-0 text-start py-2"}>
                                                    <i className="fas fa-server fw-bold"/>&nbsp;&nbsp;{t("modalEditOrg.wSettings").replace('tms',`${companyName ? companyName : 'Wialon'}`)}
                                                </div>
                                                <div className={"text-end col-8 py-2 ps-0"}>
                                                    <MakeBtnsForBlock btns={btns}/>
                                                </div>
                                                <MakeInputLine col={"4"} value={card.wHost} label={t("modalEditOrg.wHost").replace('tms',`${companyName ? companyName : 'Wialon'}`)} mykey="wHost"/>
                                                <MakeInputLine col={"4"} value={card.wToken} label={t("modalEditOrg.wToken").replace('tms',`${companyName ? companyName : 'Wialon'}`)} mykey="wToken"/>
                                                <div className={"col-4 px-3 pb-0 text-start py-2 d-flex justify-content-center align-items-center"}>
                                                    <div
                                                        className={"btn btn-outline-" + wCheckStatus.color + " d-flex justify-content-center align-items-center"}
                                                        onClick={wCheck}
                                                    >
                                                        {
                                                            wCheckStatus.iconClass &&
                                                            <i className={wCheckStatus.iconClass + " pe-2"}/>
                                                        }
                                                        {wCheckStatus.text}
                                                    </div>
                                                </div>
                                            </>
                                        ,2)}

                                        {!id ?
                                            makeCard( // Head User
                                                <>
                                                    <div className={"col-12 px-3 text-start py-2"}>
                                                        <i className="fas fa-user-alt fw-bold"/>&nbsp;&nbsp;{t("modalEditOrg.headUser")}
                                                    </div>
                                                    <MakeInputLine col={"6"} value={card.headUser.name} label={t("modalAddUser.user")} subKey="headUser" mykey="name"/>
                                                    <MakeInputLine col={"6"} value={card.headUser.login} label={t("modalAddUser.login")} subKey="headUser" mykey="login"/>
                                                    <MakeInputLine col={"6"} value={card.headUser.password} label={t("modalAddUser.pass")} subKey="headUser" mykey="password"/>
                                                    <MakeInputLine col={"6"} value={card.headUser.passConfirm} label={t("modalAddUser.confirm")} subKey="headUser" mykey="passConfirm"/>
                                                </>
                                            ,3)
                                            :
                                            makeCard( // Head User
                                                <>
                                                    <div className={"col-12 px-3 text-start py-2"}>
                                                        <i className="fas fa-user-alt fw-bold"/>&nbsp;&nbsp;{t("modalEditOrg.headUser")}
                                                    </div>
                                                    <MakeInputLine col={"6"} value={card.head_user.name} label={t("modalAddUser.user")} subKey="head_user" mykey="name" disabled/>
                                                    <MakeInputLine col={"6"} value={card.head_user.login} label={t("modalAddUser.login")} subKey="head_user" mykey="login" disabled/>
                                                </>
                                                ,3)
                                        }

                                    </div>
                                </div>
                            </div>

                            <div className="col-6 p-0">
                                <div className="text-start rounded-3">
                                    {makeCard( // Services
                                        <>
                                            <div className={"col-12 px-3 text-start py-2"}>
                                                <i className="fas fa-credit-card fw-bold"/>&nbsp;&nbsp;{t("modalEditOrg.trSys")}
                                            </div>
                                            {
                                                card.services.map((s, i) => (
                                                    <div className={"col-12 px-3 text-start py-2"} key={"key_card_service_" + i}>
                                                        <div className="row m-0 mw-100 border rounded-3 pb-2">
                                                            <div className={"col-12 px-3 text-start pt-2"}>
                                                                <Checkbox className="p-0" checked={s.active} sx={{color: "#212529", '&.Mui-checked': {color: "#212529"}}} onClick={()=>{setCard({...card, services: card.services.map(cs => (cs.serviceId === s.serviceId ? {...cs, active: !cs.active}: cs))})}}/>
                                                                &nbsp;&nbsp;{s.serviceName}
                                                            </div>
                                                            {
                                                                s.active?
                                                                    <>
                                                                        {s.fields.map((f, i) => (
                                                                            <MakeInputLine
                                                                                col={f.col ? "" + f.col : (s.fields.length === 1 ? "12" : "6")}
                                                                                value={f.value}
                                                                                hide={s.isInBase && f.hideValue}
                                                                                label={t("modalEditOrg." + f.key)}
                                                                                note={f.note !== "" ? t("modalEditOrg." + f.note) : ""}
                                                                                serviceId={s.serviceId}
                                                                                mykey={f.key}
                                                                                doSplit={f.doSplit}
                                                                                key={"key_service_field_" + f.key}
                                                                                multiline={f.multiline}
                                                                                maxRows={f.maxRows}
                                                                            />
                                                                        ))}
                                                                    </>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </>
                                    , 4)}
                                </div>
                            </div>

                            <div className="col-12 p-0">
                                <div className="text-end pt-2">
                                    <button type="button" className={"me-2 btn btn-outline-dark"} onClick={() => {props.handleClose()}}>
                                        {t("modalEditOrg.cancel")}&nbsp;&nbsp;<i className="fas fa-window-close"/>
                                    </button>

                                    <button type="button" className={"btn btn-outline-" + saveBtnColor} onClick={() => save()}>
                                        {t("modalEditOrg.save")}&nbsp;&nbsp;<i className="fas fa-save"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </Box>
        </Modal>
    )
}