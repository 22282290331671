import {serverURL, corsHeaders} from "../../../requests/constants";
import {formatBalance} from "../../../functions";
const axios = require('axios');

export default async function loadConnectedPacks(){
    try {
        /*return Array(6).fill(undefined).map((el, i) => ({
            id: i,
            name: "name_" + i,
            amObjs: "" + i * 10,
            price: "" + i*100,
        }))*/
        let response = await axios({
            method: 'get',
            url: serverURL + 'billing/tariffs/',
            headers: {
                'accept': 'application/json',
                ...corsHeaders
            },
        });

        let result = response.data.map((el, i) => ({
            id: el.pack_id,
            name: el.pack_name,
            amObjs: "" + el.unit_count,
            price: "" + (el.currency.is_right ? "" : " " + el.currency.symbol) + formatBalance(el.pack_price, 0) + (el.currency.is_right ? " " + el.currency.symbol : ""),
            pricePDF: "" + (el.currency.is_right ? "" : " " + el.currency.ISO_LAT3) + el.pack_price + (el.currency.is_right ? " " + el.currency.ISO_LAT3 : ""),
            pack_price: el.pack_price
        }))

        return {result: response.status === 200 ? result : [], error: false}
    } catch (error) {
        return {result: [], error: error.response ? error.response.status : 500}
    }
}