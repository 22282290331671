import {corsHeaders, serverURL} from "./constants";

const axios = require('axios');

export default async function wCheckLoad(wHost, wToken){
    try {
        let response = await axios({
            method: 'get',
            url: serverURL + 'users/wialoncheck/?host=' + wHost + "&token=" + wToken,
            headers: {
                //'accept': 'application/json',
                ...corsHeaders,
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                host: wHost,
                token: wToken,
            })
        });

        let result = response.data
        return {result: response.status === 200 ? result : [], error: false}
    } catch (error) {
        return {result: [], error: error.response ? error.response.status : 500}
    }
}