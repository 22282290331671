import {serverURL, corsHeaders} from "../../../requests/constants";
const axios = require('axios');

export default async function addPack(card){
    try {
        let response = await axios({
            method: 'post',
            url: serverURL + 'billing/tariffs/',
            headers: {
                ...corsHeaders,
                'Content-Type': 'application/json', // for post arr in data
            },
            data: {
                "name": card.packName,
                "price": card.pricePerM,
                "count": card.amObjs,
                "currency_id": card.curCurId,
                "dealer_pack": card.forDealerOnly,
            }
        });

        return {result: response.status === 200, error: false}
    } catch (error) {
        return {result: false, error: error.response ? error.response.status : 500}
    }
}