import React, {useContext, useEffect, useRef, useState} from "react";
import {Context} from "../Context";
import "./FilterType.css"
import {Autocomplete, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";

export default function FilterDropdownList(props){
    const [context, setContext] = useContext(Context);
    const [cv, setCv] = useState([]);
    const { t, i18n } = useTranslation();
    let contextKey = props.contextKey
    let contextSubKey = props.contextSubKey

    return (<div>
            <Autocomplete
                multiple
                options={props.items.filter(el => cv.reduce((s, c) => s + (c.value === el.value ? 1 : 0), 0) === 0)}
                getOptionLabel={(option) => option.title}
                loading={props.items.length === 0}
                loadingText={t("filterDropdownList.loadingText") + "..."}
                noOptionsText={t("filterDropdownList.noOptionsText")}
                fullWidth={false}
                onChange={(event, value) => {
                    setCv(value);
                    let byf1 = contextSubKey ? {
                        [contextSubKey]: {...context[contextSubKey], [contextKey]: value}
                    } : {
                        [contextKey]: value
                    }

                    setContext({...context, ...byf1})
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        //label={t("filterDropdownList.label")}
                        placeholder={"  " + (cv.length ? "" : t("filterDropdownList.label"))}
                    />
                )}
                style={{minWidth: "20vw" ,width: "fit-content",}}
            />
        </div>
    )
}