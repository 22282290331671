import React, {useContext, useRef, useState} from "react";
import {Context} from "../Context";
import "./FilterType.css"
import {useTranslation} from "react-i18next";
import {Button, ButtonGroup} from "@material-ui/core";

export default function StatusFilter(props){
    /*
        setArr - callback to change isChecked
        arr - array with data formatted [{
            color: "#454545",
            isChecked: true,
            text: "hiii"
        }]

        --- example ---
        let statusColors = ["#ff9000", "#32aa48", "#ff6347", "#5c5c5c"]
        let statusTexts = [t(lk + "waiting"), t(lk + "success"), t(lk + "error"), t(lk + "notAllowed")]
        const [filterArr, setFilterArr] = useState(Array(statusColors.length).fill(true))

        <StatusFilter
            setArr={setFilterArr}
            arr={Array(statusColors.length).fill(undefined).map((el, i) => ({
                color: statusColors[i],
                text: statusTexts[i],
                isChecked: filterArr[i]
            }))}
        />

     */

    const { t, i18n } = useTranslation();
    let arr = props.arr
    let setArr = props.setArr

    return (
        <ButtonGroup disableElevation>
            {arr.map((el, i) => <Button variant='contained' sx={{
                background: el.color,
                opacity: el.isChecked ? 1 : 0.3,
                borderColor: el.isChecked,
                color: "white",
                '&:hover': {
                    opacity: 0.5,
                    background: el.color,
                    color: "white",
                },
            }} onClick={() => {setArr(arr.map((el2, j) => (i !== j ? el2.isChecked : !el2.isChecked)))}} key={"key_btn_" + i}>{el.text}</Button>)}
        </ButtonGroup>
    )
}