import React, {useContext, useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import {Backdrop, CircularProgress} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {Context} from "../../Context";
import {Redirect} from "react-router-dom";
import AddPackModal from "./AddPackModal";
import Table from "../Table";
import loadClientList from "./requests/loadClientList";

export default function ClientsList(props){
    const [context, setContext] = useContext(Context);
    const [items, setItems] = useState([]);
    const [needRefresh, setNeedRefresh] = useState(Math.random());
    const { promiseInProgress } = usePromiseTracker();

    const { t, i18n } = useTranslation();
    let lk = "billing.ClientsList."

    const [open, setOpen] = React.useState(false);
    const modalOpen = (type) => {setOpen(true)};
    const modalClose = (isSuccess = false) => {
        setOpen(false);
        if(isSuccess) {
            props.refreshParent(Math.random());
            setNeedRefresh(Math.random())
        }
    };

    let addPack = async () => {
        modalClose()
        modalOpen('adding')
        return
    }

    let loadData = async () => {
        let {result, error} = await loadClientList(t(lk + "clientAvailable"), t(lk + "clientFullPrice"), t(lk + "objInUse"))
        if(error === 401) context.error_401();
        let addCurrencySign = (is_r, s, v) => ("" + (is_r ? "" : " " + s) + v + (is_r ? " " + s : ""))
        result = result.map(r => ({
            ...r,
            login: r.login + " | " + (t(lk + "objInUse")) + r.objInUse + " | " + (t(lk + "clientAvailable")) + ": " + r.clientAvailable + " | " + (t(lk + "clientFullPrice")) + ": " + addCurrencySign(r.is_right, r.symbol ,r.clientFullPrice),
            loginPDF: r.login + " | " + (t(lk + "objInUse")) + r.objInUse + " | " + (t(lk + "clientAvailable")) + ": " + r.clientAvailable + " | " + (t(lk + "clientFullPrice")) + ": " + addCurrencySign(r.is_right, r.symbolPDF ,r.clientFullPrice),
            substrs: r.substrs.map(s => ({
                ...s,
                pricePerM: addCurrencySign(r.is_right, r.symbol ,s.pricePerM),
                pricePerMPDF: addCurrencySign(r.is_right, r.symbolPDF, s.pricePerM),
            }))
        }))
        setItems(result);
    };

    useEffect(() => {
        trackPromise(loadData());
        return () => {};
    }, [needRefresh]);

    if(context.login === "")
        return <Redirect to='/auth'/>

    let columns = [
        {name: t(lk + "name"),      key: "name",        filter: true},
        {name: t(lk + "connDate"),  key: "connDate"},
        {name: t(lk + "amPacks"),   key: "amPacks"},
        {name: t(lk + "amObjs"),    key: "amObjs"},
        {name: t(lk + "pricePerM"), key: "pricePerM", exportKey: "pricePerMPDF"},
    ].map(el => {return {...el, floatC: true, floatCA: true, sort: "noSort"}})

    return (
        <div >
            <div className="container-fluid pb-3">
                {promiseInProgress ?
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}><CircularProgress color="inherit" /></Backdrop>
                    :
                    <div>
                        {open ? <AddPackModal isOpen={open}  handleClose={modalClose}/> : null}


                        <div className="row pt-2 m-0 mw-100">
                            <div className="col-12 p-0">
                                <button type="button" className="btn btn-outline-dark" onClick={() => {addPack()}}>
                                    {t(lk + "createPack")}&nbsp;&nbsp;<i className="fas fa-plus"/>
                                </button>
                            </div>
                        </div>

                        <Table items={items} columns={columns} grouped={true} doNotExpand={true}/>
                    </div>
                }
            </div>
        </div>
    )
}