import {serverURL, corsHeaders} from "./constants";
const axios = require('axios');

export default async function loadServiceTypes(active = false){

    try {
        let response = await axios({
            method: 'get',
            url: serverURL + 'services/' + (active ? "?active=true" : ""),
            headers: {
                'accept': 'application/json',
                ...corsHeaders
            },
        });

        if(response.status !== 200)
            return {result: [], error: response.status}

        return {result: response.data, error: response.status}
    } catch (error) {
        return {result: [], error: error.response ? error.response.status : 500}
    }
}