import {serverURL, corsHeaders} from "./constants";
const axios = require('axios');

export default async function loadLogos(host){

    try {
        let response = await axios({
            method: 'get',
            url: serverURL + 'dns/?domain=' + host,
            headers: {
                'accept': 'application/json',
                ...corsHeaders
            },
        });


        if(response.status !== 200)
            return false

        let navbarLogo = response.data.logos.reduce((s, el) => (el.type === "navbar" ? "data:image/jpeg;base64," + el.img : s), null)
        let pdfLogo = response.data.logos.reduce((s, el) => (el.type === "report" ? el.img : s), null)
        let authLogo = response.data.logos.reduce((s, el) => (el.type === "auth" ? "data:image/jpeg;base64," + el.img : s), null)
        let favicon = response.data.logos.reduce((s, el) => (el.type === "favicon" ? el.img : s), null)
        let serviceImg = response.data.logos.reduce((s, el) => (el.type === "service_img" ? "data:image/png;base64," + el.img : s), null)
        let title = response.data.title
        let detail = response.data.detail

        return {navbarLogo, pdfLogo, authLogo, favicon, title, detail, serviceImg}
    } catch (error) {
        return false
    }
}