import React, {useContext, useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import loadReqConfirmation from "./requests/loadReqConfirmation";
import confirmReq from "./requests/confirmReq";
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import {Backdrop, CircularProgress, Tooltip} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Table from "../Table";
import StatusFilter from "../StatusFilter";
import {Context} from "../../Context";
import getBalance from "../../requests/getBalance";

export default function ReqConfirmation(){
    const [context, setContext] = useContext(Context);
    const [items, setItems] = useState([]);
    const { promiseInProgress } = usePromiseTracker();
    const { t, i18n } = useTranslation();
    let lk = "billing.ReqConfirmation."
    let statusColors = ["#ff9000", "#32aa48", "#ff6347", "#5c5c5c"]
    let statusTexts = [t(lk + "waiting"), t(lk + "success"), t(lk + "error"), t(lk + "notAllowed")]
    const [filterArr, setFilterArr] = useState(Array(statusColors.length).fill(true))
    let cr = context.rights

    let getStatusCrumbs = (el, newStatus = "") => {
        el.status = newStatus.length ? newStatus : el.status;
        return {
            statusColor: statusColors[parseInt(el.status) - 1],
            btnAccept: !el.inbox || el.status === "4" || el.status === "3" || el.status === "2" ? "" : <Tooltip title={t(lk + "tooltipAccept")}><span><i className="far fa-check-circle text-success fs-5" onClick={() => {requestToConfirm(el.reqId, "2")}}/></span></Tooltip>,
            btnReject: !el.inbox || el.status === "3" || el.status === "2" ? "" : <Tooltip title={t(lk + "tooltipReject")}><span><i className="far fa-times-circle text-danger fs-5" onClick={() => {requestToConfirm(el.reqId, "3")}}/></span></Tooltip>,
            inboxSign: <Tooltip title={t(lk + "tooltip" + (el.inbox ? "InComing" : "OutComing"))}><span><i className={"fs-5 fas fa-" + (el.inbox ? "inbox" : "sign-out-alt")}/></span></Tooltip>,
            inboxText: el.inbox ? "inbox" : "notInbox",
        }
    }
    let loadData = async () => {
        let {result, error} = await loadReqConfirmation()
        if(error === 401) context.error_401();
        let addCurrencySign = (is_r, s, v) => ("" + (is_r ? "" : " " + s) + v + (is_r ? " " + s : ""))
        result = result.map(el => ({
            ...el,
            pricePerM: addCurrencySign(el.is_right, el.symbol, el.pricePerM),
            pricePerMPDF: addCurrencySign(el.is_right, el.symbolPDF, el.pricePerM),
            ...getStatusCrumbs(el)
        }))
        setItems(result);
    };

    let loadingAmNewReq = async () => {
        let {result: balance, error: error2} = await getBalance()
        if(error2 === 401) context.error_401();
        setContext({...context, info: balance})
    };

    let requestToConfirm = async (id, newState) => {
        let {result, error} = await confirmReq(id, newState)
        if(error === 401) context.error_401();
        if(result) {
            await loadData()
            loadingAmNewReq()
        }
    };

    useEffect(() => {
        trackPromise(loadData());
        return () => {};
    }, []);

    let columns = [
        {name: "", key: "status"},
        {name: t(lk + "reqId"),             key: "reqId",           sort: "f", filter: true},
        cr && (cr.DilerRigths || cr.SuperUserRigths) ?
            {name: t(lk + "clientName"),    key: "clientName",      sort: "t", filter: true}: null,
        cr && (cr.DilerRigths && !cr.SuperUserRigths) ?
            {name: t(lk + "inboxSign"),     key: "inboxSign", sort: "t", sortKey: "inboxText", noExport: true, width: "2rem"} : null,
        {name: t(lk + "creationDate"),      key: "creationDate",    sort: "d"},
        {name: t(lk + "packName"),          key: "packName",        sort: "t", filter: true},
        {name: t(lk + "pricePerM"),         key: "pricePerM",       exportKey: "pricePerMPDF",       sort: "f", filter: true},
        {name: t(lk + "amObjs"),            key: "amObjs",          sort: "f", filter: true},
        {name: t(lk + "changesUserName"),   key: "changesUserName", sort: "t", filter: true},
        {name: t(lk + "changesDate"),       key: "changesDate",     sort: "d"},
        {name: t(lk + "statusText"),        key: "statusText", sort: "t"},
        {name: "", key: "btnAccept", sort: "noSort", noExport: true, width: "2rem"},
        {name: "", key: "btnReject", sort: "noSort", noExport: true, width: "2rem"},
        {name: "", key: "status"},
    ].filter(el => el).map(el => ({...el, floatC: true, floatCA: true}))

    return  (
        <div className="py-2">
            {
                promiseInProgress ?
                    <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={true}><CircularProgress
                        color="inherit"/></Backdrop>
                    :
                    <div className="row m-0 mw-100">
                        <StatusFilter setArr={setFilterArr} arr={Array(statusColors.length).fill(undefined).map((el, i) => ({color: statusColors[i], text: statusTexts[i], isChecked: filterArr[i]}))}/>
                        <Table items={
                            items.filter(el => (
                                    el.status === "1" && filterArr[0] ||
                                    el.status === "2" && filterArr[1] ||
                                    el.status === "3" && filterArr[2] ||
                                    el.status === "4" && filterArr[3]
                                ))
                                .map(el => ({...el, statusText: statusTexts[parseInt(el.status) - 1]}))
                            }
                           columns={columns} doNotExpand={true}
                           defaultSort={{key: "creationDate", isUp: false}}
                        />
                    </div>
            }
        </div>

    )
}