import {serverURL, corsHeaders, momentToServerFormat} from "./constants";
const axios = require('axios');

export default async function loadBrandChart(from, to, cards){
    try {
        let response = await axios({
            method: 'get',
            url: serverURL + 'charts/brand/' + (from && to ?
                "?from=" + momentToServerFormat(from) +
                "&to=" + momentToServerFormat(to) +
                cards.reduce((s, c) => {return s + "&card=" + c.value}, "")
                    : ""
            ),
            headers: {
                'accept': 'application/json',
                ...corsHeaders
            },
        });

        return {result: response.status === 200 ? response.data : [], error: response.status}
    } catch (error) {
        return {result: [], error: error.response ? error.response.status : 500}
    }
}