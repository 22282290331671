import {serverURL, corsHeaders} from "./constants";
const axios = require('axios');

export default async function startBackLoading(intervals){
    try {
        let response = await axios({
            method: 'post',
            url: serverURL + 'transactions/intervalLoad/',
            headers: {
                ...corsHeaders,
                'Content-Type': 'application/json',
            },
            data: {
                from: intervals.from,
                to: intervals.to,
                wialon_ids: JSON.parse(intervals.cards),
            }
        });

        if(response.status !== 200)
            return {result: false, error: 200}

        return {result: response.data, error: response.status}
    } catch (error) {
        return {result: false, error: error.response ? error.response.status : 500}
    }
}