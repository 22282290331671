import {serverURL, corsHeaders} from "./constants"
const axios = require('axios');

export default async function loadCards(){
    try {
        let response = await axios({
            method: 'get',
            url: serverURL + 'cards/',
            headers: {
                'accept': 'application/json',
                //'Authorization': 'Bearer ' + token,
                ...corsHeaders
            },
        })

        if(response.status !== 200)
            return {result: [], error: response.status}

        let result = response.data.map(el => {
            return {
                title: el.cardNumber,
                value: el.cardNumber,
            }
        })

        return {result: result, error: response.status}
    } catch (error) {
        return {result: [], error: error.response ? error.response.status : 500}
    }
}