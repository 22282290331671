// let serverURL = 'http://192.168.1.198:8000/'
// let serverURL = 'http://192.168.1.35:8004/'
let serverURL = 'https://inpetrol-api.watchit.ru/'

let corsHeaders = {
    'Access-Control-Allow-Origin': [serverURL, "http://azs.uonline.com.ua/"],
    'Access-Control-Max-Age': '3600',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
    'Access-Control-Allow-Credentials': 'true',
    'Content-Type': 'application/x-www-form-urlencoded',
}

let formatDate = (str) => {
    if(!str) return ""
    let d = new Date(str)
    return d.toLocaleDateString('ru') + " " + d.toLocaleTimeString('ru').substr(0, d.toLocaleTimeString('ru').length - 3)
}

let momentToServerFormat = (curMoment) => curMoment.format("YYYY-MM-DD[T]HH:mm:ss")

export {serverURL, corsHeaders, formatDate, momentToServerFormat}
