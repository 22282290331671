import {serverURL, corsHeaders} from "../../../requests/constants";
const axios = require('axios');

export default async function requestToBuyPack(id){
    try {
        let response = await axios({
            method: 'post',
            url: serverURL + 'billing/request/',
            headers: {
                ...corsHeaders,
                'Content-Type': 'application/json',
            },
            data: {
                "pack_id": id,
                "amount": 1,
            }
        });

        return {result: response.status === 200, error: false}
    } catch (error) {
        return {result: false, error: error.response ? error.response.status : 500}
    }
}