import {serverURL, corsHeaders} from "./constants";
const axios = require('axios');

export default async function loadCharts(ids){
    try {
        let response = await axios({
            method: 'get',
            url: serverURL + 'transactions/short/?last_days=7' + ids.reduce((s, el) => s + "&card=" + el, ""),
            headers: {
                'accept': 'application/json',
                ...corsHeaders
            },
        });

        return {result: response.status === 200 ? response.data : [], error: response.status}
    } catch (error) {
        return {result: [], error: error.response ? error.response.status : 500}
    }
}