import React, {useEffect, useState} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Context} from "./Context.js";
import PageNotFound from './components/PageNotFound'
import Navbar from "./components/Navbar";
import Auth from "./components/Auth";
import Home from "./components/Home";
import DataPage from "./components/DataPage";
import SettingsPage from "./components/SettingsPage";
import Dashboard from "./components/Dashboard";
import {Backdrop, CircularProgress} from "@material-ui/core";
import loadingRights from "./requests/loadingRights";
import {useTranslation} from "react-i18next";
import "moment/locale/ru";
import "moment/locale/en-gb";
import "moment/locale/et";
import "moment/locale/es";
import changeLangTo from "./components/changeLangTo";
import {SnackbarProvider} from "notistack";
import {useDispatch} from 'react-redux'
import {loadingLogosDone} from "./redux/actions/appAction"
import loadLogos from "./requests/loadLogos";

import navbarLogo from "./logo_light.png"
import {logoAsBase64} from "./logoAsBase64"
import authLogo from "./logo_light.png"
import {faviconAsBase64} from "./faviconAsBase64"

export default function App() {
    const dispatch = useDispatch()
    const { _, i18n } = useTranslation();
    const [needUpdate, setNeedUpdate] = useState(Math.random())

    let getDefaultContext = () => ({
        login: localStorage.hasOwnProperty("login") ? localStorage.getItem("login") : "",
        filter: {
            cards: [],
            type: Array(5).fill(true),
            checkboxes: [],
        },
        info: {active_requests: 0},
        rights: "init",
        billing_state: false,
        updateWhenLangChanged: () => {setNeedUpdate(Math.random())},
        error_401: () => {
            localStorage.removeItem('login');
            setContext(getDefaultContext());
            setNeedUpdate(Math.random())}
        }
    )
    const [context, setContext] = useState(getDefaultContext());

    let getRights = async () => {
        let {result, error} = await loadingRights()
        if(result) {
            changeLangTo(result.lang, i18n)
        }
        setContext({...context, rights: result ? result : null})
    };

    let setFavicon = async (src) => {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = 'data:image/png;base64,' + src//'https://stackoverflow.com/favicon.ico';
    }

    let setTabTitle = newTitle => {
        document.title = newTitle
    }

    let startLoadingLogos = async (host) => {
        let result = await loadLogos(host)
        if(!result) {
            result = {
                navbarLogo: navbarLogo,
                pdfLogo: logoAsBase64,
                authLogo: authLogo,
                favicon: faviconAsBase64,
                title: "InPetrol",
                detail: {
                    authBg : "#212529",
                    authImgSize: "2.5rem"
                }
            }
        }

        dispatch(loadingLogosDone(result));
        setFavicon(result.favicon)
        setTabTitle(result.title)
    }

    useEffect(() => {

        startLoadingLogos(window.location.hostname)

        window.addEventListener('storage', (e) => { // i18nextLng
            setTimeout(() => {
                if(e.key === "login") {
                    setContext(getDefaultContext())
                    setNeedUpdate(Math.random())
                }
            }, 500)
        })
        getRights();
        return () => {};
    }, [needUpdate]);

    return (
        <>
            {
                context.rights !== "init"?
                    <Context.Provider value={[context, setContext]}>
                        <SnackbarProvider maxSnack={10} >
                            <div className="App" style={{height: "100vh"}}>
                                <Navbar/>

                                <div>
                                    <Switch>
                                        <Route path="/data" component={DataPage}/>
                                        <Route path="/settings" component={SettingsPage}/>
                                        <Route path="/dashboard" component={Dashboard}/>
                                        <Route path="/auth" component={() => (<Auth/>)}/>
                                        <Route exact path="/" component={Home}/>
                                        <Route component={PageNotFound}/>
                                    </Switch>
                                </div>
                            </div>
                        </SnackbarProvider>
                    </Context.Provider>
                    :
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}><CircularProgress color="inherit" /></Backdrop>

            }
        </>
    )
}
