import {LOADING_LOGOS_DONE} from '../types/appTypes'

const initialState = {
  navbarLogo: "",
  pdfLogo: "",
  authLogo: "",
  favicon: "",
  title: "...",
  detail: {
    authBg : "",
    authImgSize: "",
    service_name: "",
  }
}

export const appReducer = (state = initialState, action) => {

  switch (action.type) {
    /* в добавок к логотипам сохраняется название компании */
    case LOADING_LOGOS_DONE:
      return {
        ...state,
        ...action.payload,
        detail: Object.keys(action.payload.detail).length ? action.payload.detail : state.detail
      }
    default: return state
  }
}
