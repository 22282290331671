import React from "react";
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import "./DiapasonsDateTimePicker.css";
import moment from 'moment'
import {FormControl, MenuItem, Select, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {DesktopDatePicker, TimePicker} from "@mui/lab";

/*
    card object for writing results (dMin, dMax, dSelectI)
    setCard function for setting card

    // errors
    errors [false, false, false, false, false] red line on pickers
    errorsAll false make all Line red

    // callbacks when field change
    selectChange   () => {}
    fromDateChange () => {}
    fromTimeChange () => {}
    fromChange     () => {}
    toDateChange   () => {}
    toTimeChange   () => {}
    toChange       () => {}
    smthChange     () => {}

 */
export default function DiapasonsDateTimePicker(props) {
    const { t, i18n } = useTranslation();

    let card = props.card
    let setCard = props.setCard
    let showClock = props.showClock !== null ? props.showClock : true
    let errors = props.errors && props.errors.length === 5 ? props.errors : Array(5).fill(props.errorsAll !== null ? props.errorsAll : false)
    let selectChange   = props.selectChange   ? props.selectChange   : () => {}
    let fromDateChange = props.fromDateChange ? props.fromDateChange : () => {}
    let fromTimeChange = props.fromTimeChange ? props.fromTimeChange : () => {}
    let fromChange     = props.fromChange     ? props.fromChange     : () => {}
    let toDateChange   = props.toDateChange   ? props.toDateChange   : () => {}
    let toTimeChange   = props.toTimeChange   ? props.toTimeChange   : () => {}
    let toChange       = props.toChange       ? props.toChange       : () => {}
    let smthChange     = props.smthChange     ? props.smthChange     : () => {}


    // if you don't use i18n need to localize moment js
    //moment.locale('ru')

    let lk = "DiapasonsDateTimePicker."

    if(!card.dMin || !card.dMax || card.dSelectI === undefined) {
        setCard({...card, dSelectI: 1, dMin: moment().startOf('day'), dMax: moment().endOf('day')})
        return <></>
    }

    let dates = [
        {name: t(lk + "specificInterval"), min: moment().startOf('day'), max: moment().endOf('day')},
        {name: t(lk + "today"), min: moment().startOf('day'), max: moment().endOf('day')},
        {name: t(lk + "yesterday"), min: moment().subtract(1, 'days').startOf('day'), max: moment().subtract(1, 'days').endOf('day')},
        {name: t(lk + "tWeek"), min: moment().startOf('week'), max: moment().endOf('day')},
        {name: t(lk + "lWeek"), min: moment().subtract(1, 'week').startOf('week').startOf('day'), max: moment().subtract(1, 'week').endOf('week').endOf('day')},
        {name: t(lk + "tMonth"), min: moment().startOf('month').startOf('day'), max: moment().endOf('day')},
        {name: t(lk + "lMonth"), min: moment().subtract(1, 'month').startOf('month').startOf('day'), max: moment().startOf('month').subtract(1, 'days').endOf('day')},
    ]

    return (
        <LocalizationProvider dateAdapter={DateAdapter} locale={localStorage.getItem('momentLocale')}>
            <div className="btn-group pt-2 DiapasonsDateTimePicker" role="group">

                <div className="pt-3 pe-5">
                    <FormControl variant="standard" sx={{m: 0, p: 0}}>
                        <Select
                            value={card.dSelectI}
                            label=""
                            onChange={(e) => {
                                let i = e.target.value
                                setCard({...card, dMin: dates[i].min, dMax: dates[i].max, dSelectI: i,})
                                selectChange()
                                smthChange()
                            }}
                            error={errors[0]}
                        >
                            {dates.map((el, i) => <MenuItem value={i} key={"key_mi_date_" + i}>{el.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </div>

                <div className="pe-4">
                    <DesktopDatePicker
                        label={t(lk + "from")}
                        format="DD.MM.YYYY"
                        mask="__.__.____"
                        renderInput={(props) => <TextField {...props} variant="standard" sx={{width: "10rem"}} error={errors[1]}/>}
                        value={card.dMin}
                        maxDate={card.dMax}
                        onChange={(newV) => {setCard({...card, dMin: newV.startOf('day'), dSelectI: 0}); fromDateChange(); fromChange(); smthChange()}}
                    />
                </div>

                {
                    showClock ?
                        <div className="pe-5">
                            <TimePicker
                                label={" "}
                                mask="__:__"
                                renderInput={(props) => <TextField {...props} variant="standard" sx={{width: "7rem"}} error={errors[2]}/>}
                                value={card.dMin}
                                onChange={(newV) => {setCard({...card, dMin: newV, dSelectI: 0}); fromTimeChange(); fromChange(); smthChange()}}
                            />
                        </div>
                        : null
                }

                <div className="pe-4">
                    <DesktopDatePicker
                        label={t(lk + "to")}
                        mask="__.__.____"
                        renderInput={(props) => <TextField {...props} variant="standard" sx={{width: "10rem"}} error={errors[3]}/>}
                        value={card.dMax}
                        minDate={card.dMin}
                        onChange={(newV) => {setCard({...card, dMax: newV.endOf('day'), dSelectI: 0}); toDateChange(); toChange(); smthChange()}}
                    />
                </div>

                {
                    showClock ?
                        <div className="pe-4">
                            <TimePicker
                                label={" "}
                                mask="__:__"
                                renderInput={(props) => <TextField {...props} variant="standard" sx={{width: "7rem"}} error={errors[4]}/>}
                                value={card.dMax}
                                onChange={(newV) => {setCard({...card, dMax: newV, dSelectI: 0}); toTimeChange(); toChange(); smthChange()}}
                            />
                        </div>
                        : null
                }

            </div>
        </LocalizationProvider>
    )
}