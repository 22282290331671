import React, {useContext, useState, useEffect} from "react";
import {Context} from "../Context";
import {Redirect} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import ObjectsTable from "./ObjectsTable";
import ChangeTariff from "./ChangeTariff";
import OrgList from "./OrgList";
import AccessSettings from "./AccessSettings";
import {useTranslation} from "react-i18next";
import {Badge, Menu, MenuItem} from "@material-ui/core";
import ConnectedPacks from "./billing/ConnectedPacks";
import ReqConfirmation from "./billing/ReqConfirmation";
import Management from "./billing/Management";
import getBalance from "../requests/getBalance";
import { useSelector } from "react-redux";

export default function SettingsPage(){
    const [context, setContext] = useContext(Context);
    const [companyName, setCompanyName] = useState('')
    const state = useSelector(state => state)
    useEffect(()=>{
        setCompanyName(state.app.detail?.service_name)
    }, [state])
    const [activeTab, setActiveTab] = useState(-1);
    const [active_requests, srtActive_requests] = useState(0);
    const [activeTabComponent, setActiveTabComponent] = useState({label: "", component: null});
    const { t, i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    let cr = context.rights
    let loadingAmNewReq = async () => {
        let {result: balance, error: error2} = await getBalance()
        if(error2 === 401) context.error_401();
        setContext({...context, info: balance})
    };
    async function balancePingLoop() {
        if(localStorage.getItem('login')) {
            let {result, error} = await getBalance()
            if(error === 401) context.error_401();
            if(result) srtActive_requests(result.active_requests);
        }
        setTimeout(() => {balancePingLoop()}, 5000)
    }

    let tabs = [
        {label: t("settingsPage.orgList"), component: <OrgList/>},
        {label: t("settingsPage.accessRights"), component: <AccessSettings/>},
        ...[cr && (cr.AdminRigths || cr.EditPropertyRights || cr.ViewRights) ? {label: t("settingsPage.objects"), component: <ObjectsTable/>}: null],
        ...[cr && (cr.AdminRigths || cr.EditPropertyRights) ? {label: `${t("settingsPage.wialonMigration").replace('tms', `${companyName ? companyName : 'Wialon'}`)}`, component: <ChangeTariff/>}: null],
        {label: t("settingsPage.billing"), component: null,
            labelWithBadge: active_requests ? <Badge badgeContent={active_requests} color="primary">{t("settingsPage.billing")}&nbsp;&nbsp;&nbsp;&nbsp;</Badge> : t("settingsPage.billing"),
            ref: React.useRef(), subMenu:[
                ...[cr && (cr.Billing) ? {label: t("settingsPage.packs"), component: <ConnectedPacks/>}: null],
                ...[cr && (cr.DilerRigths || cr.SuperUserRigths) ? {label: t("settingsPage.management"), component: <Management/>}: null],
                ...[cr && (cr.Billing || cr.DilerRigths || cr.SuperUserRigths) ? {label: t("settingsPage.reqconfirmation"), component: <ReqConfirmation/>}: null],
            ].filter(el => el)
        },
    ].filter(el => el && (!el.subMenu || el.subMenu && el.subMenu.length))

    useEffect(() => {
        loadingAmNewReq();
        if(activeTab === -1 && localStorage.getItem('settingsActiveTab') !== null){
            let i = parseInt(localStorage.getItem('settingsActiveTab'))
            if(i >= tabs.length){
                i = 0;
                localStorage.removeItem('settingsActiveTab')
                localStorage.removeItem('settingsActiveSubTab')
            }
            setActiveTab(i)
            if(tabs[i].component) {
                setActiveTabComponent({label: tabs[i].label, component: tabs[i].component});
            }
            let j = localStorage.getItem('settingsActiveSubTab')
            if(!j || tabs[i].subMenu === undefined || (tabs[i].subMenu && j >= tabs[i].subMenu.length) || (tabs[i].subMenu && tabs[i].subMenu.length === 0)){
                j = null;
                localStorage.removeItem('settingsActiveSubTab')
            }
            if(j !== null){
                j = parseInt(j)
                setActiveTabComponent({label: tabs[i].label + " > " + tabs[i].subMenu[j].label, component: tabs[i].subMenu[j].component, breadCrumbs: true})
            }
        }
        balancePingLoop();
        return () => {};
    }, [context.info.active_requests]);

    const closeMenu = () => {setAnchorEl(null);};

    if(context.login === "")
        return <Redirect to='/auth'/>

    return  (
        <div className="py-2 px-3">
            <div className="row m-0 mw-100">
                <div className="btn-group py-2 px-0" role="group" aria-label="Basic example">
                    {
                        tabs.map((t, i) =>
                            <button type="button" className={"btn btn" + (activeTab !== i ? "-outline" : "") + "-dark"} key={"key_tab_btn_" + i}
                                    onClick={(e) => {
                                        setActiveTab(i);
                                        localStorage.setItem('settingsActiveTab', '' + i)
                                        localStorage.removeItem('settingsActiveSubTab')
                                        if(t.component) {
                                            setActiveTabComponent({label: t.label, component: t.component});
                                        }
                                        if(t.subMenu)
                                            setAnchorEl(t.ref.current)
                                    }}
                                    ref={t.ref}
                            >
                                {t.labelWithBadge ? t.labelWithBadge : t.label}
                            </button>
                        )
                    }
                </div>
            </div>

            {tabs.map((t, i) => !t.subMenu || activeTab !== i? null :
                <Menu anchorEl={anchorEl} open={open} onClose={closeMenu} MenuListProps={{'aria-labelledby': 'basic-button',}} transformOrigin={{vertical: 'top', horizontal: 'left'}} key={"key_map" + i}>
                    {t.subMenu.map((sm, j) =>
                        <MenuItem key={"key" + Math.random()}
                            onClick={() => {
                                closeMenu();
                                localStorage.setItem('settingsActiveSubTab', '' + j)
                                setActiveTabComponent({label: t.label + " > " + sm.label, component: sm.component, breadCrumbs: true})
                            }}
                        >
                            {sm.label}
                        </MenuItem>
                    )}
                </Menu>
            )}

            {activeTabComponent.breadCrumbs ? <div className="pt-2 pb-0 ps-3 fw-bold">{activeTabComponent.label}</div> : null}
            {activeTabComponent.component}
        </div>)
}