import React, {useContext, useState, useEffect} from "react";
import {Context} from "../Context";
import {Redirect} from 'react-router-dom';
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import loadMigration from "../requests/loadMigration";
import saveMigration from "../requests/saveMigration";
import {Backdrop, CircularProgress} from "@material-ui/core";
import PairOfGlasses from "./PairOfGlasses";
import {useTranslation} from "react-i18next";
import getBalance from "../requests/getBalance";
import ChangeTariffResultModal from "./ChangeTariffResultModal";
import { useSelector } from "react-redux";

export default function ChangeTariff(){
    const [context, setContext] = useContext(Context);
    const [items, setItems] = useState([]);
    const { promiseInProgress } = usePromiseTracker();
    const { t, i18n } = useTranslation();

    const [companyName, setCompanyName] = useState('')
    const state = useSelector(state => state)
    useEffect(()=>{
        setCompanyName(state.app.detail?.service_name || 'Wialon')
    }, [state])

    const [needRefresh, setNeedRefresh] = useState(Math.random());
    const [isOk, setIsOk] = useState(false);
    const [open, setOpen] = useState(false);
    const modalOpen = (type) => {setOpen(true)};
    const modalClose = (isSuccess = false) => {
        setOpen(false);
        if(isSuccess) {
            setNeedRefresh(Math.random())
        }
    };

    let sleep = ms => new Promise(r => setTimeout(r, ms));

    let saveF = async (arr, orArr) => {
        let byfAdd = []
        let byfDel = []
        orArr.forEach(orEl => {
            let byfEl = arr.reduce((s, el) => el.id === orEl.id ? el : s, "")
            if(byfEl !== ""){
                if(!orEl.right && byfEl.right)
                    byfAdd.push(byfEl.id);
                if(orEl.right && !byfEl.right)
                    byfDel.push(byfEl.id);
            }
        })
        let {result, error} = await saveMigration(byfAdd, byfDel)
        if(error === 401) context.error_401();
        let {result: balance, error: error2} = await getBalance()
        if(error2 === 401) context.error_401();
        setItems({...items, info: balance})
        setContext({...context, info: balance})
        setIsOk(result)
        modalOpen(result)
        return result
    };

    let loadData = async (ranges) => {
        let sl = sleep(300);
        let {result, error} = await loadMigration()
        if(error === 401) context.error_401();
        await sl
        setItems(result);
    };

    useEffect(() => {
        trackPromise(loadData());
        return () => {};
    }, [needRefresh]);

    if(context.login === "")
        return <Redirect to='/auth'/>

    return (<div >
            <div className="container-fluid pb-3 px-0">
                <div className="">
                    {promiseInProgress?
                        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}><CircularProgress color="inherit" /></Backdrop>
                        :
                        (items.length !== 0 ?
                            <div style={{width: "100%" }}>
                                <PairOfGlasses infoValues={items.info} arr={items.units} labelL={t("changeTariff.objOut")} labelR={t("changeTariff.objIn")} saveF={saveF}/>
                            </div>
                            :
                            <div className="my-3 py-1 text-center bg-danger rounded-3 text-white fw-bold">
                                {t("changeTariff.noData").replace('tms', companyName)}
                            </div>
                        )
                    }
                </div>
            </div>
            {open ? <ChangeTariffResultModal isOpen={open} isOk={isOk} handleClose={modalClose}/> : null}
        </div>
    )
}