import {FormControl, FormHelperText, IconButton, Input, InputAdornment, InputLabel} from "@material-ui/core";
import React, {useRef, useState} from "react";
import SearchIcon from "@material-ui/icons/Search";

export default function MakeInput(props){
    // specify same key in rerenders for not loosing focus
    const [eyeClosed, setEyeClosed] = useState(true)
    const [inFocus, setInFocus] = useState(false)
    let doEye = props.mykey.toLowerCase().indexOf("pass") !== -1
    let htmlId = "key__" + props.mykey
    const ref = useRef(null);
    if(props.refsPush)
        props.refsPush({key: props.mykey, ref: ref})


    return (
        <div className="row m-0 mw-100"  key={"r_" + htmlId}>
            <div className={"col-" + (doEye ? "10" : "12") + " p-0"} key={"c_" + htmlId}>
                <FormControl fullWidth sx={{ m: 0, p: 0 , pt: 0, w: "100%"}} variant="standard" key={"input_key_" + htmlId}>
                    {props.label !== "" ? <InputLabel htmlFor={htmlId} sx={{px: 1}}>{props.label}</InputLabel> : null}
                    <Input
                        id={htmlId}
                        key={htmlId}
                        defaultValue={props.hide && true && !inFocus ? "-------" : props.value}
                        inputRef={ref}
                        sx={{ m: 0 , pt: 0, w: "100%", borderColor: "red"}}
                        type={doEye && eyeClosed || props.hide ? "password" : props.type}
                        error={props.error}
                        disabled={props.disabled}
                        //onFocus={() => {setInFocus(true)}}
                        onBlur={(e) => {if(props.onBlur) props.onBlur(e)}}
                        onChange={(e) => {if(props.onChange) props.onChange(e)}}
                        onClick={(e) => {if(props.onClick) props.onClick(e)}}
                        autoComplete="off"
                        multiline={props.multiline}
                        maxRows={props.maxRows}
                    />
                    <FormHelperText>{props.note && props.note !== "" ? props.note : null}</FormHelperText>
                </FormControl>
            </div>
            {
                doEye ?
                    <div className="col-2 p-0 d-flex justify-content-center align-items-end">
                        <i className={"far fa-eye" + (eyeClosed ? "-slash" : "" ) + " pb-2"} onClick={() => {setEyeClosed(!eyeClosed)}}/>
                    </div>
                    : null
            }
        </div>

    )
}