import React from "react";
import {serverURL, corsHeaders} from "./constants";
const axios = require('axios');

export default async function loadObjects(){
    let formatBalance = (b) => parseFloat(b).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1 ")

    try {
        let response = await axios({
            method: 'get',
            url: serverURL + 'items/',
            headers: {
                'accept': 'application/json',
                ...corsHeaders
            },
        });

        if(response.status !== 200)
            return {result: [], error: response.status, ids: []}

        let ids = []
        let result = response.data.map(el => {
            ids.push({id: el.item_id, active: false, wialon_id: "" + el.wialon_id})
            return {
                id: el.item_id,
                transport: el.wialon_data.unitName,
                cardNumber: "" + (el.card_number && el.card_number !== 0 ? el.card_number : ""),
                paymentSystemId: "" + (el.type_system_id && el.type_system_id !== 0 ? el.type_system_id : ""),
                tankVolume: "" + el.fuel_volume,
                time_interval: el.time_interval,
                isVisible: el.visible_flag,
                isVisibleSign: el.visible_flag ? <i className="far fa-eye text-success"/> : <i className="far fa-eye-slash text-danger"/>,
                mileage: "" + formatBalance(el.wialon_data.mileageCoun),
                motoHours: "" + formatBalance(el.wialon_data.engHoursCount),
                fuelLvl: el.wialon_data.lastFuelVal === -1 ? "" : "" + formatBalance(el.wialon_data.lastFuelVal),
                lastLat: el.wialon_data.lastLat,
                lastLon: el.wialon_data.lastLon,
                lastConnectionTime: el.wialon_data.lastMsgTime,
                cards: el.cards,
                chart: <div></div>
            }
        })

        return {result: result, error: response.status, ids: ids}
    } catch (error) {
        return {result: [], error: error.response ? {status: error.response.status, detail: error.response.data && error.response.data.detail ? error.response.data.detail : null} : {status: 500, detail: ""}, ids: []}
    }
}
