import React, {useContext, useRef, useState} from "react";
import {Context} from "../Context";
import {Card, TextField} from "@material-ui/core";
import {Redirect} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import logIn from "../requests/logIn";
import changeLangTo from "./changeLangTo";
//import logo from "../logo_light.png";
import LangSwitcher from "./LangSwitcher";
import background from "../authBackground.jpg"
import {useSelector} from 'react-redux'

export default function Auth(){

    const logo = useSelector(state => state.app.authLogo)
    const detailCss = useSelector(state => state.app.detail)
    const [context, setContext] = useContext(Context);
    const [btnColor, setBtnColor] = useState('dark');
    const [eye, setEye] = useState(true);
    const refLogin = useRef(null);
    const refPass = useRef(null);
    const { t, i18n } = useTranslation();

    let btnC = (color) => {setBtnColor(color); setTimeout((() => {setBtnColor('dark')}, 500))}

    let tryLogIn = async (login, pass) => {
        btnC('warning')
        let {result, error} = await logIn(login, pass)
        if(result){
            btnC('success')
            if(result.locale){
                changeLangTo(result.locale, i18n)
            }
            localStorage.removeItem('settingsActiveSubTab')
            localStorage.removeItem('settingsActiveTab')
            localStorage.setItem("login", result.name)
            localStorage.setItem("measureWater", result.unit_system?.water)
            localStorage.setItem("measureDistance", result.unit_system?.distance)
            setContext({...context, login: result.name, rights: result.rights, billing_state: result.billing_state})
        } else {
            btnC('danger')
        }
    }
    if(context.login !== "")
        return <Redirect to='/'/>

    let textStyle = {input: {style: {fontSize: 30}}, label: {style: {fontSize: 20}}}

    return (<div className="container d-flex justify-content-center my-5 py-5 ">
                <img src={background} style={{position: "absolute", width: "100%", height: "100%", top: "0", filter: "blur(10px)", zIndex: "-9999"}}/>

                <Card className="p-4 text-center shadow" sx={{maxWidth: "30rem", borderRadius: "1rem", backgroundColor: "rgba(255,255,255,0.8)"}}>

                    <div className="row m-0 mw-100">

                        {
                            logo ?
                                <div className="col-12 px-0 py-3 text-center rounded-3" style={{backgroundColor: detailCss.authBg}}>
                                    <img className="" style={{height: detailCss.authImgSize}} src={logo} alt="..." loading="lazy"/>
                                </div>
                                : null
                        }


                        <div className="col-12 px-0 py-4">
                            <TextField className="fs-2" label={t("auth.login")} type="input" variant="standard" inputRef={refLogin}
                                inputProps={textStyle.input}
                                InputLabelProps={textStyle.label}
                                sx={{width: "100%"}}
                                autoComplete="new-password"
                            />
                        </div>

                        <div className="col-10 px-0 py-4">
                            <TextField label={t("auth.pass")} type={eye ? "password" : "input"} variant="standard" inputRef={refPass}
                                inputProps={textStyle.input}
                                InputLabelProps={textStyle.label}
                                sx={{width: "100%"}}
                                autoComplete="new-password"
                            />
                        </div>

                        <div className="col-2 p-0 d-flex justify-content-center align-items-center">
                            <i className={"pt-3 fs-5 far fa-eye" + (eye ? "-slash" : "")} onClick={() => {setEye(!eye)}}/>
                        </div>

                        <div className="col-6 px-0 pt-4 pb-2 text-start">
                            <button type="button" className={"btn fs-5 btn-outline-" + btnColor} onClick={() => {
                                let login = refLogin.current.value
                                let pass = refPass.current.value
                                if(login.length < 3 || pass.length < 3)
                                    btnC('danger')
                                else
                                    tryLogIn(login, pass)
                            }}>
                                {t("auth.btn")}&nbsp;&nbsp;<i className="fas fa-sign-in-alt"/>
                            </button>

                        </div>

                        <div className="col-6 px-0 pt-4 pb-2 d-flex justify-content-end avatarNoRound">
                            <LangSwitcher/>
                        </div>
                    </div>

                </Card>
            </div>
    )
}