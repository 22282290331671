import {serverURL, corsHeaders, formatDate} from "../../../requests/constants";
const axios = require('axios');

export default async function loadConnectedPacks(){
    try {
        /*return Array(5).fill(undefined).map((el, i) => ({
            name: "name_" + i,
            creationDate: formatDate("2021-10-05 21:13"),
            amObjs: "" + i * 10,
            pricePerM: "" + i * 100,
            amConnections: "" + i * 10,
        }))*/
        let response = await axios({
            method: 'get',
            url: serverURL + 'billing/tariff/short/',
            headers: {
                'accept': 'application/json',
                ...corsHeaders
            },
        });

        let result = response.data.map((el, i) => ({
            id: "" + el.PackID,
            name: "" + el.PackName,
            creationDate: formatDate(el.createTime),
            amObjs: "" + el.unitCount,
            pricePerM: "" + el.PackPrice,
            amConnections: "" + el.tariffCount,
            is_right: el.currency.is_right,
            symbol: el.currency.symbol,
            symbolPDF: el.currency.ISO_LAT3,
            isArchive: !el.Active,
            isForDealerOnly: el.Dealer,
        }))

        return {result: response.status === 200 ? result : [], error: false}
    } catch (error) {
        return {result: [], error: error.response ? error.response.status : 500}
    }
}