import {serverURL, corsHeaders} from "./constants"
const axios = require('axios');
const md5 = require('md5');
axios.defaults.withCredentials = true

export default async function logIn(login, pass){
    try {
        let response = await axios({
            method: 'post',
            url: serverURL + 'auth/',
            headers: {
                'accept': 'application/json',
                ...corsHeaders,
                'Content-Type': 'application/json',
                withCredentials: true,
            },
            data: {
                'login': login,
                'password': md5(pass),
            },
            withCredentials: true,
        })

        if(response.status !== 200)
            return {result: false, error: response.status}

        return {result: response.data, error: response.status}
    } catch (error) {
        return {result: false, error: error.response ? error.response.status : 500}
    }
}