import React, {useEffect, useState} from "react";
import {FormControl, IconButton, InputAdornment, MenuItem, Select, TextField} from "@material-ui/core";
import SavePDF from "./SavePDF";
import SaveXLSX from "./SaveXLSX";
import SearchIcon from "@material-ui/icons/Search";
import "./Table.css"
import moment from "moment";
import {useTranslation} from "react-i18next";
import {Tooltip} from "@mui/material";

export default function Table(props){
    const [curPage, setCurPage] = useState(0);
    const [tableStrOnePage, setTableStrOnePage] = useState(10);
    const [searchLine, setSearchLine] = useState("");
    //useEffect(() => {(props.searchLineChanged || (() => {}))(searchLine)}, [searchLine])

    let defaultOpenedStr = Array(tableStrOnePage).fill(false)
    const [openedStr, setOpenedStr] = useState(defaultOpenedStr);
    let defaultSort = props.defaultSort
    const [sort, setSort] = useState(defaultSort ? defaultSort : {key: "date", isUp: false});
    const { t, i18n } = useTranslation();
    let isSort = () => sort.key !== "";
    let getSortKey = () => sort.key;
    let sortChangeUp = (key) => {setOpenedStr(defaultOpenedStr); setSort({key: sort.key === key && sort.isUp ? "" : key, isUp: sort.key === key && !sort.isUp})};

    let ExpandableRow = (el) => props.ExpandableRow(el)
    let expandOnSingleClick = props.expandOnSingleClick
    let expandOnDoubleClick = props.expandOnDoubleClick
    let items = props.items
    let columns = props.columns.filter(c => !c.detail)
    let summary = props.summary
    let grouped = props.grouped
    let pdfHeader = props.pdfHeader
    let onPageChanged = props.onPageChanged ? props.onPageChanged : (() => {})

    let amStatus = columns.reduce((s,c) => s + (c.key === "status" && !c.empty ? 1 : 0), 0)
    let amStatusEmpty = columns.reduce((s,c) => s + (c.key === "status" && c.empty ? 1 : 0), 0)

    let makeStatusCell = (color) => <td className="statusLine" key={"key" + Math.random()} style={{backgroundColor: color}}/>

    let makeObjTr = (item, j) => {

        let openF = () => {setOpenedStr(openedStr.map((s, i) => (i === j ? !s : s)))};
        return(
            <tr key={"key" + j} className={"clickable " + (j % 2 === 0 ? "odd" : "even")}

            >
                {amStatus > 0 ? makeStatusCell(item["statusColor"]) : null}
                {amStatusEmpty > 0 ? <td className="vAlign text-center" onClick={openF} key={"key" + Math.random()}><i className={"fas " + (openedStr[j] ? "fa-sort-down text-primary" : "fa-caret-right opacity-50")}/></td> : null}
                {columns.filter(el => el.key !== "status").map((col, colI) =>{
                    const opacity = (!item.status && col.name === 'Транспорт') ? " opacity-50 " : ""
                    const trend = item[col.key] === '>'
                    const cell = col.key !== 'trend' ? item[col.key] : !item[col.key] ? item[col.key] : <i className={"fas fa-arrow-right " + (trend ? "text-success" : "text-danger")} style={{transform: `rotate(${trend ? 0.9 : 0.1}turn)`}}/>
                    return <td className={(col.floatC ? "text-center" : "") + " " + (col.floatCA ? "vAlign" : "") + opacity + (col.nowrap ? "text-nowrap" : null)} key={"key" + j + "_" + colI} style={{width: (col.width? col.width : "")}}
                    onClick={(e) =>{if(expandOnSingleClick && !col.doNotExpand) openF()}}
                    onDoubleClick={(e) =>{if(expandOnDoubleClick && !col.doNotExpand) openF()}}
                    >
                        {cell}
                    </td>})
                }
                {amStatus > 1 ? makeStatusCell(item["statusColor"]) : null}
            </tr>
        )
    }

    let trs = [];
    //console.log(items, columns.filter((c, j) => c.filter).map(c => c.key))
    let itemsAfterSearch =
        grouped ?
            items.map(el => {return {...el, substrs:
                el.substrs.filter((el2, i) => columns.filter((c, j) => c.filter).reduce((s, c) => {
                        return el2[c.key].toLowerCase().indexOf(searchLine.toLowerCase()) === -1
                            ? s : s + 1
                    }, 0) > 0 ||
                    el.login.toLowerCase().indexOf(searchLine.toLowerCase()) !== -1  /*  key  HARDCODE    */
                )
            }}).filter(el => el.substrs.length)
            :
            items
                .filter((e, i) => columns.filter((c, j) => c.filter)
                .reduce((s, c) => {
                    //console.log("qwe: ", e, c, c.key, e[c.key])
                    return e[c.searchKey || c.key].toLowerCase().indexOf(searchLine.toLowerCase()) === -1 ? s : s + 1
                }, 0) > 0)

    let itemsAfterSort = itemsAfterSearch
    if(isSort())
        itemsAfterSort = itemsAfterSort.sort((a, b) =>
            {
                let k = getSortKey()
                let dateFormat = "DD.MM.YYYY HH:mm"
                let sortType = k !== "" ? columns.filter(c => ((c.sortKey ? c.sortKey : c.key) === k)) : []
                if(sortType.length === 0)
                    return false
                sortType = sortType[0].sort // t d f

                let result = a[k] > b[k] ? 1 : -1 // t
                if(sortType === "d")
                    if (moment(a[k], dateFormat).isValid() && moment(b[k], dateFormat).isValid())
                        result = moment(a[k], dateFormat).isAfter(moment(b[k], dateFormat)) ? 1 : -1
                if(sortType === "f") {
                    let aa = ("" + a[k]).replace(/\s/g, '')
                    let bb =("" + b[k]).replace(/\s/g, '')
                    aa = aa.length > 0 ? parseFloat(aa) : 0
                    bb = bb.length > 0 ? parseFloat(bb) : 0

                    result = aa > bb ? 1 : -1
                }
                return result * (sort.isUp ? 1 : -1)
            }
        );

    let itemsAfterSortForExport = itemsAfterSort.map(el => el)
    itemsAfterSort = itemsAfterSort.filter((e, i) => i >= curPage * tableStrOnePage && i < (curPage + 1) * tableStrOnePage)
    if(grouped){
        itemsAfterSort.forEach((tr, j) => {
            trs.push(
                <tr key={"key" + Math.random()}>
                    <td className="text-start px-5 vAlign" colSpan={columns.length} style={{backgroundColor: "#cecece"}} key={"key" + Math.random()}>
                        {tr.login /*  key  HARDCODE    */}
                    </td>
                </tr>
            )

            if(tr.substrs.length === 0)
                trs.push(
                    <tr key={"key" + Math.random()}>
                        <td className="text-center px-5 vAlign" colSpan={columns.length} style={{backgroundColor: ""}} key={"key" + Math.random()}>
                            {t("table.noData")}
                        </td>
                    </tr>
                )

            tr.substrs.forEach((subTr, subTrI) => {
                trs.push(
                    <tr key={"key" + Math.random()}>
                        {
                            columns.map((col, colI) =>
                                <td className={(col.floatC ? "text-center" : "") + " " + (col.floatCA ? "vAlign" : "") + " " + (col.nowrap ? "text-nowrap" : null)} key={"key" + Math.random()}
                                    style={{width: (col.width? col.width : ""), color: (subTrI === 0 && colI === 0? "#cc7b00": "")}}>
                                    {subTr[col.key]}
                                </td>
                            )
                        }
                    </tr>
                )
            })
        })
    } else {
        itemsAfterSort.forEach((tr, j) => {
            trs.push(makeObjTr(tr, j))
            if (openedStr[j] && !props.doNotExpand)
                trs.push(<tr className="expandableRow" key={"key" + Math.random()}>
                    {amStatus > 0 ? <td className="statusLine" key={"key" + Math.random()}/> : null}
                    {amStatusEmpty > 0 ? <td className="statusLine" key={"key" + Math.random()}/> : null}
                    {<td colSpan={columns.length - amStatus - amStatusEmpty}><ExpandableRow el={tr}/></td>}
                    {amStatus > 1 ? <td className="statusLine" key={"key" + Math.random()}/> : null}
                </tr>)
        })
    }

    summary = summary ? summary.map(sEl => {return {
        title: sEl.title,
        value: itemsAfterSearch.reduce((s, el) => s + (
            sEl.action ?
                sEl.action(el[sEl.key1] && parseFloat(el[sEl.key1]) ? parseFloat(el[sEl.key1]) : 0, el[sEl.key2] && parseFloat(el[sEl.key2]) ? parseFloat(el[sEl.key2]) : 0)
                :
                el[sEl.key] && parseFloat(el[sEl.key]) ? parseFloat(el[sEl.key]) : 0
        ), 0).toFixed(2),
        resultAction: sEl.resultAction
    }}) : null

    summary = summary ? summary.map(sEl => {return {
        ...sEl,
        value: sEl.resultAction ? sEl.resultAction(sEl.value) : sEl.value
    }}) : null


    let objsTable =
        <table className="table m-0">
            <thead className="bg-dark text-white">
            <tr>
                {columns.map(col =>

                    <th className={"text-center " + (col.key === "status" && !col.empty ? "statusLine" : "") + " " + (col.sort !== "noSort" ? "clickable" : "") + " " + (col.nowrap ? "text-nowrap" : "")}
                        scope="col" key={"key" + Math.random()} onClick={() => {if(col.sort !== "noSort") sortChangeUp(col.sortKey ? col.sortKey : col.key)}}
                    >
                        <Tooltip title={col.tooltip ? col.tooltip : ""}>
                            <span>
                                <div className="d-flex justify-content-center">
                                    {col.sort !== "noSort" ? <div className="d-table-cell vAlign">{getSortKey() === (col.sortKey ? col.sortKey : col.key) ? <i className={"pe-2 fas text-white fa-long-arrow-alt-" + (sort.isUp ? "up" : "down")}/>: ""}</div> : null}
                                    <div className="d-table-cell vAlign">{col.thName ? col.thName : col.name}</div>
                                </div>
                            </span>
                        </Tooltip>
                    </th>

                )}
            </tr>
            </thead>
            <tbody>
                {trs.length === 0 ? <tr key={"key" + Math.random()}><td className="text-center" colSpan={columns.length}>{t("table.noData")}</td></tr> : trs}
            </tbody>
        </table>

    let pages = Math.ceil(itemsAfterSearch.length / tableStrOnePage)

    if(curPage > pages)
        setCurPage(0)

    return (<div>
                <div className="row py-2 m-0 mw-100">
                    {/*<Tooltip title="123123">
                        <span>Bar</span>
                    </Tooltip>*/}
                    <div className="col-6" style={{marginTop: "16px"}}>
                        <div className={"d-inline mx-2"}>
                            {t("table.showBy")}&nbsp;&nbsp;
                            <FormControl variant="standard" sx={{minWidth: 50}}>
                                <Select value={tableStrOnePage} onChange={(e) => {
                                    setOpenedStr(Array(e.target.value).fill(false));
                                    setCurPage(0);
                                    setTableStrOnePage(e.target.value)
                                }}>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={25}>25</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div className={"d-inline mx-2"}>
                            <SavePDF cells={columns.filter(el => !el.empty && !el.noExport).map(cl => {
                                return {title: cl.name, key: cl.key, exportKey: cl.exportKey, exportText: cl.exportText, pdfMaxW: cl.pdfMaxW}
                            })} array={itemsAfterSortForExport} pdfHeader={pdfHeader} grouped={grouped}/>
                        </div>

                        <div className={"d-inline mx-2"}>
                            <SaveXLSX cells={columns.filter(el => el.key !== "status" && !el.noExport).map(cl => {
                                return {title: cl.name, key: cl.key, exportText: cl.exportText}
                            })} array={itemsAfterSortForExport} grouped={grouped}/>
                        </div>

                    </div>

                    <div className="col-6 text-end ">
                        <TextField variant="standard" label={t("table.search")} value={searchLine} onChange={(e) => {setCurPage(0); setSearchLine(e.target.value)}}
                        InputProps={{endAdornment: (<InputAdornment position='end'><IconButton><SearchIcon /></IconButton></InputAdornment>)}}
                        />
                    </div>
                </div>

                <div className="row py-2 m-0 mw-100">
                    <div className="col-12 card p-0 rounded-3">
                        {objsTable}
                    </div>
                </div>

                <div className="row pt-2 pb-2 m-0 mw-100">
                    <div className="col-12">
                        <div className="text-end">
                            <div className="d-inline pe-4">{curPage + 1}&nbsp;&nbsp;{t("table.of")}&nbsp;&nbsp;{pages}</div>
                            <div className={"paginatorButton d-inline mx-2 rounded-3 " + (curPage === 0 ? "opacity-50" : "")} onClick={()=>{setOpenedStr(defaultOpenedStr); setCurPage(0); onPageChanged()}}><i className="fas fa-angle-double-left fs-5 text-muted"/></div>
                            <div className={"paginatorButton d-inline mx-2 rounded-3 " + (curPage === 0 ? "opacity-50" : "")} onClick={()=>{setOpenedStr(defaultOpenedStr); setCurPage(curPage - 1 >= 0 ? curPage - 1 : 0); onPageChanged()}}><i className="fas fa-angle-left fs-5 text-muted"/></div>
                            <div className={"paginatorButton d-inline mx-2 rounded-3 " + (curPage === pages - 1 ? "opacity-50" : "")} onClick={()=>{setOpenedStr(defaultOpenedStr); setCurPage(curPage + 1 <= pages - 1 ? curPage + 1 : pages - 1); onPageChanged()}}><i className="fas fa-angle-right fs-5 text-muted"/></div>
                            <div className={"paginatorButton d-inline mx-2 rounded-3 " + (curPage === pages - 1 ? "opacity-50" : "")} onClick={()=>{setOpenedStr(defaultOpenedStr); setCurPage(pages - 1); onPageChanged()}}><i className="fas fa-angle-double-right fs-5 text-muted"/></div>
                        </div>
                    </div>
                </div>

            {trs.length !== 0 && summary ?
                <div className="row pt-2 pb-2 text-end m-0 mw-100">
                    <div className="col-2 text-start rounded-3">
                        <table className="table m-0 bg-light text-center">
                            <thead className="bg-dark text-white"><tr><th className="results" colSpan="2">{t("table.summary")}</th></tr></thead>
                            <tbody>{summary.map(el => <tr key={"key" + Math.random()}><td>{el.title}</td><td>{el.value}</td></tr>)}</tbody>
                        </table>
                    </div>
                    <div className="col-10"/>
                </div>
                : null
            }
    </div>
    )
}