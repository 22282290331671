import {serverURL, corsHeaders, formatDate} from "../../../requests/constants";
const axios = require('axios');

export default async function loadCurr(valid = false){
    try {
        /*let names = ["rub", "dol", "eur"]
        return Array(names.length).fill(undefined).map((el, i) => ({
            id: "" + i,
            name: names[i]
        }))*/
        let response = await axios({
            method: 'get',
            url: serverURL + 'currencies/' + (valid ? "?valid=true" : ""),
            headers: {
                'accept': 'application/json',
                ...corsHeaders
            },
        });

        let result = response.data.map(el => ({
            id: "" + el.currency_id,
            name: "" + el.ISO_LAT3 + " " + el.symbol
        }))
        return {result: response.status === 200 ? result : [], error: false}
    } catch (error) {
        return {result: [], error: error.response ? error.response.status : 500}
    }
}