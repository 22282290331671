import React, {useContext, useEffect, useState} from "react";
import addPack from "./requests/addPack";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {Autocomplete, TextField} from "@material-ui/core";
import MakeInput from "../MakeInput";
import {useTranslation} from "react-i18next";
import loadCurr from "./requests/loadCurr";
import {Context} from "../../Context";
import {Checkbox} from "@mui/material";

export default function AddPackModal(props){
    const [context, setContext] = useContext(Context);
    let refArr = []
    const { t, i18n } = useTranslation();
    let lk = "billing.AddPackModal."

    const [saveBtnColor, setSaveBtnColor] = useState('dark');
    let refsPush = (newRef) => {refArr = [...refArr.filter(el => el.key !== newRef.key), newRef]}
    const [card, setCard] = useState({packName: "", pricePerM: "", amObjs: "", forDealerOnly: false});
    const [curCur, setCurCur] = useState(0);
    const [currList, setCurrList] = useState([]);

    let loadCurrs = async () => {
        let {result, error} = await loadCurr(true)
        if(error === 401) context.error_401();
        setCurrList(result);
        if(result.length)
            setCurCur(result[0])
    };

    useEffect(() => {
        loadCurrs();
        return () => {};
    }, []);

    let getVByRefK = (key) => refArr.filter(el => el.key === key) ? "" + refArr.filter(el => el.key === key)[0].ref.current.value : "";

    let save = async () => {
        if(saveBtnColor !== "dark")
            return;
        setSaveBtnColor('warning')
        let lastCard = {
            ...card,
            packName: getVByRefK("packName"),
            pricePerM: getVByRefK("pricePerM"),
            amObjs: getVByRefK("amObjs"),
            curCurId: curCur.id
        }

        setCard(lastCard)
        let {result, error} = await addPack(lastCard)
        if(error === 401) context.error_401();
        if(result) setSaveBtnColor('success');
        else setSaveBtnColor('danger');
        setTimeout(() => {
            setSaveBtnColor('dark')
            if(result)
                props.handleClose(true)
        }, 800)
    }

    const modalStyle = {position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "30rem", // width: "50vw", height: "95vh", overflow: "auto",
        bgcolor: '#eeeeee', border: '2px solid grey', borderRadius: "1rem", boxShadow: 24, p: 4, pt: 2
    };

    let updateCard = () => {setCard(prev => ({packName: getVByRefK("packName"), pricePerM: getVByRefK("pricePerM"), amObjs: getVByRefK("amObjs"), forDealerOnly: prev.forDealerOnly}))}

    return (
        <Modal open={props.isOpen}
               onClose={(e, reason) => {if (reason !== 'backdropClick') {props.handleClose()}}}
        >
            <Box sx={modalStyle}>
                <div className="text-center py-2 mb-2 rounded-3 bg-dark text-white fw-bold">
                    {t(lk + "header")}
                </div>

                <div className="text-start rounded-3">

                    <div className={"card  my-2 py-2"}>
                        <div className="row m-0 mw-100">
                            <div className="col-12 px-3 text-start py-2">

                                <div className="px-2 pt-2 pb-4"><i className="fas fa-address-card"/>&nbsp;&nbsp;{t(lk + "inputHeader")}</div>

                                <MakeInput value={card.packName} label={t(lk + "packName")} note="" mykey={"packName"} refsPush={(newRef) => {refsPush(newRef)}} error={saveBtnColor === 'danger'}/>

                                <div className="row m-0 mw-100">

                                    <div className="col-8 px-0 pe-4">
                                        <MakeInput value={card.pricePerM} label={t(lk + "pricePerM")} note="" mykey={"pricePerM"} refsPush={(newRef) => {refsPush(newRef)}} error={saveBtnColor === 'danger'}/>
                                    </div>

                                    <div className="col-4 px-0">
                                        <Autocomplete
                                            onChange={(e, nv) => {
                                                setCurCur(nv)
                                                console.log(nv)
                                                updateCard()
                                            }}
                                            options={currList}
                                            value={curCur}
                                            getOptionLabel={el => el ? el.name : ""}
                                            loading={currList.length === 0}
                                            loadingText={t(lk + "loadingText")}
                                            noOptionsText={t(lk + "noOptionsText")}
                                            sx={{width: "100%", pt: "1rem"}}
                                            renderInput={(params) => <TextField {...params} label="" value="" variant="standard" error={saveBtnColor === 'danger'}/>}
                                            key={"AutocompleteKey"}
                                            disabled={!context.rights.SuperUserRigths}
                                        />
                                    </div>
                                </div>

                                <MakeInput value={card.amObjs} label={t(lk + "amObjs")} note="" mykey={"amObjs"} refsPush={(newRef) => {refsPush(newRef)}} error={saveBtnColor === 'danger'}/>

                                {
                                    context.rights.SuperUserRigths ?
                                        <div className="py-2 d-flex justify-content-start align-items-center">
                                            <Checkbox checked={card.forDealerOnly} onClick={() => {setCard(prev => ({...prev, forDealerOnly: !prev.forDealerOnly}))}}/>
                                            &nbsp;&nbsp;
                                            {t(lk + "forDealerOnly")}
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className={"card  my-2 py-2"}>
                        <div className="row m-0 mw-100">
                            <div className="col-12 px-3 text-start py-2">
                                <div className="px-2 py-2"><i className="fas fa-info"/>&nbsp;&nbsp;{t(lk + "attention")}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="text-end pt-2">
                    <button type="button" className={"me-2 btn btn-outline-dark"} onClick={props.handleClose}>
                        {t(lk + "cancel")}&nbsp;&nbsp;<i className="fas fa-window-close"/>
                    </button>

                    <button type="button" className={"btn btn-outline-" + saveBtnColor} onClick={() => {save()}}>
                        {t(lk + "save")}&nbsp;&nbsp;<i className="fas fa-save"/>
                    </button>
                </div>
            </Box>
        </Modal>
    )
}