import React, {useContext, useState, useEffect} from "react";
import {Context} from "../Context";
import {Redirect} from 'react-router-dom';
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import Table from "./Table";
import {Backdrop, Checkbox, CircularProgress} from "@material-ui/core";
import loadUsers from "../requests/loadUsers";
import removeUsers from "../requests/removeUsers";
import saveAccRights from "../requests/saveAccRights";
import RemoveModal from "./RemoveModal";
import EditUserModal from "./EditUserModal";
import {useTranslation} from "react-i18next";

export default function AccessSettings(){
    const [context, setContext] = useContext(Context);
    let cr = context.rights
    const [items, setItems] = useState([]);
    const [needRefresh, setNeedRefresh] = useState(Math.random());
    const { promiseInProgress } = usePromiseTracker();
    const [removingId, setRemovingId] = useState(null);
    const [removingBtnColor, setRemovingBtnColor] = useState('dark');
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [editingUser, setEditingUser] = useState(null);
    const modalOpen = (type) => {setModalType(type); setOpen(true)};
    const modalClose = (isSuccess = false) => {setModalType(null); setOpen(false); if(isSuccess) setNeedRefresh(Math.random())};

    let sleep = ms => new Promise(r => setTimeout(r, ms));

    let addUser = async (el = null) => {
        modalClose()
        setEditingUser(el);
        modalOpen('adding')
        return
    }

    let removeUser = async (id, confirmed= false) => {
        setRemovingId(id)
        if(!confirmed) {
            modalClose()
            setRemovingBtnColor('dark')
            modalOpen('removing')
            return
        }
        setRemovingBtnColor('warning')
        let {result, error} = await removeUsers(removingId)
        if(error === 401) context.error_401();
        setRemovingBtnColor( result ? 'success' : 'danger')
        setTimeout(() => {
            setRemovingBtnColor('dark');
            setItems(result ? items.map(gi => {return {...gi, substrs: gi.substrs.filter(el => el.id !== removingId)}}) : items)
            modalClose()
        }, 500)
    }

    let checkStyle = {color: "#212529", '&.Mui-checked': {color: "#212529"}, '&.Mui-disabled': {opacity: "0.35"}}

    let saveAccessRights = (idUser, idSubUser, bool_i) => {

        let byf = items.filter(el => el.id === idUser)[0].substrs.filter(el => el.id === idSubUser)[0]
        byf["bool_" + bool_i] = !byf["bool_" + bool_i]
        byf["check_" + bool_i] = <Checkbox className="p-0" disabled={byf.disabled[bool_i]} checked={byf["bool_" + bool_i]} onClick={() => {saveAccessRights(idUser, idSubUser, bool_i)}} sx={checkStyle}/>

        saveAccRights(byf)
        setItems(items.map(el => (el.id === idUser ? {...el, substrs: el.substrs.map(el2 => (el2.id === idSubUser ? byf: el2))}: el)))
    }

    let makeChecks = (idUser = null, idSubUser = null) => {
        setItems(items.map(user => (user.id === idUser && idUser || !idUser? {...user,
            substrs: user.substrs.map(subUser => (subUser.id === idSubUser && subUser || !idSubUser? {
                ...subUser,
                check_0: <Checkbox className="p-0" disabled={subUser.disabled[0]} checked={subUser.bool_0} onClick={() => {saveAccessRights(user.id, subUser.id, 0)}} sx={checkStyle}/>,
                check_1: <Checkbox className="p-0" disabled={subUser.disabled[1]} checked={subUser.bool_1} onClick={() => {saveAccessRights(user.id, subUser.id, 1)}} sx={checkStyle}/>,
                check_2: <Checkbox className="p-0" disabled={subUser.disabled[2]} checked={subUser.bool_2} onClick={() => {saveAccessRights(user.id, subUser.id, 2)}} sx={checkStyle}/>,
                check_3: <Checkbox className="p-0" disabled={subUser.disabled[3]} checked={subUser.bool_3} onClick={() => {saveAccessRights(user.id, subUser.id, 3)}} sx={checkStyle}/>,
                check_4: <Checkbox className="p-0" disabled={subUser.disabled[4]} checked={subUser.bool_4} onClick={() => {saveAccessRights(user.id, subUser.id, 4)}} sx={checkStyle}/>,
                check_5: <Checkbox className="p-0" disabled={subUser.disabled[5]} checked={subUser.bool_5} onClick={() => {saveAccessRights(user.id, subUser.id, 5)}} sx={checkStyle}/>,
            } : subUser))} : user)))
    }

    let loadData = async (ranges) => {
        let sl = sleep(300);
        let {result, error} = await loadUsers()
        if(error === 401) context.error_401();
        await sl

        result = result.map(gi => ({...gi,
            substrs: gi.substrs.map((el, elI) => ({
                    ...el,
                    edit: <i className="fas fa-edit" onClick={() => {addUser(el)}} style={{cursor: "pointer"}}/>,
                    remove: el.creator && elI !== 0 ? <i className="fas fa-trash-alt" onClick={() => {removeUser(el.id)}} style={{cursor: "pointer"}}/> : "",
                }
            ))
        }))

        setItems(result);
    };


    if(items.reduce((s, el) => (s + el.substrs.reduce((ss, el2) => (ss + (el2.check_0 === undefined ? 1 : 0)), 0)), 0) > 0){
        makeChecks()
    }

    useEffect(() => {
        trackPromise(loadData());
        return () => {};
    }, [needRefresh]);

    if(context.login === "")
        return <Redirect to='/auth'/>

    let columns = [
        {name: t("accessSettings.login"),           key: "login",           filter: true},
        {name: t("accessSettings.creator"),         key: "creator",         },
        {name: t("accessSettings.lastAuthDate"),    key: "lastAuthDate",    },
        {name: t("accessSettings.creationDate"),    key: "creationDate",    },
        {name: t("accessSettings.lastChangeDate"),  key: "lastChangeDate",  },
        {name: t("accessSettings.changeCreator"),   key: "changeCreator",   },
        {name: t("accessSettings.check_0"),         key: "check_0",         width: "1rem"},
        {name: t("accessSettings.check_1"),         key: "check_1",         width: "1rem"},
        {name: t("accessSettings.check_2"),         key: "check_2",         width: "1rem"},
        {name: t("accessSettings.check_3"),         key: "check_3",         width: "1rem"},
        {name: t("accessSettings.check_4"),         key: "check_4",         width: "1rem"},
        {name: t("accessSettings.check_5"),         key: "check_5",         width: "1rem"},
        ...[cr && (cr.AdminRigths) ? {name: "",     key: "edit",            width: "3rem"}: null],
        ...[cr && (cr.AdminRigths) ? {name: "",     key: "remove",          width: "3rem"}: null],
    ].filter(el => el).map(el => {return {...el, floatC: true, floatCA: true, sort: "noSort",}})

    return (<div >
            <div className="container-fluid pb-3">
                <div className="px-3">
                    {promiseInProgress ?
                        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}><CircularProgress color="inherit" /></Backdrop>
                        :
                        <div>
                            {
                                open ?
                                    modalType === 'removing' ?
                                        <RemoveModal isOpen={open}  handleClose={modalClose}
                                                     btnColor={removingBtnColor} btnF={() => {removeUser(null, true)}}
                                        /> :
                                        <EditUserModal isOpen={open}  handleClose={modalClose} editingUser={editingUser}/>
                                    : null
                            }

                            {
                                cr && (cr.AdminRigths) ?
                                    <div className="row pt-2 m-0 mw-100">
                                        <div className="col-12 p-0">
                                            <button type="button" className={"btn btn-outline-dark"} onClick={() => {addUser()}}>
                                                {t("accessSettings.addUser")}&nbsp;&nbsp;<i className="fas fa-plus"/>
                                            </button>
                                        </div>
                                    </div>
                                    : null
                            }

                            <Table items={items} columns={columns} grouped={true} doNotExpand={true}/>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}