import {serverURL, corsHeaders} from "./constants";
const axios = require('axios');

export default async function loadOrgCard(id = null){
    try {
        let response = await axios({
            method: 'get',
            url: serverURL + 'clients/' + (id ? id + "/info": "services") + '/',
            headers: {
                'accept': 'application/json',
                ...corsHeaders
            },
        });
        if(response.status !== 200)
            return {result: false, error: response.status}

        let result = {...response.data, ...( !id ? {user: "", login: "", pass: "", passConfirm: "", isDealer: false} :{})}

        return {result: result, error: response.status}
    } catch (error) {
        return {result: false, error: error.response ? error.response.status : 500}
    }
}