import React, {useContext, useState, useEffect} from "react";
import {Context} from "../Context";
import {Redirect} from 'react-router-dom';
import moment from 'moment'
import {usePromiseTracker, trackPromise} from "react-promise-tracker";
import Table from "./Table";
import {Backdrop, Checkbox, CircularProgress, Tooltip} from "@material-ui/core";
import ExpandableRowObj from "./ExpandableRowObj";
import loadObjects from "../requests/loadObjects";
import loadCharts from "../requests/loadCharts";
import DiapasonsDateTimePicker from "./DiapasonsDateTimePicker/DiapasonsDateTimePicker";
import {Line, ComposedChart, Bar} from "recharts";
import {useTranslation} from "react-i18next";
import loadServiceTypes from "../requests/loadServiceTypes";
import startBackLoading from "../requests/startBackLoading";
import checkBackLoading from "../requests/checkBackLoading";
import {useSnackbar} from 'notistack';
import ImportFileModal from "./ImportFileModal";
import {useDispatch, useSelector} from "react-redux";
import {importFromFileInit} from "../redux/actions/transactionActions";
import {momentToServerFormat} from "../requests/constants";

export default function ObjectsTable() {
    const dispatch = useDispatch()
    const [modalImportFile, setModalImportFile] = useState(false)

    const [companyName, setCompanyName] = useState('')
    const state = useSelector(state => state)
    useEffect(()=>{
        setCompanyName(state.app.detail?.service_name || 'Wialon')
    }, [state])


    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const snackDef = {
        variant: "warning",
        anchorOrigin: {vertical: 'bottom', horizontal: 'right'},
        autoHideDuration: null,
        action: key => <i className="fas fa-times pe-2" style={{cursor: "pointer"}} onClick={e => {
            closeSnackbar(key)
        }}/>
    }

    const recountingErrorsLangKeys = {
        1: "unknown",
        2: "connectError",
        3: "noAccess",
    }

    const [context, setContext] = useContext(Context);
    const [items, setItems] = useState([]);
    const [searchLine, setSearchLine] = useState("");
    const [needReload, setNeedReload] = useState(Math.random());
    const [recountBtnStatus, setRecountBtnStatus] = useState('dark');
    const [serviceTypes, setServiceTypes] = useState([]);
    const [deadTokenError, setDeadTokenError] = useState(null);
    const [isShowVisible, setIsShowVisible] = useState(true);
    const [isShowUnVisible, setIsShowUnVisible] = useState(true);
    const [datePicker, setDatePicker] = useState({});

    useEffect(() => {
        if(context?.filter?.masterCheckbox && items.length){
            console.log("context.filter.masterCheckbox")
            enqueueSnackbar(t("objectsTable.checkboxMessage.checkAll").replace('%d', "" + items.length), {
                ...snackDef,
                variant: "warning",
                autoHideDuration: 5000
            });
        }
    }, [context?.filter?.masterCheckbox])

    let cr = context.rights
    const {promiseInProgress} = usePromiseTracker();
    const {t, i18n} = useTranslation();

    let sleep = ms => new Promise(r => setTimeout(r, ms));
    let remToPx = (rem) => rem * parseFloat(getComputedStyle(document.documentElement).fontSize);

    let ContextCheckBox = (props) => {
        const [context, setContext] = useContext(Context);
        if (props.master)
            console.log("ContextCheckBox searchLine: ", searchLine)
        /*let isBySearch = (el, searchLine) => {
            let item = items.reduce((s, it) => (it.id === el.id ? it : s), null);
            console.log(item.transport, item.transport.toLowerCase().indexOf(searchLine.toLowerCase()));
            console.log(item.cardNumberLabel, item.cardNumberLabel.toLowerCase().indexOf(searchLine.toLowerCase()));
            ["transport", "cardNumberLabel"].forEach(k => {
                if(item && (item[k] || "").toLowerCase().indexOf(searchLine.toLowerCase()) !== -1)
                    return true
            })
            return false
        }*/
        return <Tooltip title={props.master ? t("objectsTable.checkAll") : ""}>
            <Checkbox className={"py-0 " + (props.master ? "text-white" : "")}
                  checked={
                      props.master ?
                          context.filter.masterCheckbox
                          :
                          context.filter.checkboxes
                              .filter(el => el.id === props.id)[0].active}
                  sx={{opacity: (props.master ? 1 : 0.5), '&.Mui-checked': {opacity: 1}}}
                  onChange={(e) => {
                      setContext({
                          ...context,
                          filter: {
                              ...context.filter,
                              masterCheckbox:
                                  props.master ?
                                      !context.filter.masterCheckbox
                                      :
                                      false,
                              checkboxes: context.filter.checkboxes
                                  .map(el => (
                                      el.id === props.id || props.master/* && isBySearch(el, searchLine)*/ ?
                                          {
                                              ...el,
                                              active: props.master ? !context.filter.masterCheckbox : !el.active
                                          }
                                          :
                                          el))
                          }
                      })
                  }
                  }
            />
        </Tooltip>
    }

    let loadData = async (ranges) => {
        let sl = sleep(300);
        let st_pr = loadServiceTypes()
        let {result, error, ids = []} = await loadObjects()
        if (error.status === 401) context.error_401();
        setDeadTokenError(error.detail)
        await sl
        let {result: st, error: error2} = await st_pr
        if (error2 === 401) context.error_401();
        setServiceTypes(st)
        setContext({...context, filter: {...context.filter, masterCheckbox: false, checkboxes: ids}})

        result = result.map(el => {
            return {
                ...el,
                //isVisibleSign: <div><ContextCheckBox id={el.id}/>{el.isVisibleSign}</div>,
                cards: el.cards,
                cardNumberLabel: el.cards.reduce((s, el2) => (s + el2.card_number + " "), ""),
                cardNumber: el.cards.reduce((s, el2) => el2.card_number, ""),
                paymentSystem: el.cards.reduce((s, el2) => (s + (el2.type_system_id !== null ? st.reduce((s2, service) => (s2 + (service.id === el2.type_system_id ? service.name + " " : "")), "") : "")), ""),
                _funct_makeReload: () => (setNeedReload(Math.random()))
            }
        })

        let data = Array(result.length).fill(undefined).map((el, i) => {
            return {i: i, "v": 0, "vv": 0}
        })
        let widthOgChart = 5 // rem
        setItems(result.map(r => {
            return {
                ...r, chart: (
                    <ComposedChart width={parseInt(remToPx(widthOgChart))} height={parseInt(remToPx(1.8))} data={data}>
                        <Bar type="monotone" dataKey="v" stroke="#8884d8" dot={false} style={{opacity: 0}}/>
                    </ComposedChart>
                )
            }
        }));

        setTimeout(async () => {
            let {result: loadedCharts, error} = await loadCharts(result.map(el => el.cardNumber))
            if (error === 401) context.error_401();
            let dataLen = 7

            setItems(result.map((r, ri) => {

                let data = (loadedCharts[r.cardNumber] ? loadedCharts[r.cardNumber] : Array(dataLen).fill(undefined)).map((el, i) => {
                    return {
                        i: i,
                        "v": loadedCharts[r.cardNumber] ? el.wialonCount : 0,
                        "vv": loadedCharts[r.cardNumber] ? -el.serviceCount : 0,
                        _zero: 0
                    }
                })
                let addFirstLastEl = d => [{i: null, "v": 0, "vv": 0, _zero: 0}, ...d, {
                    i: null,
                    "v": 0,
                    "vv": 0,
                    _zero: 0
                }]

                return {
                    ...r, chart: (
                        <ComposedChart barGap={-parseInt(remToPx(widthOgChart) * 0.5 / dataLen)}
                                       barSize={parseInt(remToPx(widthOgChart) * 0.5 / dataLen)}
                                       width={parseInt(remToPx(widthOgChart))} height={parseInt(remToPx(1.8))}
                                       data={addFirstLastEl(data)}
                                       key={"key_chart_" + ri}
                        >
                            <Bar type="monotone" dataKey="v" stroke="#fe4444" fill="#fe4444" dot={false}/>
                            <Bar type="monotone" dataKey="vv" stroke="#3366cb" fill="#3366cb" dot={false}/>
                            <Line dataKey="_zero" stroke="black" dot={false}/>
                        </ComposedChart>
                    )
                }
            }));
        }, 0)

    };

    useEffect(() => {
        trackPromise(loadData());
        return () => {
        };
    }, [needReload]);

    if (context.login === "")
        return <Redirect to='/auth'/>

    let columns = [{name: "", key: "status", empty: true, noExport: true},
        {
            name: <div><ContextCheckBox master={true} searchLine={searchLine}/><i className="far fa-eye-slash"
                                                                                  style={{color: "#212529"}}/></div>,
            key: "isVisibleSign",
            floatC: true,
            floatCA: true,
            sort: "noSort",
            exportText: "Да",
            width: "5rem",
            doNotExpand: true
        },
        {name: t("objectsTable.transport"), key: "transport", filter: true, sort: "t", nowrap: true},
        {name: t("objectsTable.cardNumber"), key: "cardNumberLabel", filter: true, floatC: true, sort: "d"},
        {
            name: t("objectsTable.paymentSystem"),
            key: "paymentSystem",
            floatC: true,
            filter: true,
            sort: "t",
            nowrap: true
        },
        {name: t("objectsTable.tankVolume"), key: "tankVolume", floatC: true, sort: "f"},
        {
            name: t("objectsTable.chart"),
            key: "chart",
            floatC: true,
            sort: "noSort",
            width: "6rem",
            noExport: true,
            tooltip: t("objectsTable.tooltipChart")
        }]

    return (
        <div className="container-fluid pb-3 px-0">
            {
                deadTokenError ?
                    <div className="my-3 py-1 text-center bg-danger rounded-3 text-white fw-bold">
                        {t("changeTariff.noData").replace('tms', companyName)}
                    </div>
                    :
                    <div className="px-3">
                        {promiseInProgress ?
                            <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                                      open={true}><CircularProgress color="inherit"/></Backdrop>
                            :
                            <div style={{width: "100%"}}>

                                <div className="row m-0 mw-100">
                                    <div className="col-11 px-0">
                                        <div className="btn-group pt-2" role="group" aria-label="Basic example">
                                            <button type="button"
                                                    className={"btn " + (isShowVisible ? "btn-dark" : "btn-outline-dark opacity-50")}
                                                    onClick={() => (setIsShowVisible(!isShowVisible))}
                                                    style={{borderRight: "2px solid white"}}><i className="fas fa-eye"/>
                                            </button>
                                            <button type="button"
                                                    className={"btn " + (isShowUnVisible ? "btn-dark" : "btn-outline-dark opacity-50")}
                                                    onClick={() => (setIsShowUnVisible(!isShowUnVisible))}><i
                                                className="fas fa-eye-slash"/></button>
                                        </div>
                                    </div>

                                    {
                                        cr && (cr.AdminRigths) ?
                                            <div className="col-1 px-0 d-flex justify-content-end align-items-end">
                                                <button type="button" className="btn btn-outline-dark"
                                                        onClick={() => {
                                                            dispatch(importFromFileInit())
                                                            setModalImportFile(true)
                                                        }}
                                                >
                                                    <Tooltip title={t("data.importFromFile")}>
                                                        <i className="fas fa-file-import"/>
                                                    </Tooltip>
                                                </button>
                                            </div>
                                            : null
                                    }

                                </div>

                                <Table
                                    items={
                                        items.filter(el => ((el.isVisible && isShowVisible) || (!el.isVisible && isShowUnVisible)))
                                            .map(el => ({
                                                ...el,
                                                isVisibleSign: <div><ContextCheckBox id={el.id}/>{el.isVisibleSign}
                                                </div>,
                                            }))
                                    }
                                    columns={columns}
                                    ExpandableRow={ExpandableRowObj}
                                    expandOnSingleClick
                                    defaultSort={{key: "transport", isUp: false}}
                                    searchLineChanged={newSearchLine => setSearchLine(newSearchLine)}
                                />

                                {
                                    cr && (cr.AdminRigths) ?
                                        <>
                                            <div className="py-2 px-0">
                                                <div className="border-bottom rounded-3 p-2"
                                                     style={{backgroundColor: "lightblue"}}>{t("objectsTable.recountInfo")}</div>
                                            </div>

                                            <div className="pt-3">
                                                <DiapasonsDateTimePicker card={datePicker} setCard={setDatePicker}
                                                                         showClock={false}/>
                                            </div>

                                            <div className="pt-3">
                                                <button type="button" className={"btn btn-outline-" + recountBtnStatus}
                                                        onClick={async () => {
                                                            let isSomeChecks = !context.filter.checkboxes.filter(el => el.active).length
                                                            if (isSomeChecks)
                                                                enqueueSnackbar(t("objectsTable.recountNoChecksError"), {
                                                                    ...snackDef,
                                                                    variant: "error",
                                                                    autoHideDuration: 5000
                                                                });

                                                            if (!isSomeChecks) {
                                                                let {result, error} = await startBackLoading({
                                                                    from: moment(datePicker.dMin).format('YYYY-MM-DD'), //momentToServerFormat(moment(datePicker.dMin)),
                                                                    to: moment(datePicker.dMax).format('YYYY-MM-DD'), //momentToServerFormat(moment(datePicker.dMax)),
                                                                    cards: JSON.stringify(context.filter.checkboxes.filter(el => el.active).map(el => el.wialon_id)),
                                                                })
                                                                if (error === 401) context.error_401();
                                                                if (result.load_id) {
                                                                    closeSnackbar()
                                                                    enqueueSnackbar(t("billing.SnackNotifier.warning"), {
                                                                        ...snackDef,
                                                                        variant: "warning",
                                                                        autoHideDuration: 5000
                                                                    });
                                                                    let snackLoopStart = async (id) => {
                                                                        if (id !== "error_id") {
                                                                            let {
                                                                                result,
                                                                                error
                                                                            } = await checkBackLoading(id)
                                                                            if (error === 401) context.error_401();
                                                                            if (!result.is_work) {
                                                                                closeSnackbar()
                                                                                if (result.errors.length)
                                                                                    result.errors.forEach(el => {
                                                                                        enqueueSnackbar(t("recountingErrors." + recountingErrorsLangKeys[el.code]) + (el.args ? " " + el.args.reduce((s, el2, i2) => (s + el2 + (i2 !== 0 && i2 !== el.args.length ? ", " : "")), "") : ""), {
                                                                                            ...snackDef,
                                                                                            variant: "error"
                                                                                        });
                                                                                    })
                                                                                else
                                                                                    enqueueSnackbar(t("billing.SnackNotifier.success"), {
                                                                                        ...snackDef,
                                                                                        variant: "success"
                                                                                    });
                                                                            } else
                                                                                setTimeout(() => {
                                                                                    snackLoopStart(id)
                                                                                }, 1000)
                                                                        }
                                                                    };
                                                                    setTimeout(() => {
                                                                        snackLoopStart(result.load_id)
                                                                    }, 1000)
                                                                } else
                                                                    enqueueSnackbar(t("billing.SnackNotifier.error"), {
                                                                        ...snackDef,
                                                                        variant: "error",
                                                                        autoHideDuration: 5000
                                                                    });
                                                            }
                                                        }}
                                                >
                                                    {t("objectsTable.recount")}&nbsp;&nbsp;<i
                                                    className="fas fa-retweet"/>
                                                </button>
                                            </div>
                                        </>
                                        : null
                                }

                            </div>
                        }
                    </div>
            }

            {
                modalImportFile ?
                    <ImportFileModal handleClose={() => {
                        setModalImportFile(false)
                    }}/>
                    : null
            }

        </div>
    )
}