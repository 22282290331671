import {serverURL, corsHeaders, formatDate} from "../../../requests/constants";
const axios = require('axios');

export default async function loadClientList(clientAvailable= "", clientFullPrice= ""){
    try {
        /*return Array(13).fill(undefined).map((el, i) => ({
            id: "" + i,
            login: "clientName" + i + " | " + clientAvailable + ": " + i * 3 + " | " + clientFullPrice + ": " + i * 2000,
            substrs: Array(i % 4).fill(undefined).map((u, j) => ({
                name: "name_" + j,
                connDate: formatDate("2021-10-05 21:13"),
                amPacks: "" + i * 2,
                amObjs: "" + i * 15,
                pricePerM: "" + i * 1000,
            }))
        }))*/
        let response = await axios({
            method: 'get',
            url: serverURL + 'billing/tariff/detail/',
            headers: {
                'accept': 'application/json',
                ...corsHeaders
            },
        });

        let result = response.data.map(el => {
            return ({
                id: "" + el.ClientID,
                login: "" + el.ClientName,
                objInUse: "" + el.ItemsActiv,
                clientAvailable: "" + el.totalItems,
                clientFullPrice: "" + el.totalSum,
                is_right: el.Tariff.length ? (el.Tariff[0].currency ? el.Tariff[0].currency.is_right: true): true,
                symbol: el.Tariff.length ? (el.Tariff[0].currency ? el.Tariff[0].currency.symbol: ""): "",
                symbolPDF: el.Tariff.length ? (el.Tariff[0].currency ? el.Tariff[0].currency.ISO_LAT3: ""): "",
                substrs: el.Tariff.map(el2 => ({
                    name: "" + el2.PackName,
                    connDate: formatDate(el2.connectTime),
                    amPacks: "" + el2.countPack,
                    amObjs: "" + el2.unitCount,
                    pricePerM: "" + el2.PackPrice,
                }))
            })
        })

        return {result: response.status === 200 ? result : [], error: false}
    } catch (error) {
        return {result: [], error: error.response ? error.response.status : 500}
    }
}