import {serverURL, corsHeaders} from "./constants";
const axios = require('axios');

export default async function addOrganization(card){
    try {
        let response = await axios({
            method: 'post',
            url: serverURL + 'clients/',
            headers: {
                ...corsHeaders,
                'Content-Type': 'application/json', // for post arr in data
            },
            data: JSON.stringify(card)
        });

        return {result: response.status === 200, error: response.status}
    } catch (error) {
        return {result: false, error: error.response ? error.response.status : 500}
    }
}