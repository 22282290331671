import {serverURL, corsHeaders} from "../../../requests/constants";
const axios = require('axios');

export default async function saveChangedTariff(card){
    try {
        let response = await axios({
            method: 'put',
            url: serverURL + 'billing/tariffs/',
            headers: {
                ...corsHeaders,
                'Content-Type': 'application/json', // for post arr in data
            },
            data: JSON.stringify({
                pack_id: card.id,
                pack_name: card.name,
                active: !card.isArchive
            })
        });

        return {result: response.status === 200, error: false}


        //console.log(card)
        //return {result: false, error: /*error.response ? error.response.status : 500*/false}
    } catch (error) {
        return {result: false, error: error.response ? error.response.status : 500}
    }
}