import {serverURL, corsHeaders} from "./constants";
import moment from 'moment'
const axios = require('axios');

export default async function loadUsers(){
    let formatDate = (str) => {
        if(!str) return ""
        let d = new Date(str)
        return d.toLocaleDateString('ru') + " " + d.toLocaleTimeString('ru').substr(0, d.toLocaleTimeString('ru').length - 3)
    }

    try {
        let response = await axios({
            method: 'get',
            url: serverURL + 'users/rights/',
            headers: {
                'accept': 'application/json',
                ...corsHeaders
            },
        });

        if(response.status !== 200)
            return {result: [], error: response.status}

        let result = response.data.map(el => ({
            id: el.ClientID,
            login: el.ClientName,
            substrs: el.Users.map(u => ({
                changeCreator: u.EditorName,
                creationDate: formatDate(u.CreationDate),
                creator: u.CreatorName,
                id: u.UserID,
                lastAuthDate: formatDate(u.last_login),
                lastChangeDate: formatDate(u.EditTime),
                login: u.login,
                UserName: u.UserName,
                ClientName: el.ClientName,

                bool_0: u.ViewRights > 0,
                bool_1: u.EditPropertyRights > 0,
                bool_2: u.AdminRigths > 0,
                bool_3: u.DilerRigths > 0,
                bool_4: u.Billing > 0,
                bool_5: u.enable > 0,

                disabled: [
                    u.disabled.ViewRights,
                    u.disabled.EditPropertyRights,
                    u.disabled.AdminRigths,
                    u.disabled.DilerRigths,
                    u.disabled.Billing,
                    u.disabled.enable
                ],
            }))
        }))

        return {result: result, error: response.status}
    } catch (error) {
        return {result: [], error: error.response ? error.response.status : 500}
    }
}