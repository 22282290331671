import React, { useEffect, useState } from "react";
import Map from "./Map";
import {useTranslation} from "react-i18next";
import { useSelector } from "react-redux";

export default function ExpandableRowData(props) {
    const {t, i18n} = useTranslation();
    const [companyName, setCompanyName] = useState('')
    const state = useSelector(state => state)
    let el = props.el
    const points = [
                                el.latFuel && el.lonFuel ? {icon: true, lat: el.latFuel, lon: el.lonFuel, markerText: el.fuelCompany !== "" && el.address !== "" ? el.fuelCompany + " | " + el.address : el.latFuel + " | " + el.lonFuel, isMarker: true} : null,
                                el.latWialon && el.lonWialon ? {lat: el.latWialon, lon: el.lonWialon, markerText: `${companyName}: ${el.transport}`, isMarker: true} : null,
                                (el.latObject && el.lonObject) && !(el.latWialon && el.lonWialon) ? {lat: el.latObject, lon: el.lonObject, markerText: `${companyName}: ${el.transport}`, isMarker: true} : null,
                            ].filter(el => el)
    const [mapCenter, setMapCenter] = useState(points.reduce((s, p) => [p.lat, p.lon], [0, 0]))
    useEffect(()=>{
        setCompanyName(state.app.detail?.service_name ?? 'Wialon')
    }, [state])

    let mapW = parseInt(window.innerWidth * 0.39)
    let mapH = parseInt(window.innerHeight * 0.3)

    return <div className="row w-100 m-0">
        <div className="d-inline p-0 " style={{width: mapW, height: mapH}}>
            <div className="text-center border border-dark rounded-3" style={{overflow: "hidden"}}>
                {
                    el.latFuel && el.lonFuel || el.latWialon && el.lonWialon?
                        <Map
                            center={mapCenter}
                            points={points}
                            //lat={el.lat}
                            //lon={el.lon}
                            //markerText={el.fuelCompany !== "" && el.address !== "" ? el.fuelCompany + " | " + el.address : el.lat + " | " + el.lon}
                            //isMarker={false}
                            width={mapW + "px"}
                            height={mapH + "px"}
                        />
                        :
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{width: mapW + "px", height: mapH + "px"}}
                        >{t("expandData.noMap")}</div>
                }

            </div>
        </div>
        <div className="d-inline p-0 ms-5 " style={{width: "calc(100% - 43vw)"}}>
            <div className="row pb-3 w-100 m-0">
                <div className="card rounded-3 w-100 p-0" style={{border: "2px solid #3277b3"}}>
                    <div className="col-12 p-0"
                        style={{border: "2px solid #3277b3", backgroundColor: "#3277b3", borderColor: "#3277b3", display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <div className="px-2 py-1 fw-bold text-white"
                            style={{fontSize: "1rem"}}>{t("expandData.tr")} {el.cardNumber ? " / " + el.cardNumber : ""}
                        </div>
                        {(el.latFuel && el.lonFuel) && <button
                        style={{
                            border: '2px solid #fff',
                            borderRadius: 3,
                            color: '#fff',
                            backgroundColor: "#3277b3",
                            padding: 1,
                            paddingInline: 3,
                            fontWeight: 'bold',
                            maxHeight: 25
                        }}
                        onClick={() => {setMapCenter([el.latFuel, el.lonFuel])}}>
                            <i class="fa fa-map-o" aria-hidden="true"></i>
                            &nbsp;{t('expandData.onMap')}
                        </button>}
                    </div>
                    <div className="col-12 px-2">
                        {
                            //refunds
                            el.fillings.length || el.refunds.length ?
                                <table className="table table-striped text-center">
                                    <thead>
                                    <tr>
                                        <th>{t("expandData.date")}</th>
                                        <th>{t("expandData.price")}</th>
                                        <th>{t("expandData.volume")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {el.fillings.map(f => (f && f.date !== "" ? <tr>
                                        <td>{f.date}</td>
                                        <td>{f.price}</td>
                                        <td>{f.value}</td>
                                    </tr> : null))}
                                    {el.refunds.map(r => (r && r.date !== "" ? <tr>
                                        <td>{r.date}</td>
                                        <td>{r.price}</td>
                                        <td>{r.value}</td>
                                    </tr> : null))}
                                    </tbody>
                                </table>
                                :
                                <div className="py-2">{t("expandData.noTrData")}</div>
                        }
                        {/*
                            el.fillings && el.fillings && el.fillings.date !== "" || el.refunds && el.refunds && el.refunds.date !== "" ?
                                <table className="table table-striped text-center">
                                    <thead><tr><th>{t("expandData.date")}</th><th>{t("expandData.price")}</th><th>{t("expandData.volume")}</th></tr></thead>
                                    <tbody>
                                    {el.fillings && el.fillings && el.fillings.date !== "" ? <tr><td>{el.fillings.date}</td><td>{el.fillings.price}</td><td>{el.fillings.value}</td></tr> : null}
                                    {el.refunds && el.refunds && el.refunds.date !== "" ? <tr><td>{el.refunds.date}</td><td>{el.refunds.price}</td><td>{el.refunds.value}</td></tr> : null}
                                    </tbody>
                                </table>
                                :
                                <div className="py-2">{t("expandData.noTrData")}</div>
                        */}
                    </div>
                </div>
            </div>
            <div className="row w-100 m-0">
                <div className="card rounded-3 w-100 p-0" style={{border: "2px solid #d1e9c7"}}>
                    <div className="col-12 p-0" style={{
                        border: "2px solid #d1e9c7",
                        color: "#7b9e7e",
                        backgroundColor: "#d1e9c7",
                        borderColor: "#d1e9c7",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
                        <div className="px-2 py-1 fw-bold"
                            style={{fontSize: "1rem"}}>{t("expandData.fillsW").replace('tms', companyName)} {el.transport ? " / " + el.transport : ""}
                        </div>
                        {((el.latObject && el.lonObject) || (el.latWialon && el.lonWialon)) && 
                        <button
                        style={{
                            border: '2px solid #7b9e7e',
                            borderRadius: 3,
                            color: '#7b9e7e',
                            backgroundColor: "#d1e9c7",
                            paddingInline: 3,
                            fontWeight: 'bold',
                            maxHeight: 25,
                        }}
                        onClick={() => {
                            if (el.latObject && el.lonObject) setMapCenter([el.latObject, el.lonObject])
                            if (el.latWialon && el.lonWialon) setMapCenter([el.latWialon, el.lonWialon])
                            }}>
                            <i class="fa fa-map-o" aria-hidden="true"></i> &nbsp;
                            {t('expandData.onMap')}
                        </button>}
                    </div>
                    <div className="col-12 px-2">
                        {
                            el.wialonInfo && el.wialonInfo && el.wialonInfo.date !== "" ?
                                <table className="table table-striped text-center">
                                    <thead>
                                    <tr>
                                        <th>{t("expandData.date")}</th>
                                        <th>{t("expandData.volume")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>{el.wialonInfo && el.wialonInfo && el.wialonInfo.date !== "" ? <tr>
                                        <td>{el.wialonInfo.date}</td>
                                        <td>{el.wialonInfo.value}</td>
                                    </tr> : null}</tbody>
                                </table>
                                :
                                <div className="py-2">{t("expandData.noWData").replace('tms', companyName)}</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
}